import { environment } from '../../environments/environment';

let catSilamazeDaTavisMovla;
let sportiDaMogzauroba;

if (environment.develop) {
  catSilamazeDaTavisMovla = 176;
  sportiDaMogzauroba = 792;
} else if (environment.staging) {
  catSilamazeDaTavisMovla = 176;
  sportiDaMogzauroba = 918;
} else if (environment.production) {
  catSilamazeDaTavisMovla = 344;
  sportiDaMogzauroba = 918;
}
export const SILAMAZE_DA_TAVIS_MOVLA = catSilamazeDaTavisMovla;
export const SPORTI_DA_MOGZAUROBA = sportiDaMogzauroba;
