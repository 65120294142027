<div
  *ngIf="!isFromSafari"
  role="banner"
  aria-labelledby="smartBannerTitle"
  class="_x_relative _x_z-10 _x_flex _x_items-center _x_justify-between _x_bg-white _x_py-9 _x_pl-8 _x_pr-4 _x_shadow-dropwownShadow">
  <div class="_x_flex _x_items-center _x_gap-4">
    <button
      (click)="closeSmartBanner()"
      aria-label="Close Smart Banner"
      class="_x_icon _x_icon-remove _x_flex _x_h-12 _x_max-h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_cursor-pointer _x_items-center _x_justify-center _x_text-5 _x_text-dark-200">
      <!-- Include icon here if needed for further context -->
    </button>

    <img
      class="_x_h-15 _x_w-15 _x_rounded-15 _x_object-contain"
      src="../../../assets/smartbanner.svg"
      alt="Extra.ge logo" />
    <div class="_x_flex _x_flex-col _x_gap-1">
      <p id="smartBannerTitle" class="_x_font-bold _x_text-3 _x_text-dark">Extra: All you need is here</p>
      <p class="_x_font-medium _x_text-2 _x_text-dark-400">Open in the Extra app</p>
    </div>
  </div>
  <button
    (click)="redirectToMobileApp()"
    aria-label="Open Extra app"
    class="_x_flex _x_h-17 _x_w-32 _x_items-center _x_justify-center _x_rounded-100 _x_bg-app-blue _x_font-bold _x_text-2 _x_tracking-wide _x_text-white">
    OPEN
  </button>
</div>
