import { EventEmitter, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private events: Map<string, EventEmitter<any>> = new Map();

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  setValue(property: string, value: any): void {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(property, JSON.stringify(value));
      if (this.events.has(property)) {
        this.events.get(property).emit(value);
      }
    }
  }

  getValue(property: string): any {
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem(property)) {
        return JSON.parse(localStorage.getItem(property));
      }
    }
  }

  removeItem(property: string): any {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem(property);
    }
  }

  on(property: string): EventEmitter<any> {
    if (!this.events.has(property)) {
      this.events.set(property, new EventEmitter());
    }
    return this.events.get(property);
  }
}
