import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from '../api.service';
import { Guid } from 'guid-typescript';

@Injectable({
  providedIn: 'root'
})
export class CategoriesHttp {
  constructor(private api: ApiService) {}

  // there should be endpoint for url for category tree

  getCategoryBranchBySlug(slug: string) {
    return this.api.get(`api/categories/branch-by-slug`, { slug: slug }, 'cmsURL');
  }

  getCategoryFeatureGroups(id): Observable<any> {
    return this.api.get(`api/categories/${id}/featuregroups`, {}, 'cmsURL');
  }

  changeSortIndex(data): Observable<any> {
    return this.api.put(
      'api/categories/featuregroups/changesortindexes',
      data,
      { requestId: this.getGuidId() },
      'cmsURL'
    );
  }

  getCategoryNested(): Observable<any> {
    return this.api.get('api/categories/nested', {}, 'cmsURL');
  }

  getCategoryFlat(): Observable<any> {
    return this.api.get('api/categories/flat', {}, 'cmsURL');
  }

  getCategorysNodes(parentCategorySlug?): Observable<any> {
    return this.api.get('api/categories/by-parent-slug', { parentCategorySlug }, 'cmsURL');
  }

  getMercuryCategorysNodes(slug?): Observable<any> {
    return this.api.get(`categories${slug ? '/' + encodeURIComponent(slug) : ''}`, {}, 'mercury-api-url');
  }

  getMercuryCategoryById(id) {
    return this.api.get(`categories/${id}`, {}, 'mercury-api-url');
  }

  getMercuryCategorysNodesCheri(slug?): Observable<any> {
    return this.api.get(`categories/${slug}/cheri-cheri`, {}, 'mercury-api-url');
  }

  getSearchedCategories(searchValue): Observable<any> {
    return this.api.get('api/categories/search', { searchValue }, 'cmsURL');
  }

  getCategoryBySlug(slug): Observable<any> {
    return this.api.get(`api/categories/by-slug`, { slug }, 'cmsURL');
  }

  getCategoriesBySearch(keyWord): Observable<any> {
    return this.api.get(`api/products/getcategoriesbysearch`, { searchText: keyWord }, 'cmsURL');
  }

  coolCat(params): Observable<any> {
    return this.api.post(`search/cool-cat`, { ...params }, {}, 'mercury-api-url');
  }

  getCategoryById(id): Observable<any> {
    return this.api.get(`api/categories/${id}`, {}, 'cmsURL').pipe(map(res => res['category']));
  }

  getCategoryIdByName(name: string): Observable<number> {
    return this.api.get(`api/categories`, { input: name }, 'cmsURL');
  }

  getBrandsByCategory(id: number) {
    return this.api.get(`api/categories/${id}/brands`, {}, 'cmsURL');
  }

  getFeaturesByCategory(categoryId: any) {
    return this.api.get(`api/categories/${categoryId}/features`, {}, 'cmsURL');
  }

  getLastNodeCategory(pageNumber = 1, pageSize = 10, searchValue?): Observable<any> {
    return this.api.get(
      'api/categories/lastnodecategories',
      {
        pageNumber: pageNumber,
        pageSize: pageSize,
        searchValue: searchValue && searchValue
      },
      'cmsURL'
    );
  }

  getLastNodeCategoryPublished(pageNumber = 1, pageSize = 10, searchValue?): Observable<any> {
    return this.api.get(
      'api/categories/lastnodecategories/published',
      {
        pageNumber: pageNumber,
        pageSize: pageSize,
        searchValue: searchValue && searchValue
      },
      'cmsURL'
    );
  }

  getCategoryC2CFeatures(id: any, params): Observable<any> {
    return this.api.get(`api/categories/${id}/c2c-features`, params, 'cmsURL').pipe(map(res => res.data));
  }

  getCategoryByParent(params): Observable<any> {
    return this.api.get(`api/categories/by-parent`, params, 'cmsURL').pipe(map(res => res.data));
  }

  getCategoryFeaturesById(id: string): Observable<any> {
    return this.api.get(`api/categories/${id}/features`, {}, 'cmsURL').pipe(map(res => res.categoryFeatures));
  }

  getCategoryFeatureValues(categoryId: string, featureId: string): Observable<any> {
    return this.api
      .get(`api/featuresuggestedvalues/${categoryId}/features/${featureId}`, {}, 'cmsURL')
      .pipe(map(res => res.categoryFeatureSuggestedValues));
  }

  getC2CFeatures(id, params): Observable<any> {
    return this.api.get(`api/categories/${id}/c2c-features/data`, params, 'cmsURL').pipe(map(res => res.data));
  }

  addCategory(data): Observable<any> {
    return this.api.post('api/categories', data, { requestId: this.getGuidId() }, 'cmsURL');
  }

  addCategoryFeature(data): Observable<any> {
    return this.api.post('api/categories/addfeature', data, { requestId: this.getGuidId() }, 'cmsURL');
  }

  addCategoryFeatureValue(data): Observable<any> {
    return this.api.post('api/featuresuggestedvalues', data, { requestId: this.getGuidId() }, 'cmsURL');
  }

  updateCategory(data): Observable<any> {
    return this.api.put('api/categories', data, { requestId: this.getGuidId() }, 'cmsURL');
  }

  categoriesChangeFilters(data): Observable<any> {
    return this.api.put('api/categories/changefilters', data, { requestId: this.getGuidId() }, 'cmsURL');
  }

  updateHideCategory(id: string): Observable<any> {
    return this.api.put('api/categories/hide', { id: id }, { requestId: this.getGuidId() }, 'cmsURL');
  }

  updateCategoryFeature(data): Observable<any> {
    return this.api.put('api/categories/updatefeature', data, { requestId: this.getGuidId() }, 'cmsURL');
  }

  updateCategoryFeatureSortIndex(data): Observable<any> {
    return this.api.put('api/categories/features/changesortindexes', data, { requestId: this.getGuidId() }, 'cmsURL');
  }

  updateCategoryFeatureValueSortIndex(data): Observable<any> {
    return this.api.put(
      'api/featuresuggestedvalues/changesortindexes',
      data,
      { requestId: this.getGuidId() },
      'cmsURL'
    );
  }

  removeCategoryFeature(categoryId: string, featureId: string): Observable<any> {
    return this.api.delete(
      'api/categories/removefeature',
      {
        requestId: this.getGuidId(),
        CategoryId: categoryId,
        FeatureId: featureId
      },
      'cmsURL'
    );
  }

  getCategoriesDescription(id: number | string): Observable<any> {
    return this.api.get(`api/categories/${encodeURIComponent(id)}/description`, {}, 'cmsURL', 'text/html');
  }

  removeCategoryFeatureValue(valueId: string): Observable<any> {
    return this.api.delete(
      'api/featuresuggestedvalues',
      {
        requestId: this.getGuidId(),
        Id: valueId
      },
      'cmsURL'
    );
  }

  getCategories(): Observable<any> {
    return this.api.get(`categories/`, {}, 'mercury-api-url');
  }

  searchSellers(params: any): Observable<any> {
    return this.api.post(`search/sunny`, { ...params }, {}, 'mercury-api-url');
  }

  getParentCategory(id: number | string): Observable<any> {
    return this.api.get(`api/categories/parent-by-childid`, { id: id }, 'cmsURL').pipe(map(res => res.data));
  }

  private getGuidId(): string {
    return Guid.create().toString();
  }
}
