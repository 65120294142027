import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { AddressesStoreModel, ShippingAddressInteface } from '../models/addresses.model';
import { AddressesState } from '../states/addresses.state';
import {
  GetShippingAddressesAttempt,
  GetShippingAddressesSuccess,
  GetShippingAddressesFailed
} from '../actions/addresses.actions';

export const AddressesReducer = createReducer(
  AddressesState,
  on(GetShippingAddressesAttempt, state => ({ ...state, loading: true, error: [] })),
  on(GetShippingAddressesSuccess, (state, action) => {
    return { data: action.data, loading: false, error: [] };
  }),
  on(GetShippingAddressesFailed, (state, action) => ({ ...state, loading: false, error: action.error }))
);

export function addressReducer(state: AddressesStoreModel, action: Action) {
  return AddressesReducer(state, action);
}

export const selectAddressState = createFeatureSelector<AddressesStoreModel>('addresses');

export const selectAddresses = createSelector(selectAddressState, state => state?.data);

export const selectAddressesLoadingState = createSelector(selectAddressState, state => state?.loading);

export const selectAddressesAmount = createSelector(selectAddressState, state => state?.data?.length);
