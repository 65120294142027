import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WishlistService {
  private productIds: number[];
  public productIdsSubject = new Subject<number[]>();
  constructor() {}

  set wishlistProductIds(ids: number[]) {
    this.productIds = ids;
    this.productIdsSubject.next(this.productIds);
  }

  get wishlistProductIds(): number[] {
    return this.productIds;
  }
}
