import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { MarketingHttp } from 'lib-core';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { GetRibbonAttempt, GetRibbonFailed, GetRibbonSuccess } from '../actions/ribbon.actions';
import { RibbonModel } from '../models/ribbon.model';

@Injectable()
export class RibbonEffects {
  constructor(
    private actions$: Actions,
    private marketingHttp: MarketingHttp,
    private store: Store<RibbonModel>
  ) {}

  getRibbonAttempt = createEffect(() =>
    this.actions$.pipe(
      ofType(GetRibbonAttempt),
      mergeMap(() =>
        this.marketingHttp.getRibbon().pipe(
          map((res: RibbonModel) => {
            return GetRibbonSuccess(res);
          }),
          catchError(err => {
            return of(GetRibbonFailed(err));
          })
        )
      )
    )
  );
}
