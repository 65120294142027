import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import {
  closeBirthDateTab,
  legalAgeStatusFail,
  legalAgeStatusSuccess,
  openBirthDateTab,
  setGuardSelectedCategory
} from '../actions/guard.actions';
import { GuardModel } from '../models/guard.model';

export const initialState: GuardModel = {
  selectedCategory: null,
  birthDateStatus: false,
  legalAgeStatus: false
};

const guardReducer = createReducer(
  initialState,
  on(setGuardSelectedCategory, (state, action) => ({ ...state, selectedCategory: action.selectedCategory })),
  on(openBirthDateTab, state => ({ ...state, birthDateStatus: true })),
  on(closeBirthDateTab, state => ({ ...state, birthDateStatus: false })),
  on(legalAgeStatusSuccess, state => ({ ...state, legalAgeStatus: true })),
  on(legalAgeStatusFail, state => ({ ...state, legalAgeStatus: false }))
);

export function reducer(state: GuardModel, action: Action) {
  return guardReducer(state, action);
}

export const selectGuardFeatureState = createFeatureSelector<GuardModel>('guard');

export const getSelectedCategory = createSelector(
  selectGuardFeatureState,
  (state: GuardModel) => state.selectedCategory
);

export const getBirthDateTabStatus = createSelector(
  selectGuardFeatureState,
  (state: GuardModel) => state && state.birthDateStatus
);

export const getLegalAgeStatus = createSelector(
  selectGuardFeatureState,
  (state: GuardModel) => state && state.legalAgeStatus
);
