import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitAddress'
})
export class SplitAddressPipe implements PipeTransform {
  transform(inputString: string, word: string): string {
    if (!inputString || !word) {
      return '';
    }

    const words = inputString.split(', ');
    const index = words.findIndex(w => w === word);

    return index !== -1 ? words.slice(0, index).join(' ') : inputString;
  }
}
