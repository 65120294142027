import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorModalService {
  public isErrorModalOpen = new BehaviorSubject<boolean>(false);

  constructor() {}

  showModal() {
    this.isErrorModalOpen.next(true);
  }

  hideModal() {
    this.isErrorModalOpen.next(false);
  }
}
