import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PopupService } from './popup-overlay.service';

@Component({
  selector: 'app-popup-overlay',
  templateUrl: './popup-overlay.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
  standalone: true
})
export class PopupOverlayComponent implements OnInit {
  closeButtonClass: string;
  bodyClass: string;
  customSvgPath: string;
  customCloseCondition: boolean;
  hideCloseButton: boolean = false;

  constructor(
    public popupService: PopupService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.popupService.event$.subscribe(popupData => {
      if (!popupData) {
        return;
      }
      this.closeButtonClass = popupData.customButtonClass;
      this.bodyClass = popupData.customBodyClass;
      this.customSvgPath = popupData.customSvgPath;
      this.customCloseCondition = popupData.customCloseCondition;
    });
    this.popupService.hideCloseButton$.subscribe(data => {
      this.hideCloseButton = data;
      this.cdr.markForCheck();
    });
  }

  close() {
    this.hideCloseButton = false;
    if (!this.customCloseCondition) {
      this.popupService.close();
    }
  }
}

export interface PopupData {
  template: any;
  customButtonClass?: string;
  customBodyClass?: string;
  customSvgPath?: string;
  customSvgClass?: string;
  customCloseCondition?: boolean;
}
