import { Directive, ElementRef, HostListener, NgModule } from '@angular/core';
import { ImageForSearchService } from '../../services/image-for-search.service';

@Directive({
  selector: '[imageForSearch]'
})
export class ImageForSearchDirective {
  // ამის გარეშე პირველ გახსნაზევე იძახებს დახურას ჰოსთლისენერში, თუ რამე შეიცვლება მომავალში, შესაძლოა ეს აღარც დაგვჭირდეს
  private firstOpen = false;
  constructor(
    private el: ElementRef,
    private imageForSearchService: ImageForSearchService
  ) {}

  @HostListener('document:click', ['$event.target']) onClick(ev) {
    if (this.firstOpen && !this.el.nativeElement.contains(ev)) {
      this.imageForSearchService.setImageSearchModalState(false);
    }
    this.firstOpen = true;
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.fileInput.classList.add('_x_hidden');
    this.contentElement.style.backgroundColor = '#FFFFFF';
  }

  @HostListener('dragover', ['$event']) onDragOver(evt) {
    this.fileInput.classList.remove('_x_hidden');
    this.contentElement.style.backgroundColor = '#EADBFF';
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    if (!this.contentElement.contains(evt.fromElement)) {
      this.contentElement.style.backgroundColor = '#FFFFFF';
      this.fileInput.classList.add('_x_hidden');
    }
  }

  // უშუალოდ ინფუთის ელემენტი
  get fileInput(): HTMLElement {
    return this.el.nativeElement.querySelector('#uploadImage');
  }

  // დივი რომელშიც დრაგით ვარდება ფოტო და რომელსაც ეცვლება ბექგრაუნდის ფერი
  get contentElement(): HTMLElement {
    return this.el.nativeElement;
  }
}

export const DIRECTIVES = [ImageForSearchDirective];

@NgModule({
  declarations: DIRECTIVES,
  exports: DIRECTIVES
})
export class ImageForSearchDirectiveModule {}
