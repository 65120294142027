<div
  class="_x_absolute _x_inset-0 _x_z-10 _x_m-auto _x_flex _x_h-20 _x_h-auto _x_max-h-full _x_w-full _x_max-w-250 _x_items-center _x_overflow-hidden _x_rounded-12 _x_p-8 sm:_x_p-0">
  <div class="_x_relative _x_rounded-12 _x_bg-white sm:_x_w-full">
    <div class="_x_flex _x_flex-col _x_items-center _x_items-stretch">
      <div
        class="_x_absolute _x_left-0 _x_right-0 _x_top-22 _x_mx-auto _x_mb-9 _x_inline-flex _x_h-36 _x_w-36 _x_items-center _x_justify-center _x_rounded-full"
        (click)="closeModal()">
        <svg width="84" height="85" viewBox="0 0 84 85" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="42" cy="42.5" r="36" fill="white" />
          <path
            d="M49.0073 37.0734L46.2317 28.2393L30.7839 36.4364C30.484 36.5958 30.2484 36.8641 30.1202 37.1921C29.9919 37.5202 29.9796 37.8862 30.0855 38.2231L31.643 43.181L33.2005 48.1374C33.3064 48.4745 33.5235 48.7599 33.8121 48.9415C34.1007 49.1231 34.4414 49.1886 34.7719 49.126L51.7828 45.9089L49.0073 37.0734Z"
            fill="#F72163" />
          <path
            d="M49.0074 37.0732L31.6431 43.1809L33.2006 48.1373C33.3065 48.4744 33.5236 48.7598 33.8122 48.9414C34.1008 49.1229 34.4415 49.1884 34.772 49.1259L51.7829 45.9088L49.0074 37.0732Z"
            fill="#F72163" />
          <path
            d="M31.6429 43.1799L30.1441 38.4111L28.6216 39.4943C28.367 39.6741 28.1752 39.9373 28.0758 40.2435C27.9763 40.5497 27.9747 40.882 28.0712 41.1893L28.9917 44.1183L29.9122 47.0457C30.0087 47.3524 30.1975 47.6172 30.4498 47.7995C30.7021 47.9818 31.004 48.0716 31.3091 48.055L33.139 47.9531L31.6429 43.1799Z"
            fill="#F72163" />
          <path
            d="M50.6838 26.2453L56.4794 44.6849C56.5792 45.0026 56.6188 45.3379 56.5961 45.6718C56.5734 46.0057 56.4887 46.3316 56.3469 46.6309C56.2052 46.9302 56.0091 47.197 55.7698 47.4161C55.5306 47.6352 55.2529 47.8023 54.9526 47.9079C54.6522 48.0139 54.3351 48.0562 54.0192 48.0324C53.7034 48.0085 53.3951 47.919 53.112 47.769C52.8289 47.619 52.5765 47.4114 52.3694 47.158C52.1622 46.9047 52.0043 46.6107 51.9047 46.2927L49.0076 37.0714L46.1105 27.8531C46.0107 27.5354 45.971 27.2001 45.9938 26.8662C46.0165 26.5323 46.1011 26.2064 46.2429 25.9071C46.3847 25.6078 46.5808 25.341 46.82 25.1219C47.0593 24.9028 47.337 24.7357 47.6373 24.6301C47.9376 24.5245 48.2546 24.4825 48.5702 24.5066C48.8858 24.5306 49.1939 24.6202 49.4768 24.7702C49.7597 24.9202 50.0119 25.1276 50.219 25.3807C50.4261 25.6338 50.584 25.9276 50.6838 26.2453Z"
            fill="#FEC2A4" />
          <path
            opacity="0.8"
            d="M52.2017 31.0718C53.1681 31.074 54.1094 31.3976 54.8925 31.9967C55.6756 32.5957 56.2609 33.44 56.5657 34.4102C56.8706 35.3804 56.8794 36.4275 56.5911 37.4033C56.3028 38.3791 55.7319 39.2344 54.959 39.8482L52.2017 31.0718Z"
            fill="#2D3552" />
          <path
            opacity="0.8"
            d="M43.0903 47.5498L46.4302 55.2209C46.4763 55.3263 46.4982 55.4418 46.4943 55.5579C46.4903 55.674 46.4605 55.7875 46.4073 55.8891C46.3541 55.9908 46.2789 56.0777 46.188 56.1429C46.0971 56.208 45.9929 56.2496 45.884 56.2642L44.2287 56.4873C43.9371 56.5272 43.641 56.4686 43.3827 56.32C43.1243 56.1714 42.9169 55.9404 42.7899 55.6598L39.4375 48.2414L43.0903 47.5498Z"
            fill="#2D3552" />
          <path
            d="M36.9117 44.3935C38.5124 44.3935 39.8101 43.0206 39.8101 41.3271C39.8101 39.6336 38.5124 38.2607 36.9117 38.2607C35.3109 38.2607 34.0132 39.6336 34.0132 41.3271C34.0132 43.0206 35.3109 44.3935 36.9117 44.3935Z"
            fill="white" />
          <circle opacity="0.2" cx="42" cy="42.5" r="41.5" stroke="white" />
        </svg>
      </div>
      <div class="_x_h-40 _x_w-full _x_rounded-t-12 _x_bg-gradient-to-r _x_from-red-500 _x_to-purple">
        <i
          class="_x_icon-remove _x_absolute _x_right-8 _x_top-8 _x_flex _x_h-12 _x_w-12 _x_items-center _x_justify-center _x_rounded-8 _x_bg-white _x_text-5 _x_text-black"
          (click)="postponeVerification(false)"></i>
      </div>
      <div class="_x_relative _x_mt-1 _x_p-15 _x_text-center">
        <p class="_x_mb-4 _x_font-bold _x_text-6 _x_text-black">სიახლე ექსტრაზე</p>
        <p class="_x_mb-12 _x_font-medium _x_text-4 _x_text-dark-700">
          შეავსე პროფილის ინფორმაცია სრულად და მიიღე შენზე მორგებული შეთავაზებები.
        </p>
        <button
          class="_x_h-24 _x_w-full _x_items-center _x_justify-center _x_rounded-8 _x_border-1 _x_border-purple _x_bg-purple _x_font-bold _x_text-3 _x_text-white _x_transition _x_duration-300 hover:_x_border-purple-100 hover:_x_bg-purple-100 hover:_x_text-purple lg:_x_flex"
          (click)="postponeVerification(true)">
          შევსება
        </button>
      </div>
    </div>
  </div>
</div>
