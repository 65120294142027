import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { fromEvent, Observable, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  share,
  takeUntil,
  throttleTime
} from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { OAuthService } from 'angular-oauth2-oidc';

import { ProductsHttp, Unsubscriber } from 'lib-core';
import { MixpanelService } from 'projects/lib-core/src/lib/services/mixpanel.service';
import { environment } from '../../../environments/environment';
import { FbPixelService } from '../../services/fb-pixel.service';
import { ImageForSearchService } from '../../services/image-for-search.service';
import { OverflowHandlerService } from '../../services/overflow-handler.service';
import { logOut } from '../../store/actions/auth.actions';
import { getBasket } from '../../store/actions/basket.actions';
import { getCategories } from '../../store/actions/category.action';
import { clearComparison, getComparison } from '../../store/actions/comparison.actions';
import { searchToggle, setLoad, sidebarOpen } from '../../store/actions/layout.actions';
import { moveToNextRoute } from '../../store/actions/navigation.actions';
import {
  clearImageByExternalIdState,
  getImageByExternalIdAttempt,
  UploadSearchImageAttempt
} from '../../store/actions/search-by-image.action';
import { removeUser } from '../../store/actions/user.actions';
import * as fromAuth from '../../store/models/auth.model';
import * as fromLayout from '../../store/models/layout.model';
import * as fromRibbon from '../../store/models/ribbon.model';
import * as fromNavigation from '../../store/models/route.model';
import { SearchByImageModel } from '../../store/models/search-by-image.model';
import * as fromUser from '../../store/models/user.model';
import { UserModel } from '../../store/models/user.model';
import { WishlistModel } from '../../store/models/wishlist.model';
import { getUserStatus } from '../../store/reducers/auth.reducer';
import { getBasketProductCount } from '../../store/reducers/basket.reducer';
import { getComparisonProductCount } from '../../store/reducers/comparison.reducer';
import { getSearchBarStatus, getSidebar } from '../../store/reducers/layout.reducer';
import { selectRibbon, selectRibbonActiveState } from '../../store/reducers/ribbon.reducer';
import {
  selectImageForSearchIsUploading,
  selectUploadedImageForSearch
} from '../../store/reducers/search-by-image.reducer';
import { getUserData } from '../../store/reducers/user.reducer';
import { selectWishlistTotalCount } from '../../store/reducers/wishlist.reducer';
import { POPULAR_PRODUCTS_ID } from '../../constants/set-ids.constants';
import { DynamicHostDirective } from '../../shared/directives/dynamic-host.directive';
import { CategoryMenuService } from '../../services/category.service';
import { PopularBannerService } from '../popular-banner/popular-banner.service';
import { VerificationService } from '../../services/verification.service';
import { AddressService } from '../../services/address.service';
import { selectAddresses } from '../../store/reducers/addresses.reducer';
import { AddShippingAddressAttempt, GetShippingAddressesAttempt } from '../../store/actions/addresses.actions';

import { darkStore, ExpressService } from '../../services/express.service';
import { FilterDeliveryTypes } from '../../shared/models/enums/express.enum';
import { ShippingAddressInteface } from '../../store/models/addresses.model';
import { UNAUTHORIZED_USER_ADDRESS } from '../../constants/express.constants';
import { SmartBannerService } from '../../services/smart-banner.service';
import { IsMobileService } from '../../services/is-mobile.service';
import { SILAMAZE_DA_TAVIS_MOVLA, SPORTI_DA_MOGZAUROBA } from '../../constants/header.constants';
import { PrivacyPolicyService } from '../../services/privacy-policy.service';
import { UserTypeService } from '../../services/user-type.service';
import { SmartbannerFn } from '../smart-banner/smart-banner.fn';
import { ChatService } from '../../services/chat.service';

enum Direction {
  Up = 'Up',
  Down = 'Down'
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent extends Unsubscriber implements OnInit, OnDestroy, AfterViewInit {
  // i'm leaving commented part, because may need it in future, if not, just delete them
  @ViewChild('inputMobile', { static: false }) inputMobile: ElementRef;
  @ViewChild('inputDesktop', { static: false }) inputDesktop: ElementRef;
  @ViewChild(DynamicHostDirective, { static: true })
  dynamicHost!: DynamicHostDirective;

  // @Input() tokenLoad: boolean;
  getBasketProductCount$ = this.store.pipe(select(getBasketProductCount));
  getWishlistProductsCount$ = this.store.pipe(select(selectWishlistTotalCount));
  user$: Observable<UserModel> = this.store.pipe(select(getUserData));
  userStatus$: Observable<any> = this.store.pipe(select(getUserStatus));
  userStatus: boolean;
  searchForm: UntypedFormGroup;
  isHome: boolean = true;
  imageUploadForm: UntypedFormGroup;
  previousSearchInputValue: string;
  layoutStatus$: Observable<any> = this.store.pipe(select(getSidebar));
  searchToggleStatus$: Observable<any> = this.store.pipe(select(getSearchBarStatus));
  searchToggleStatus: boolean;
  closeNotification: boolean = false;
  showCategory: boolean;
  public categoryExpansion: boolean = false;
  suggestions: string[] = [];
  isSearcInputActive: boolean = false;
  showSuggestions: boolean = false;
  userTypedValue: string;
  firstTimeVisited: boolean = false;
  // new
  public isInputFocused: boolean = false;
  public mainCategories: any[] = [
    {
      id: 1,
      name: 'ტექნიკა',
      url: '/catalog/teqnika/1'
    },
    {
      id: 2,
      name: 'სახლი დეკორი ავეჯი',
      url: '/catalog/sakhli-dekori-aveji/8'
    },
    {
      id: 3,
      name: 'სპორტი და მოგზაურობა',
      url: '/catalog/sporti-da-mogzauroba/' + SPORTI_DA_MOGZAUROBA
    },
    {
      id: 4,
      name: 'სილამაზე და თავის მოვლა',
      url: '/catalog/silamaze-da-tavis-movla/' + SILAMAZE_DA_TAVIS_MOVLA
    }
  ];
  scrollbarWidth: string;
  suggestionsKeyControlIndex: number = -1;
  updateSuggestions: boolean = true;
  searchValue: string = null;
  finalSearchValue: string = null;
  public showExpressSearch = false;
  public userVerifiedStatus$ = this.verificationService.verificationState$;
  public selectImageForSearchIsUploading: Observable<boolean> = this.store.select(selectImageForSearchIsUploading);
  public selectUploadedImageForSearch: Observable<any> = this.store.select(selectUploadedImageForSearch);
  public readonly popularProductsId = POPULAR_PRODUCTS_ID;
  public selectRibbon$: Observable<any> = this.store.pipe(select(selectRibbon));
  public selectRibbonActiveState$: Observable<any> = this.store.pipe(select(selectRibbonActiveState));
  public getComparisonCount$: Observable<any>;
  public selectedAddress: ShippingAddressInteface = null;
  public hasUserPrivacyPolicyUpdated: boolean;
  protected isMobile: boolean;
  private saveExternalId: string;
  private userAddresses$: Observable<ShippingAddressInteface[]> = this.store.select(selectAddresses);
  private userAddresses: ShippingAddressInteface[] = null;
  // hide header on scroll down start
  public isVisible: boolean = true;
  private saveSearchValue = '';
  private saveSearchCase = null;
  private isSmartBannerOpen = false;
  protected isFromSafari: boolean;

  constructor(
    private store: Store<
      | fromAuth.AuthModel
      | fromUser.UserModel
      | fromLayout.SidebarModel
      | fromNavigation.RouteModel
      | fromRibbon.RibbonModel
      | SearchByImageModel
      | WishlistModel
    >,
    private oauthService: OAuthService,
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private _fb: UntypedFormBuilder,
    private productsHttp: ProductsHttp,
    @Inject(PLATFORM_ID) private platformId: Object,
    private cdr: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document,
    private mixpanel: MixpanelService,
    private imageForSearchService: ImageForSearchService,
    private fbPixelService: FbPixelService,
    private verificationService: VerificationService,
    private overflowHandlerService: OverflowHandlerService,
    private CategoryMenuService: CategoryMenuService,
    public popularBannerService: PopularBannerService,
    private addressService: AddressService,
    public expressService: ExpressService,
    public smartBannerService: SmartBannerService,
    private isMobileService: IsMobileService,
    private privacyPolicyService: PrivacyPolicyService,
    private el: ElementRef,
    private chatService: ChatService
  ) {
    super();
  }

  get selectedAddressId(): number | null {
    return this.addressService.selectedAddressId;
  }

  // new
  get searchPlaceholder(): string {
    return this.isInputFocused ? '' : 'მოძებნე რაც გაგიხარდება';
  }

  // hide header on scroll down end

  get getPlatformIsBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }

  get getSearchInputValue() {
    return this.searchForm.get('searchKeyword').value;
  }

  set getSearchInputValue(newValue: any) {
    this.searchForm.get('searchKeyword').setValue(newValue);
  }

  get disableAddressInput() {
    const url = this.router.url;
    const sections = ['checkout'];
    return !sections.some(section => url.includes(section));
  }

  // visible only homepage And when category is closed
  get showSecondLevelHeader() {
    const url = this.router.url;
    const sections = [];

    return !sections.some(section => url.includes(section));
  }

  get isExpressPage() {
    return this.router.url.includes('express');
  }

  get window() {
    if (isPlatformBrowser(this.platformId)) {
      return window.location.href;
    }
  }

  get showHr() {
    return this.router.url.length !== 1 && !this.isHome;
  }

  get imageSearchModalState(): Observable<boolean> {
    return this.imageForSearchService.getImageUploadModalState;
  }

  get showPopularBannerModal(): Observable<boolean> {
    return this.popularBannerService.showPopularBannerPopup;
  }

  get userTypedValueLength(): number {
    return this.userTypedValue?.trim().length;
  }

  ngOnInit(): void {
    this.listenPrivacyPolicyChanges();
    this.expressService
      .getSearchCase$()
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: boolean) => {
        this.showExpressSearch = value;
      });

    this.store.dispatch(GetShippingAddressesAttempt());
    this.userAddresses$
      .pipe(
        distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
        takeUntil(this.destroy$)
      )
      .subscribe((addresses: ShippingAddressInteface[]) => {
        this.userAddresses = addresses;
        if (!this.userAddresses?.length) {
          this.selectedAddress = null;
        }

        if (this.userAddresses?.length) {
          if (!this.selectedAddressId) {
            if (this.addressService.getSelectedAddressIdFromLocalStorage()) {
              this.addressService.setSelectedAddressId(this.addressService.getSelectedAddressIdFromLocalStorage());
            } else {
              this.selectedAddress = this.userAddresses?.find(address => address.isDefault) || null;
            }
          } else {
            this.selectedAddress = this.userAddresses?.find(address => address.id === this.selectedAddressId) || null;
          }
          this.expressService.updateDarkStoreData(!!this.userAddresses?.length, this.selectedAddress);

          this.cdr.markForCheck();
        }
      });

    this.addressService.selectedAddressId$
      .pipe(takeUntil(this.destroy$), distinctUntilChanged())
      .subscribe((res: number) => {
        if (this.userStatus) {
          this.selectedAddress =
            this.userAddresses?.find(address =>
              !this.selectedAddressId ? address.isDefault : address.id === this.selectedAddressId
            ) || null;
          this.cdr.markForCheck();
        }
      });

    // this.mixpanel.init();
    if (isPlatformBrowser(this.platformId)) {
      // this.popularBannerService.detectCookieBannerFirstLoadState();
      this.CategoryMenuService.closeCategories.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.openCategories();
      });
    }

    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe(params => {
      if (params['img'] && params['img'] !== this.saveExternalId) {
        this.saveExternalId = params['img'];
        this.store.dispatch(getImageByExternalIdAttempt({ externalId: this.saveExternalId }));
      } else if (params['img'] !== this.saveExternalId) {
        this.saveExternalId = null;
        this.store.dispatch(clearImageByExternalIdState());
      }
      this.cdr.markForCheck();
    });

    this.store.dispatch(getBasket());
    this.store.dispatch(getComparison());
    this.imageUploadForm = this._fb.group({
      file: null
    });

    this.searchForm = this._fb.group({
      searchKeyword: null
    });

    if (this.getPlatformIsBrowser) {
      this.scrollbarWidth = `${window.innerWidth - document.documentElement.clientWidth}px`;
    }
    this.searchTermChange();
    this.searchToggleStatus$.pipe(takeUntil(this.destroy$)).subscribe(status => {
      this.searchToggleStatus = status;
      if (this.searchToggleStatus && this.showCategory) {
        this.openCategories();
      }
    });

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.setImageSearchModalState(false);
        this.inputMobile?.nativeElement.blur();
        this.inputDesktop?.nativeElement.blur();
        const root = this.router.parseUrl(this.router.url).root;
        if (root && root.children && root.children.primary && root.children.primary.segments) {
          this.isHome = false;
          const urlSegments = root.children.primary.segments;

          if (urlSegments[0].path == 'search') {
            this.firstTimeVisited = true;
            this.cdr.markForCheck();
          }
          if (urlSegments[0].path == 'search') {
            const k = this.route.snapshot.queryParamMap.get('k');
            const img = this.route.snapshot.queryParamMap.get('img');

            if (!k && !img) {
              this.router.navigate(['/']);
              return;
            }
            let searchText = k;

            // რატომ ჰედერიდან? :/
            if (this.getPlatformIsBrowser) {
              this.store.dispatch(setLoad());
            }

            this.getSearchInputValue = searchText?.split('-').join(' ');
          } else {
            this.clearSearch();
          }
        } else {
          this.clearSearch();
          this.isHome = true;
        }
        this.openCategories(false, true);
        this.cdr.markForCheck();
      }
    });
    this.userStatus$.pipe(takeUntil(this.destroy$)).subscribe(status => {
      this.store.dispatch(GetShippingAddressesAttempt());
      this.userStatus = status;
      const unauthorisedUserAddress = localStorage.getItem(UNAUTHORIZED_USER_ADDRESS);
      if (!this.userStatus && unauthorisedUserAddress) {
        this.selectedAddress = JSON.parse(unauthorisedUserAddress);
        this.expressService.updateDarkStoreData(!!unauthorisedUserAddress, this.selectedAddress);

        this.cdr.detectChanges();
      }
      if (this.userStatus && unauthorisedUserAddress) {
        this.store.dispatch(
          AddShippingAddressAttempt({
            shippingAddress: JSON.parse(unauthorisedUserAddress),
            force: true
          })
        );
        localStorage.removeItem(UNAUTHORIZED_USER_ADDRESS);
      }
    });
    this.expressService
      .getDarkStoreData$()
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: darkStore) => {
        const unauthorisedUserAddress = localStorage.getItem(UNAUTHORIZED_USER_ADDRESS);
        if (!this.userStatus && unauthorisedUserAddress) {
          this.selectedAddress = JSON.parse(unauthorisedUserAddress);
          this.cdr.detectChanges();
        }
      });
    if (this.getPlatformIsBrowser) {
      this.store.dispatch(getCategories());
    }
    this.getComparisonCount$ = this.store.select(getComparisonProductCount);

    this.detectMobileDevice();

    if (isPlatformBrowser(this.platformId)) {
      this.isFromSafari = SmartbannerFn.isFromSafari();
      this.smartBannerService.isFromSafari = this.isFromSafari;
    }
  }

  hideExpressPopup() {
    this.expressService.isExpressAddressSuggestionClosed = true;
    this.expressService.showAddressSuggestionPopup = false;
    this.overflowHandlerService.show();
  }

  ngAfterViewInit(): void {
    // for mobile devices, should be better solution
    if (isPlatformBrowser(this.platformId)) {
      const scroll$ = fromEvent(window, 'scroll').pipe(
        throttleTime(10),
        map(() => window.scrollY),
        pairwise(),
        map(([y1, y2]) => ({
          scrollPosition: y2,
          direction: y2 < y1 ? Direction.Up : window.scrollY > (this.isSmartBannerOpen ? 360 : 280) && Direction.Down
        })),
        distinctUntilChanged(
          (prev, curr) => prev.direction === curr.direction && prev.scrollPosition === curr.scrollPosition
        ),
        share()
      );

      const scrollDown$ = scroll$.pipe(filter(({ direction }) => direction === Direction.Down));

      this.smartBannerService.openSmartBanner$.pipe(takeUntil(this.destroy$)).subscribe(smartBannerRes => {
        this.isSmartBannerOpen = smartBannerRes;
        scroll$.pipe(takeUntil(this.destroy$)).subscribe(scrollPosRes => {
          if (scrollPosRes.scrollPosition <= 360 && smartBannerRes) {
            this.isVisible = true;
            this.cdr.markForCheck();
          }

          if (scrollPosRes.scrollPosition <= 280 && !smartBannerRes) {
            this.isVisible = true;
            this.cdr.markForCheck();
          }
        });
      });

      scrollDown$.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.isVisible = false;
        this.cdr.markForCheck();
      });
    }
  }

  searchByImage(fileInput: any) {
    const fileData = <File>fileInput.target.files[0];

    // THIS CONDITION IS TEMPORARY VERSION
    if (fileData && fileData.type.split('/')[0] === 'image') {
      const formData = new FormData();
      formData.append('file', fileData);
      this.mixpanel.trackImageSearch(fileData.name);
      this.store.dispatch(UploadSearchImageAttempt({ imageData: formData }));
    }
  }

  clearUploadedSearchImage() {
    this.saveExternalId = null;
    this.store.dispatch(clearImageByExternalIdState());
    const { k } = this.route.snapshot.queryParams;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { k },
      queryParamsHandling: ''
    });
  }

  scrollTop(): void {
    if (this.getPlatformIsBrowser) {
      const root = document.getElementsByTagName('html')[0];
      root.classList.add('scroll-behavior');
      this.timeoutIds.push(
        setTimeout(() => {
          window.scrollTo(0, 0);
          root.classList.remove('scroll-behavior');
        })
      );
    }
  }

  openBasket(): void {
    this.store.dispatch(sidebarOpen({ burgerStatus: true, activeComponent: 'basket' }));
  }

  search(val?, suggestion?, $event?): void {
    this.mixpanel.trackSearch(this.getSearchInputValue);

    if ($event && this.saveSearchValue === val && this.saveSearchCase === this.expressService.getSearchCase()) {
      $event.preventDefault();
    }
    this.saveSearchValue = val;
    this.saveSearchCase = this.expressService.getSearchCase();
    // old
    if (suggestion) {
      val = suggestion;
    }
    this.getProductSuggestions(val);
    this.previousSearchInputValue = val;
    this.showSuggestions = false;
    this.cdr.markForCheck();
    if (isPlatformBrowser(this.platformId)) {
      this.fbPixelService.search(val);
    }
    if (val) {
      val = val.split(' ').join('-');
      const { cat, p, ...others } = this.route.snapshot.queryParams;
      this.router.navigate(['search'], {
        queryParams: {
          // ...others,
          k: val,
          // p: 1
          deliveryTypes: this.expressService.getSearchCase() ? FilterDeliveryTypes.Express : null
        },
        queryParamsHandling: ''
      });
    }
    if (!val) {
      const { k, p, cat, ...others } = this.route.snapshot.queryParams;
      this.router.navigate(['search'], {
        queryParams: {
          // ...others,
          // p: 1
        },
        queryParamsHandling: ''
      });
    }
  }

  logout(): void {
    if (!this.getPlatformIsBrowser) {
      return;
    }
    this.chatService.logOut();
    this.mixpanel.reset();
    localStorage.removeItem('loginMethod');
    this.oauthService.logOut(true);
    this.store.dispatch(logOut());
    this.store.dispatch(removeUser());
    this.store.dispatch(clearComparison());
    this.expressService.clearDarkStoreData();
    if (this.getPlatformIsBrowser) {
      this.document.location.href = environment.apiUrl + 'api/Account/SignOut?returnUrl=' + window.location.origin;

      // this.router.navigate(['/'])
    }
  }

  toSignIn(): void {
    this.chatService.closeChat();
    this.store.dispatch(sidebarOpen({ burgerStatus: true, activeComponent: 'sign-in' }));
  }

  clearSearch(): void {
    this.searchForm.get('searchKeyword').setValue('');
    if (this.route.snapshot.queryParamMap.get('img')) {
      this.search();
    }
  }

  setImageSearchModalState(value: boolean = false) {
    this.layoutStatus$
      .subscribe(status => {
        this.imageForSearchService.setImageSearchModalState(value, status.mobileFilter || value);
      })
      .unsubscribe();
  }

  removeExpressSearch(): void {
    this.expressService.removeSearchCase();
  }

  toggleSearchBar(): void {
    this.store.dispatch(searchToggle());
  }

  // ჯერჯერობით არ ვიყენებთ
  focusOut() {
    this.isInputFocused = false;
    this.timeoutIds.push(
      setTimeout(() => {
        this.showSuggestions = false;
        this.isSearcInputActive = false;
        this.cdr.markForCheck();
      }, 300)
    );
  }

  focusOutMobile() {
    this.isInputFocused = false;
    this.timeoutIds.push(
      setTimeout(() => {
        this.showSuggestions = false;
        this.isSearcInputActive = false;
        this.cdr.markForCheck();
      }, 300)
    );
  }

  onfocus() {
    this.isSearcInputActive = true;
    this.isInputFocused = true;
    if (this.searchForm.get('searchKeyword').value?.length >= 3) {
      this.showSuggestions = true;
      if (!this.suggestions.length) {
        this.getProductSuggestions(this.searchForm.get('searchKeyword').value);
      }
    }
  }

  getFirstLettersOfUserInput(value: string) {
    return value.substring(0, this.userTypedValueLength);
  }

  getLastLettersOfUserInput(value: string) {
    return value.substring(this.userTypedValueLength);
  }

  markSuggestion(event) {
    const increment = event.key === 'ArrowUp' ? -1 : 1;
    this.suggestionsKeyControlIndex += increment;
    if (this.suggestionsKeyControlIndex >= this.suggestions.length) {
      this.suggestionsKeyControlIndex = -1;
    } else if (this.suggestionsKeyControlIndex < -1) {
      this.suggestionsKeyControlIndex = this.suggestions.length - 1;
    }

    this.finalSearchValue =
      this.suggestionsKeyControlIndex === -1 ? this.searchValue : this.suggestions[this.suggestionsKeyControlIndex];
    this.searchForm.get('searchKeyword').setValue(this.finalSearchValue);
  }

  // გააჩნია მომავალში რას ვაპირენთ საჯეშენზე
  getProductSuggestions(value) {
    this.productsHttp
      .getProductSuggestionsGoGo(value)
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.suggestions = [];
        this.suggestionsKeyControlIndex = -1;
        this.userTypedValue = value;
        if (res && res.length) {
          // this.formattedUserTypedValue = res[0].substring(0, value.length);
          res.forEach(e => {
            this.suggestions.push(e);
          });
        }
        if (res.length && this.isSearcInputActive && !this.firstTimeVisited) {
          this.showSuggestions = true;
        } else {
          this.showSuggestions = false;
        }
        this.cdr.markForCheck();
        this.firstTimeVisited = false;
      });
  }

  updateSuggestionsVariable(event) {
    this.updateSuggestions = !(event.key === 'ArrowUp' || event.key === 'ArrowDown');
  }

  // საჯეშენი არ გვჭირდება
  searchTermChange() {
    this.searchForm
      .get('searchKeyword')
      .valueChanges.pipe(debounceTime(160), takeUntil(this.destroy$))
      .subscribe((value: string) => {
        // value= value.replace(/^\s+|\s+$|\s+(?=\s)/g, "")
        // es damokidebulia imaze suggestions gveqneba Tu ara, shesadzloa dakomentardes
        if (this.updateSuggestions && value?.length >= 3 && value != this.previousSearchInputValue) {
          // this.isSearcInputActive = true;
          this.getProductSuggestions(value);
        } else if (value?.length < 3) {
          this.showSuggestions = false;
          this.cdr.markForCheck();
        }
        this.previousSearchInputValue = value;
      });
  }

  // arsad arafershi ar iyenebs da ravici aba
  saveReferrer() {
    if (this.getPlatformIsBrowser) {
      this.store.dispatch(
        moveToNextRoute({
          currentLocation: document.location.href,
          previousLocation: document.location.href
        })
      );
      this.cdr.markForCheck();
    }
  }

  openCategories(expansion: boolean = false, close?: boolean): void {
    this.categoryExpansion = expansion;
    this.showCategory = !this.showCategory && !close;
    this.dynamicHost.viewContainerRef.clear();
    if (this.showCategory && this.searchToggleStatus) {
      this.toggleSearchBar();
    }

    if (this.getPlatformIsBrowser) {
      if (this.showCategory) {
        this.overflowHandlerService.hide();
        const viewContainerRef = this.getViewContainerRef();
        let data = {
          dropdown: true,
          homeBannerWidth: '600',
          homeBannerHeight: '600',
          expansion: this.categoryExpansion
        };
        this.CategoryMenuService.setData(data);
        import('../../shared/components/category/category.component').then(c => {
          viewContainerRef.createComponent<any>(c.CategoryComponent);
          this.cdr.detectChanges();
        });

        this.timeoutIds.push(
          setTimeout(() => {
            if (document.getElementById('category-holder')) {
              if (!this.closeNotification) {
                document
                  .getElementById('category-holder')
                  .classList.add('_s_position-t-px--16', '_s_md-position-t-px--20');
                document
                  .getElementById('category-holder')
                  .classList.remove('_s_position-t-px--25', '_s_md-position-t-px--29');
              } else {
                document
                  .getElementById('category-holder')
                  .classList.remove('_s_position-t-px--16', '_s_md-position-t-px--20');
                document
                  .getElementById('category-holder')
                  .classList.add('_s_position-t-px--25', '_s_md-position-t-px--29');
              }
            }
          }, 0)
        );
      } else {
        this.layoutStatus$
          .subscribe(status => {
            if (!status.mobileFilter) {
              this.overflowHandlerService.show();
            }
          })
          .unsubscribe();
      }
    }
  }

  public hidePop(): void {
    this.popularBannerService.closePopup();
  }

  public openAddressFormModal(): void {
    if (!this.disableAddressInput || !this.hasUserPrivacyPolicyUpdated) {
      return;
    }

    this.addressService.isFromExpress = true;
    if (this.userAddresses && this.userAddresses?.length && this.userStatus) {
      this.addressService.openAddressListingModal();
    } else {
      if (localStorage.getItem(UNAUTHORIZED_USER_ADDRESS)) {
        this.addressService.editAddressData = JSON.parse(localStorage.getItem(UNAUTHORIZED_USER_ADDRESS));
      }
      this.addressService.openAddressFormModal();
    }
    this.cdr.markForCheck();
  }

  detectMobileDevice() {
    this.isMobileService.isMobileDevice.pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
      this.isMobile = res;
      this.cdr.markForCheck();
    });
  }

  setExpressSearchCase(): void {
    this.expressService.addSearchCase();
  }

  private getViewContainerRef(): ViewContainerRef {
    const viewContainerRef = this.dynamicHost.viewContainerRef;
    viewContainerRef.clear();
    return viewContainerRef;
  }

  private listenPrivacyPolicyChanges() {
    this.privacyPolicyService.hasUserPrivacyPolicyUpdated$.pipe(takeUntil(this.destroy$)).subscribe({
      next: res => {
        this.hasUserPrivacyPolicyUpdated = res;
        this.cdr.detectChanges();
      }
    });
  }
}
