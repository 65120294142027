import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { getBannersFail, getBannersSuccess } from '../actions/banner.actions';
import { BannerModel, BannerPlaceType, BannerSection } from '../models/banner.model';

export const initialState: BannerModel = {
  bannerPlaceName: '',
  bannerPlaceId: 0,
  section: 0,
  bannerPlaceType: 1,
  banners: [],
  error: ''
};

const bannerReducer = createReducer(
  initialState,
  on(getBannersSuccess, (state, action: any) => {
    const data = [...action.banners.data];
    const dataObject = {};
    data.forEach(item => {
      if (!dataObject[item.section]) {
        dataObject[item.section] = {
          [BannerPlaceType.Web]: [],
          [BannerPlaceType.Mobile]: [],
          [BannerPlaceType.Ribbon]: []
        };
      }
      dataObject[item.section][item.bannerPlaceType].push(...item.banners);
    });
    if (dataObject[BannerSection.BannerCarousel]) {
      dataObject[BannerSection.BannerCarousel][BannerPlaceType.Web].sort((a, b) => a.sortIndex - b.sortIndex);
    }
    return { ...state, ...action.banners, data: dataObject };
  }),
  on(getBannersFail, (state, action) => ({
    banners: state.banners,
    error: action.error,
    bannerPlaceName: '',
    bannerPlaceId: 0,
    section: 0,
    bannerPlaceType: 1
  }))
);

export function reducer(state: BannerModel, action: any) {
  return bannerReducer(state, action);
}

export const selectBannersFeatureState = createFeatureSelector<BannerModel>('banners');

export const BannersStatus = (state: BannerModel) => state;

export const selectBannersState = createSelector(selectBannersFeatureState, BannersStatus);
