import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as mixpanel from 'mixpanel-browser';
import * as fromAuth from 'projects/web/src/app/store/models/auth.model';
import { UserModel } from 'projects/web/src/app/store/models/user.model';
import { selectAuthFeatureState } from 'projects/web/src/app/store/reducers/auth.reducer';
import { getUserData } from 'projects/web/src/app/store/reducers/user.reducer';
import { environment } from 'projects/web/src/environments/environment';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

// OPTIMIZABLE Can be moved to separate file later
enum ActionTypes {
  checkout = '_EXTRA_checkout_Success',
  checkoutFailed = '_EXTRA_checkout failed',
  addToBasket = '_EXTRA_Added item to cart',
  addToWishlist = '_EXTRA_Added item to wishlist',
  loggedIn = '_EXTRA_User logged in',
  registered = '_EXTRA_User Registered',
  proceedCheckout = '_EXTRA_Proceed Checkout',
  detailedPageVisit = '_EXTRA_Detailed_Page',
  merchantVisit = '_EXTRA_Merchant Page',
  search = '_EXTRA_Search',
  imageSearch = '_EXTRA_Image Search',
  catalog = 'EXTRA_Catalog',
  buyNow = '_EXTRA_Buy Now',
  installment = '_EXTRA_Installment'
}

@Injectable({
  providedIn: 'root'
})
export class MixpanelService {
  checkoutData: any;
  userToken: string;
  getAuthStatus$: Observable<fromAuth.AuthModel> = this.store.pipe(select(selectAuthFeatureState));
  user$: Observable<UserModel> = this.store.pipe(select(getUserData));

  authStatus = this.getAuthStatus$.pipe(take(1));

  userStatus = this.user$.pipe();

  constructor(private store: Store<fromAuth.AuthModel>) {
    if (environment.production) {
      this.userToken = 'a10530525ab09e45c0f6361b8b70b913';
    } else if (environment.develop) {
      this.userToken = '28948c9800d81087e48c9ba49ae7810a';
    } else if (environment.staging) {
      this.userToken = '54821f32650bae71601d5f3fe2d1243c';
    }
  }

  /**
   * Initialize mixpanel.
   *
   * @param {string} userToken
   * @memberof MixpanelService
   */

  // staging token
  init(): void {
    mixpanel.init(this.userToken);
    // mixpanel.identify(userToken);
  }

  /**
   * Push new action to mixpanel.
   *
   * @param {string} id Name of the action to track.
   * @param {*} [action={}] Actions object with custom properties.
   * @memberof MixpanelService
   */

  // Can be used for anything
  track(id: string, action: any = {}): void {
    mixpanel.track(id, action);
  }

  alias(uniqueId: string, oldId?: string) {
    if (+uniqueId) {
      mixpanel.alias('995' + uniqueId);
    } else {
      mixpanel.alias(uniqueId);
    }
  }

  identify(uniqueId: string) {
    mixpanel.identify(uniqueId);
  }

  reset() {
    mixpanel.reset();
  }

  // Types can be created when exact data needed is known
  trackBasketAdd(id: string, category?: string, quantity?: any): void {
    this.userStatus.subscribe(res => {
      if (res?.id) {
        mixpanel.track(ActionTypes.addToBasket, {
          '_EXTRA_PRODUCT-ID': id,
          _EXTRA_CATEGORY: category
        });
      } else {
        return;
      }
    });
  }

  trackWishlistAdd(id: string, category?: string, quantity?: any): void {
    this.userStatus.subscribe(res => {
      if (res?.id) {
        mixpanel.track(ActionTypes.addToWishlist, {
          '_EXTRA_PRODUCT-ID': id,
          _EXTRA_CATEGORY: category
        });
      } else {
        return;
      }
    });
  }

  getDataForBuyAndInstallment(
    productName: string,
    productId: number,
    category: string,
    price: number,
    isInstallment?: boolean
  ) {
    this.userStatus.subscribe(res => {
      if (res?.id) {
        let productData = {
          _EXTRA_PRODUCT_ID: productId,
          _EXTRA_PRODUCT_NAME: productName,
          _EXTRA_CATEGORY: category,
          _EXTRA_PRICE: price
        };
        if (isInstallment) {
          mixpanel.track(ActionTypes.installment, productData);
        } else {
          mixpanel.track(ActionTypes.buyNow, productData);
        }
      }
    });
  }

  // There seems to be no 'form' interface, formValues could use it for better TS support
  getDataOnCheckout(
    successful: boolean,
    shippingOption: string,
    userID: string,
    formValues: any,
    paymentType: string,
    basket: any,
    instalmentBank?: string,
    promocode?: string,
    promocodeValidity?: string,
    BayingFrom?: boolean,
    catalogSearchIs?: boolean,
    catalogSearch?: string
  ) {
    this.userStatus.subscribe(res => {
      if (res?.id) {
        let productIdAndCount: object[] = [];
        if (basket !== undefined) {
          basket?.checkout?.items?.forEach(el => {
            let { productId, productCount } = el;
            productIdAndCount.push({
              _EXTRA_PRODUCT_ID: productId,
              _EXTRA_PRODUCT_COUNT: productCount
            });
          });
        }

        let catalogData = {
          // '_EXTRA_USER_ID': userID || undefined,
          // '_EXTRA_PERSONAL_INFO': {
          //   '_EXTRA_FIRST_NAME': formValues?.firstName,
          //   '_EXTRA_LAST_NAME': formValues?.lastName,
          //   '_EXTRA_FULL_NAME': `${formValues?.firstName} ${formValues?.lastName}`,
          //   '_EXTRA_TEL_NUM': formValues?.phoneNumber,
          //   '_EXTRA_CITY': formValues?.location,
          //   '_EXTRA_ADDRESS': formValues?.address,
          // },
          _EXTRA_CATEGORY: catalogSearch
        };

        this.checkoutData = {
          _EXTRA_USER_ID: userID,
          _EXTRA_PERSONAL_INFO: {
            _EXTRA_FIRST_NAME: formValues?.firstName,
            _EXTRA_LAST_NAME: formValues?.lastName,
            _EXTRA_FULL_NAME: `${formValues?.firstName} ${formValues?.lastName}`,
            _EXTRA_TEL_NUM: formValues?.phoneNumber
          },
          _EXTRA_PAYMENT_TYPE: paymentType,
          _EXTRA_DELIVERY_TYPE: shippingOption,
          _EXTRA_PRODUCT_INFO: {
            _EXTRA_COUNT_AND_ID: productIdAndCount
          },
          _EXTRA_COSTS: {
            _EXTRA_SUBTOTAL: basket?.checkout.totalPrice,
            _EXTRA_DELIVERY_AMOUNT: basket?.checkout.shippingPrice
          },
          _EXTRA_PROMOCODE_INFO: {
            _EXTRA_PROMOCODE: promocode,
            _EXTRA_PROMOCODE_VALIDITY: promocodeValidity
          },
          _EXTRA_CITY: formValues?.location,
          _EXTRA_ADDRESS: formValues?.address,
          _EXTRA_BUYING_FROM: localStorage.getItem('buying_from')
        };
        if (catalogSearchIs) {
          mixpanel.track(ActionTypes.catalog, catalogData);
        }
        // else if (instalmentBank) {
        //   this.checkoutData._EXTRA_INSTALLMENT_BANK = instalmentBank
        //   // new, lets test
        //   mixpanel.track(
        //     ActionTypes.installment,
        //     this.checkoutData
        //   )
        // }
      }
    });
  }

  proceedCheckout(basket, totalPrice) {
    this.userStatus.subscribe(res => {
      if (res?.id) {
        let productIdAndCount: object[] = [];
        basket.forEach(el => {
          let { productId, productCount = 1 } = el;
          productIdAndCount.push({
            _EXTRA_PRODUCT_ID: productId,
            _EXTRA_PRODUCT_COUNT: productCount
          });
        });

        mixpanel.track(ActionTypes.proceedCheckout, {
          _EXTRA_PRODUCTS: productIdAndCount,
          _EXTRA_TOTAL_PRICE: totalPrice
        });
      } else {
        return;
      }
    });
  }

  // To track login events, we call this together with Identify (not really necessary, as identify does this by default)

  // NOT USED AS NEEDED, NEEDS WORK
  // trackLogIn(
  //     userID: string,
  //     firstName: string,
  //     lastName: string,
  //     authMethod: any
  // ) {
  //     mixpanel.track(
  //         ActionTypes.loggedIn,
  //         {
  //             'USER_ID': userID,
  //             'FIRST_NAME': firstName,
  //             'LAST_NAME': lastName,
  //             'AUTH_METHOD': authMethod,
  //             'TIME': new Date().toString(),
  //         }
  //     )
  // }

  // For normal registration tracking
  // trackNormalRegistration(data: SingUpData, externalUserId: number) {
  //   mixpanel.track(ActionTypes.registered,
  //     {
  //       '_EXTRA_FIRST_NAME': data.firstName,
  //       '_EXTRA_LAST_NAME': data.lastName,
  //       '_EXTRA_EMAIL_OR_PHONE': data.emailOrPhone,
  //       '_EXTRA_BIRTH_DATE': data.birthDate,
  //       '_EXTRA_TYPE': data.type,
  //       '_EXTRA_USER_ID': externalUserId,
  //       '_EXTRA_REGISTRATION_DATE': new Date().toString()
  //     }
  //   )
  // }

  authUser(data: UserModel, authMethod?: string) {
    if (data?.userExternalId) {
      let userDataForMixpanel = {
        _EXTRA_FIRST_NAME: data.firstName,
        _EXTRA_LAST_NAME: data.lastName,
        _EXTRA_FULL_NAME: `${data?.firstName} ${data?.lastName}`,
        _EXTRA_PHONE: data.phoneNumber,
        _EXTRA_EMAIL: data.email,
        _EXTRA_BIRTH_DATE: data.birthDate,
        _EXTRA_USER_ID: data.userExternalId,
        '_EXTRA_AUTH_DATE/TIME': new Date().toString(),
        _AUTH_METHOD: authMethod ? authMethod : data?.phoneNumber ? 'Phone Number' : data?.email ? 'Email' : null
      };

      mixpanel.register(userDataForMixpanel);

      if (data?.userId) {
        this.identify(data.userId);

        mixpanel.people.set({
          _EXTRA_FIRST_NAME: data.firstName,
          _EXTRA_LAST_NAME: data.lastName,
          _EXTRA_FULL_NAME: `${data?.firstName} ${data?.lastName}`,
          _EXTRA_PHONE: data.phoneNumber,
          _EXTRA_EMAIL: data.email,
          _EXTRA_BIRTH_DATE: data.birthDate,
          _AUTH_METHOD: authMethod ? authMethod : data?.phoneNumber ? 'Phone Number' : data?.email ? 'Email' : null,
          $first_name: data.firstName,
          $last_name: data.lastName,
          $phone: data.phoneNumber,
          $email: data.email
        });
      }

      mixpanel.track(ActionTypes.loggedIn);
    }
  }

  detailedPageTrack(category: string) {
    this.userStatus.subscribe(status => {
      if (status?.id) {
        mixpanel.track(ActionTypes.detailedPageVisit, {
          _EXTRA_Category: category
        });
      }
    });
  }

  trackMerchantVisit(merchantName: string) {
    this.userStatus.subscribe(status => {
      if (status?.id) {
        mixpanel.track(ActionTypes.merchantVisit, {
          _EXTRA_MERCHANT_NAME: merchantName
        });
      }
    });
  }

  trackSearch(keyword: string) {
    this.userStatus.subscribe(status => {
      if (status?.id) {
        mixpanel.track(ActionTypes.search, {
          _EXTRA_KEYWORD: keyword
        });
      }
    });
  }

  trackImageSearch(imageName: string) {
    this.userStatus.subscribe(res => {
      if (res?.id) {
        mixpanel.track(ActionTypes.imageSearch, {
          _EXTRA_IMAGE_NAME: imageName
        });
      }
    });
  }

  // socAuth(data) {
  //     let userDataForMixpanel = {
  //         '_EXTRA_FIRST_NAME': data.firstName,
  //         '_EXTRA_LAST_NAME': data.lastName,
  //         // '_EXTRA_PHONE': data.phoneNumber,
  //         '_EXTRA_EMAIL': data.email,
  //         '_EXTRA_BIRTH_DATE': data.birthDate,
  //         '_EXTRA_USER_ID': data.userExternalId
  //     }
  //     console.log(data);
  // }
}
