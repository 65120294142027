import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import {
  clearImageByExternalIdState,
  getImageByExternalIdAttempt,
  getImageByExternalIdFailed,
  getImageByExternalIdSuccess,
  UploadSearchImageAttempt,
  UploadSearchImageFailed,
  UploadSearchImageSuccess
} from '../actions/search-by-image.action';
import { SearchByImageModel } from '../models/search-by-image.model';
import { SearchByImageStates } from '../states/search-by-image.state';

export const SearchByImageReducer = createReducer(
  SearchByImageStates,
  on(UploadSearchImageAttempt, state => ({ ...state, uploadImage: { loading: true, error: null } })),
  on(UploadSearchImageSuccess, state => ({ ...state, uploadImage: { loading: false, error: null } })),
  on(UploadSearchImageFailed, (state, action) => ({ ...state, uploadImage: { loading: false, error: action } })),

  on(getImageByExternalIdAttempt, state => ({
    ...state,
    getImageByExternalId: { data: null, loading: true, error: null }
  })),
  on(getImageByExternalIdSuccess, (state, action) => ({
    ...state,
    getImageByExternalId: { data: action, loading: false, error: null }
  })),
  on(getImageByExternalIdFailed, (state, action) => ({
    ...state,
    getImageByExternalId: { data: null, loading: false, error: action }
  })),
  on(clearImageByExternalIdState, state => ({
    ...state,
    getImageByExternalId: { data: null, loading: false, error: null }
  }))
);

export function reducer(state: SearchByImageModel, action: Action) {
  return SearchByImageReducer(state, action);
}

export const selectSearchByImageState = createFeatureSelector<SearchByImageModel>('searchByImage');

export const selectImageForSearchIsUploading = createSelector(
  selectSearchByImageState,
  data => data.uploadImage.loading
);
export const selectUploadedImageForSearch = createSelector(
  selectSearchByImageState,
  data => data.getImageByExternalId.data
);
