export interface OrdersModel {
  data: OrderModel[];
  totalCount: number;
}

export interface GetOrdersModel {
  page: number;
  pageSize: number;
  OrderFilterForCustomerType?: number;
}

export interface OrderModel {
  id: number;
  createTime: Date;
  userId: string;
  firstName: string;
  lastName: string;
  mobile: string;
  identityNumber: string;
  locationId: number;
  bankOfInstalments: BanksEnum;
  location: string;
  address: string;
  orderLines: {
    deliveryDate: string;
    lines: any[];
    orderLineForCustomerViewType: OrderLineForCustomerViewType;
    open?: boolean;
    lineHasPriceDifference?: boolean;
  }[];
  paymentType: PaymentType;
  shippingPrice: number;
  subTotalPrice: number;
  totalPrice: number;
  additionalInformation: string;
  externalId: string;
  open: boolean;
  orderForCustomerViewType: OrderForCustomerViewType;
}

export enum BanksEnum {
  BOG = 1,
  TBC,
  CREDO,
  Crystal = 5,
  Tera
}

export enum OrderFilterForCustomerType {
  Current = 1,
  Delivered,
  Cancelled
}

export enum OrderForCustomerViewType {
  Default,
  Recived = 1,
  Waiting,
  Process,
  Collect,
  Prepare,
  Bring,
  DeliveredC,
  DeliveredP,
  Cancelled,
  DeliveredAtPickup = 10
}

export enum OrderLineForCustomerViewType {
  Default,
  Recived = 1,
  Waiting = 2,
  Process = 3,
  Collect = 4,
  Prepare = 5,
  Bring = 6,
  DeliveredC = 7,
  DeliveredP = 8,
  Cancelled = 9,
  DeliveredAtPickup = 10
}

export enum PaymentType {
  IPay = 1,
  Instalmets = 2,
  CashOnHand = 3,
  ApplePay = 5,
  Balance = 8,
  VisaDiscount = 9,
  PlusPoints = 10,
  MasterCard = 11,
  VisaDigitalCard = 12,
  PartByPart = 13,
  GooglePay = 14,
  PartByPartTBC = 15,
  TBCPay = 16,
  CombinedPayment = 17
}

export enum BankType {
  BOG,
  TBC
}

export enum IPayInstallmentStatus {
  Confirmed = 1,
  Failed = 3
}

export enum ExpressOrderFinancialType {
  Dept = 1,
  Refund = 2
}

export enum ExpressOrderFinancialStatus {
  UnPayed = 0,
  Payed = 1
}

export enum ShippingOptionType {
  Courier = 1,
  Pickup = 2
}
export interface PayExpressOrderDebtModel {
  id: string;
  paymentType: number;
  referrerType: ReferrerType;
  cardId: number;
}

export enum OrderDeliveryType {
  Delivery = 1,
  PickUpExtra,
  PickUpMerchant,
  Manual,
  Pickup
}

export interface ExpressOrderDebtPaymentSuccessModel {
  href: string;
  method: string;
  rel: string;
}

export enum ReferrerType {
  Web,
  Mobile
}
