import { FormControl } from '@angular/forms';

export interface Voucher {
  id: number;
  price: number;
  assets: VoucherAsset[];
  externalQuantity: number;
}

export interface VoucherBody {
  productId: number;
  type: VoucherType;
  fileName: string;
  price: number;
  vouchersCount: number;
  recipientName?: string;
  recipientEmail?: string;
  recipientPhone?: number;
  letterContent: string;
  electricAlso: boolean;
}

export interface VoucherAsset {
  fileName: string;
  sortIndex: number;
  url: string;
}

export enum VoucherName {
  productId = 'productId',
  type = 'type',
  fileName = 'fileName',
  price = 'price',
  recipientName = 'recipientName',
  recipientEmail = 'recipientEmail',
  recipientPhone = 'recipientPhone',
  vouchersCount = 'vouchersCount',
  letterContent = 'letterContent',
  electricAlso = 'electricAlso'
}

export type VoucherForm = {
  [k in keyof VoucherBody]: FormControl;
};

export type VoucherTypes = {
  name: VoucherTypeName;
  type: VoucherType;
}[];

export enum VoucherType {
  phisical = 1,
  electric = 2,
  phisicalAndAlsoElectric = 3
}

export enum VoucherTypeName {
  phisical = 'ფიზიკური',
  electric = 'ელექტრონული'
}

export type RecipientEmailOrPhone = 'recipientEmail' | 'recipientPhone';

export interface VoucherValidate {
  code: string;
  price: number;
  status: VoucherValidateStatus;
}

export enum VoucherValidateStatus {
  Created = 1, // არ მოიძებნა
  Active = 2, // როცა გადაიხადა
  Used = 3, // უკვე გამოყენებული
  Cancelled = 4 // არ მოიძებნა
}

export enum VoucherValidateErrorName {
  notFound = 'არასწორი კოდი',
  used = 'კოდი გამოყენებულია'
}

export interface Validation {
  status: 'success' | 'error';
  errorMessage?: VoucherValidateErrorName;
}

export interface UseVoucherBody {
  userId: string;
  amount: number;
  voucherCode: string;
}

export const STORAGE_VOUCHER = 'voucherdData';

export interface StorageVoucher {
  voucherIds: string[];
}

export interface VoucherDetail {
  url: string;
  price: number;
  voucherCode: string;
  recipientName: string;
  message: string;
}

export enum VoucherInvoiceAddress {
  Web,
  Cms
}
