import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { OrderingHttp, PaymentHttp } from 'lib-core';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import {
  getCustomerOrders,
  getCustomerOrderSuccess,
  PayExpressOrderDebt,
  PayExpressOrderDebtFailure,
  PayExpressOrderDebtSuccess
} from '../actions/orders.actions';
import { ExpressOrderDebtPaymentSuccessModel, OrdersModel } from '../models/orders.model';
import { of } from 'rxjs';
import { OrderDebtService } from '../../services/order-debt.service';

@Injectable()
export class OrdersEffects {
  constructor(
    private actions$: Actions,
    private orderingHttp: OrderingHttp,
    private paymentHttp: PaymentHttp,
    private orderDebtService: OrderDebtService
  ) {}

  getOrders = createEffect(() =>
    this.actions$.pipe(
      ofType(getCustomerOrders),
      switchMap(params => this.orderingHttp.getCustomerOrders(params)),
      map((data: OrdersModel[]) => getCustomerOrderSuccess({ orders: data }))
    )
  );

  PayExpressOrderDebt = createEffect(() =>
    this.actions$.pipe(
      ofType(PayExpressOrderDebt),
      switchMap(params =>
        this.paymentHttp.expressOrderDebtPayment(params).pipe(
          map((data: ExpressOrderDebtPaymentSuccessModel) => PayExpressOrderDebtSuccess(data)),
          catchError(error => {
            return of(PayExpressOrderDebtFailure({ error }));
          })
        )
      )
    )
  );
  PayExpressOrderDebtSuccessEffect = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PayExpressOrderDebtSuccess),
        tap((action: ExpressOrderDebtPaymentSuccessModel) => {
          // this.orderingHttp.closeModal();
          this.orderDebtService.handlePaymentSuccess(action);
        })
      ),
    { dispatch: false }
  );
}
