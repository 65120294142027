import { Injectable } from '@angular/core';
import { ProductModel } from '../store/models/product.model';
import { PixelInitiateCheckoutData } from '../shared/models/fb-pixel.model';

declare let fbq: Function;

@Injectable({
  providedIn: 'root'
})
export class FbPixelService {
  constructor() {}

  public pageView(): void {
    fbq('track', 'PageView');
  }

  public search(param: string): void {
    fbq('track', 'Search', {
      search_string: param
    });
  }

  public addToCart(product: ProductModel): void {
    fbq('track', 'AddToCart', {
      value: product.discountedPrice || product.sellPrice || 0,
      currency: 'GEL',
      content_name: product.title,
      content_type: 'product',
      content_ids: [product.id]
    });
  }

  public initiateCheckout(data: PixelInitiateCheckoutData): void {
    fbq('track', 'InitiateCheckout', {
      content_ids: data.content_ids,
      contents: data.contents,
      currency: 'GEL',
      content_type: 'product',
      num_items: data.num_items,
      value: data.value
    });
  }

  // don't need at the moment. handled by backend
  public purchase(data: PixelInitiateCheckoutData): void {
    fbq('track', 'Purchase', {
      content_category: 'checkout',
      content_ids: data.content_ids,
      value: data.value,
      currency: 'GEL',
      contents: data.contents,
      content_type: 'product',
      num_items: data.num_items
    });
  }

  public viewContent(product: ProductModel): void {
    fbq('track', 'ViewContent', {
      content_name: product.title,
      content_category: product.categoryName,
      content_ids: [product.id],
      content_type: 'product',
      value: product.sellPrice ? (!product.discountedPrice ? product.sellPrice : product.discountedPrice) : 0,
      currency: 'GEL'
    });
  }

  public completeRegistration(): void {
    fbq('track', 'CompleteRegistration');
  }

  public addToWishlist(product: ProductModel): void {
    fbq('track', 'AddToWishlist', {
      value: product.discountedPrice || product.sellPrice || 0,
      currency: 'GEL',
      content_name: product.title,
      content_type: 'product',
      content_ids: [product.id]
    });
  }
}
