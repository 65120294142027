import { createAction, props } from '@ngrx/store';
import { CriteriaModel } from '../models/sidebar-filters.model';

export enum AuthActionsTypes {
  GetSidebarFilter = '[SIDEBAR] GetSidebarFilter',
  GetSidebarFilterSuccess = '[SIDEBAR] GetSidebarFilterSuccess',
  setSidebarFilterCriteria = '[SIDEBAR] SetSidebarFilterCriteria'
}

export const getSidebarFilter = createAction(AuthActionsTypes.GetSidebarFilter, props<{ data: any }>());
export const getSidebarFilterSuccess = createAction(AuthActionsTypes.GetSidebarFilterSuccess, props<{ filter: any }>());
export const setSidebarFilterCriteria = createAction(AuthActionsTypes.setSidebarFilterCriteria, props<CriteriaModel>());
