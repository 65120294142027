import { Routes } from '@angular/router';
import { AuthGuard } from '../core/guards';
import { STATIC_ROUTES } from './static/static-routes.module';

export const PAGE_ROUTES: Routes = [
  ...STATIC_ROUTES,
  {
    path: 'user',
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    loadChildren: () => import('./protected-routes/protechted.module').then(m => m.ProtechtedModule)
  },
  {
    path: 'checkout',
    loadChildren: () => import('./protected-routes/checkout/checkout.module').then(m => m.CheckoutModule)
  },
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'basket',
    loadChildren: () => import('./basket-page/basket-page.module').then(m => m.BasketPageModule)
  },
  {
    path: 'wishlist',
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    loadChildren: () => import('./wishlist/wishlist.module').then(m => m.WishlistModule)
  },
  {
    path: 'catalog/set',
    loadChildren: () => import('./set/set.module').then(m => m.SetModule)
  },
  {
    path: 'catalog',
    loadChildren: () => import('./catalog/catalog.module').then(m => m.CatalogModule)
  },
  {
    path: 'seller',
    loadChildren: () => import('./merchant/merchant.module').then(m => m.MerchantModule)
  },
  // {
  //   path: 'განცხადებები/ka/სულ-განცხადებები',
  //   loadChildren: () =>
  //     import('./search/search.module').then(
  //       m => m.SearchModule
  //     )
  // },
  {
    path: 'search',
    loadChildren: () => import('./search/search.module').then(m => m.SearchModule)
  },
  {
    path: 'product',
    loadChildren: () => import('./detailed/detailed.module').then(m => m.DetailedModule)
  },
  {
    path: 'discount',
    loadChildren: () => import('./promo-page/promo-page.module').then(m => m.PromoPageModule)
  },
  // {
  //   path: 'landing',
  //   loadChildren: () =>
  //     import('./landing/landing.module').then(
  //       m => m.LandingModule
  //     )
  // },
  // {
  //   path: 'school',
  //   loadChildren: () =>
  //     import('./school/school.module').then(
  //       m => m.SchoolModule
  //     )
  // },
  // {
  //   path: 'trade-in',
  //   loadChildren: () =>
  //     import('./trade-in/trade-in.module').then(
  //       m => m.TradeInModule
  //     )
  // },
  {
    path: 'sellers',
    loadChildren: () => import('./sellers/sellers.module').then(m => m.SellersModule)
  },
  {
    path: 'comparison',
    loadChildren: () =>
      import('./products-comparison/products-comparasion.module').then(m => m.ProductsComparisonModule)
  },
  {
    path: 'express',
    loadChildren: () => import('./express-page/express.module').then(m => m.ExpressModule)
  },
  {
    path: 'katalog',
    loadComponent: () => import('../pages/static/sitemap/sitemap.component').then(m => m.SitemapComponent)
  },
  {
    path: 'voucher',
    loadChildren: () => import('./voucher/voucher.module').then(m => m.VoucherModule)
  },

  {
    path: 'voucher-invoice/:id',
    loadChildren: () => import('./voucher-invoice/voucher-invoice.module').then(m => m.VoucherInvoiceModule)
  },

  {
    path: '**',
    redirectTo: '/'
  }
  // {
  //   path: '**',
  //   redirectTo: '/',
  // },

  // {
  //   path: 'notFound',
  //   loadChildren: () =>
  //     import('./not-found/not-found.module').then(
  //       m => m.NotFoundModule
  //     )
  // },
];
