import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID
} from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { MarketingHttp, Unsubscriber } from 'lib-core';
import { BehaviorSubject, interval } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { OverflowHandlerService } from '../../services/overflow-handler.service';
import {
  NotificationPopupByDate,
  NotificationPopupByType,
  PlatformType
} from '../../store/models/notification-popups.model';
import { NewInputModule } from '../../shared/components/HOC/forms/new-input/new-input.component';
import { TranslateModule } from '@ngx-translate/core';
import { ClickOutsideDirective } from '../../shared/directives/clickOutside.directive';
import { NotificationPopupComponent } from './notification/notification-popup.component';
import { SuccessPopupComponent } from './success/success-popup.component';

@Component({
  selector: 'app-notification-popup-main',
  templateUrl: './notification-popup-main.component.html',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NewInputModule,
    TranslateModule,
    ClickOutsideDirective,
    NotificationPopupComponent,
    SuccessPopupComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationPopupMainComponent extends Unsubscriber implements OnInit, OnDestroy {
  showSuccessPopup$ = new BehaviorSubject<boolean>(false);

  public popupTypesByDate = NotificationPopupByDate;
  public popupTypesByType = NotificationPopupByType;
  public popupsArray$ = new BehaviorSubject([]);
  public currentPopupObj$ = new BehaviorSubject({});
  public timeCounter = 1000;
  public popupsCount: number = 0;
  public showNotificationPopup$ = new BehaviorSubject(false);
  form: any;
  private successPageUrl = '/user/checkout/success';
  private currentUrl$ = new BehaviorSubject('');
  private isSuccessPage: boolean = false;
  private popupsInterval: any;
  private activeSessionDate: number;
  private platformType: number;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private marketingHttp: MarketingHttp,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    private overflowHandlerService: OverflowHandlerService
  ) {
    super();
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (window.innerWidth >= 768) {
        this.platformType = PlatformType.Desktop;
      } else {
        this.platformType = PlatformType.Mobile;
      }

      if (!sessionStorage.getItem('dateNow')) {
        // start session date
        sessionStorage.setItem('dateNow', `${Date.now()}`);
      }
    }

    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        filter(event => event instanceof NavigationEnd || event instanceof NavigationStart)
      )
      .subscribe(event => {
        if (event instanceof NavigationStart) {
          if (this.popupsInterval) {
            this.popupsInterval.unsubscribe(); // when router changes, interval must be unsubscribe to avoid multiple intervals
            this.timeCounter = 1000; // time counter have to start all over again
            if (isPlatformBrowser(this.platformId)) {
              this.activeSessionDate = isNaN(+sessionStorage.getItem('dateNow'))
                ? null
                : +sessionStorage.getItem('dateNow');
            }
          }
        }
        if (event instanceof NavigationEnd) {
          this.currentUrl$.next(this.router.url.split('?')[0]);
        }
      });
    if (isPlatformBrowser(this.platformId)) {
      this.activeSessionDate = isNaN(+sessionStorage.getItem('dateNow')) ? null : +sessionStorage.getItem('dateNow');
    }

    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });

    this.marketingHttp
      .getPopups(this.platformType)
      .pipe(
        map(res => res['data']),
        tap(res => {
          this.handlePopupsData(res);
        })
      )
      .subscribe(() => {});

    if (isPlatformBrowser(this.platformId)) {
      this.popupsArray$.pipe(filter(res => res.length !== 0)).subscribe(result => {
        let popupsInSeconds = [];
        let timeAfterRefresh = this.activeSessionDate ? Date.now() - this.activeSessionDate : 0; // how many seconds left from first session after user refresh
        for (let i = 0; i < result.length; i++) {
          const element = result[i];
          if (element.type === this.popupTypesByDate.OnSuccessPage) {
            element['seconds'] = 2; // popup on successpage doesn't have delay
            popupsInSeconds.push(element);
          } else {
            let seconds =
              element.type === this.popupTypesByDate.BySession
                ? this.millisecondsToSeconds(result[i].milliseconds - timeAfterRefresh)
                : this.millisecondsToSeconds(result[i].milliseconds); // convert remaining milliseconds to seconds for better calculations
            element['seconds'] = seconds;
            seconds >= 0 ? popupsInSeconds.push(element) : null;
          }
        }
        if (!this.isSuccessPage && isPlatformBrowser(this.platformId)) {
          let allPopups = parseInt(sessionStorage.getItem('popupsCount')); // get already showed popups count
          if (popupsInSeconds.length !== 0) {
            // if all popups are showed , dont start interval
            this.popupsInterval = interval(1000)
              .pipe(takeUntil(this.destroy$)) // start interval
              .subscribe(() => {
                let currentPopup = popupsInSeconds.find(
                  obj => obj.seconds == this.millisecondsToSeconds(this.timeCounter)
                ); // get popup which popup is next
                if (currentPopup) {
                  this.openNotificationPopup();
                  this.currentPopupObj$.next(currentPopup);
                  allPopups ? (this.popupsCount = allPopups) : this.popupsCount; //if page has been refreshed get correct popups count
                  this.popupsCount++;
                  sessionStorage.setItem('popupsCount', `${this.popupsCount}`);
                  this.cdr.markForCheck();
                }

                this.timeCounter += 1000;
                if (allPopups === popupsInSeconds.length || this.popupsCount === popupsInSeconds.length) {
                  // check if all popups are showed
                  this.popupsInterval.unsubscribe();
                }
              });
          }
        } else {
          let counter = 0;
          this.popupsInterval = interval(1000)
            .pipe(takeUntil(this.destroy$)) // start interval
            .subscribe(() => {
              let currentPopup = popupsInSeconds.find(
                obj => obj.seconds == this.millisecondsToSeconds(this.timeCounter)
              ); // get popup which popup is next
              if (currentPopup) {
                this.openNotificationPopup();
                this.currentPopupObj$.next(currentPopup);
                counter++;
                this.cdr.markForCheck();
              }
              this.timeCounter += 1000;
              if (counter === popupsInSeconds.length) {
                // check if all popups are showed
                this.popupsInterval.unsubscribe();
              }
            });
        }
      });
    }
  }

  millisecondsToSeconds(mill) {
    let seconds = Math.floor(mill / 1000);

    return seconds;
  }

  handlePopupsData(data) {
    this.currentUrl$
      // .pipe(
      //    filter(res => res !== '') // home ფეიჯზე არ მუშაობდა და ავაკომენტარე
      // )
      .subscribe(val => {
        if (val === this.successPageUrl) {
          this.isSuccessPage = true;
          this.popupsArray$.next(data.filter(res => res.type == this.popupTypesByDate.OnSuccessPage));
        } else {
          this.isSuccessPage = false;
          this.popupsArray$.next(
            data.filter(res => res.type !== this.popupTypesByDate.OnSuccessPage && res.milliseconds !== null)
          );
        }
      });
  }

  closeNotificationPopup() {
    this.showNotificationPopup$.next(false);
    this.toggleScroll(true);
  }

  openNotificationPopup(): void {
    this.showNotificationPopup$.next(true);
    this.toggleScroll(false);
  }

  showSuccessPopup(val) {
    this.showSuccessPopup$.next(val);
    this.showNotificationPopup$.next(false);
  }

  closeSuccessPopup(): void {
    this.showSuccessPopup$.next(false);
    this.toggleScroll(true);
  }

  private toggleScroll(show) {
    if (show) {
      this.overflowHandlerService.show();
    } else {
      this.overflowHandlerService.hide();
    }
  }
}
