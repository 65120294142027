import { Directive, ElementRef, Input, NgModule, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  selector: '[navigateToCorrectUrl]'
})
export class NavigateToCorrectUrlDirective implements OnInit {
  @Input() url: string;
  @Input() localUrlPrefix: string = '';

  constructor(
    private _elRef: ElementRef,
    private _renderer: Renderer2,
    private router: Router
  ) {}

  ngOnInit() {
    if (this.url && this.url.length) {
      if (this.url.indexOf('http') > -1) {
        this._renderer.setAttribute(this._elRef.nativeElement, 'href', this.url);
        this._renderer.setAttribute(this._elRef.nativeElement, 'target', '_blank');
      } else {
        this._renderer.setAttribute(this._elRef.nativeElement, 'href', this.localUrlPrefix + this.url);
      }
    }
  }
}

export const DIRECTIVES = [NavigateToCorrectUrlDirective];

@NgModule({
  declarations: DIRECTIVES,
  exports: DIRECTIVES
})
export class NavigateToCorrectUrlModule {}
