import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  QueryList,
  ViewChildren,
  ViewContainerRef
} from '@angular/core';
import { Unsubscriber } from 'lib-core';
import { filter, of, switchMap, take, takeUntil } from 'rxjs';
import { GlobalModalService } from '../../../services/global-modal-service';

@Component({
  selector: 'app-global-modal',
  templateUrl: './global-modal.component.html',
  styleUrls: ['./global-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
  standalone: true
})
export class GlobalModalComponent extends Unsubscriber implements OnInit, OnDestroy {
  @ViewChildren('modalContainer') modalContainer: QueryList<ElementRef>;
  modalWrapperClass: string = this.globalModalService.modalWrapperClass;

  constructor(
    public globalModalService: GlobalModalService,
    private viewContainerRef: ViewContainerRef,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    super();
  }

  ngOnInit(): void {
    this.inputsBindAction();
    this.globalModalService.disableInspect && this.disableInspect();
    this.disableScroll();
  }

  close(): void {
    if (!this.globalModalService.params.skipCallbackFnOnClose && this.globalModalService.params?.callBackFn) {
      this.globalModalService.params.callBackFn();
    }

    this.globalModalService.close();
  }

  disableInspect() {
    if (isPlatformBrowser(this.platformId)) {
      document.getElementById('global_modal_area').addEventListener('contextmenu', event => event.preventDefault());
    }
  }

  enableInspect() {
    document.getElementById('global_modal_area').removeEventListener('contextmenu', event => event.preventDefault());
  }

  disableScroll() {
    if (isPlatformBrowser(this.platformId)) {
      document.body.style.overflow = 'hidden';
    }
  }

  enableScroll() {
    if (isPlatformBrowser(this.platformId)) {
      document.body.style.overflow = 'auto';
    }
  }

  ngOnDestroy(): void {
    this.enableInspect();
    this.enableScroll();
  }

  private inputsBindAction() {
    this.globalModalService.component$
      .pipe(
        takeUntil(this.destroy$),
        switchMap(component =>
          this.globalModalService.inputs$.pipe(
            takeUntil(this.destroy$),
            take(1),
            switchMap(inputs => of([component, inputs]))
          )
        ),
        filter(([component, inputs]) => component && inputs)
      )
      .subscribe({
        next: ([component, inputs]) => {
          this.bindInputs(component, inputs);
        }
      });
  }

  private bindInputs(modalComponent: any, modalInputs = null) {
    const componentRef = this.viewContainerRef.createComponent(modalComponent);
    const modalComponentName: string = componentRef.location.nativeElement.localName;

    Object.keys(modalInputs).forEach(key => {
      if (componentRef.instance.hasOwnProperty(key)) {
        componentRef.instance[key] = modalInputs[key];
      } else {
        console.error(
          `%cproperty ${key} is not found in ${modalComponentName} component, or maybe the initialization is forgotten in ${modalComponentName} component`,
          'color: white; text-decoration: underline'
        );
      }
    });
    this.viewContainerRef.insert(componentRef.hostView);
  }
}
