import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { getCustomerOrderSuccess } from '../actions/orders.actions';
import { OrdersModel } from '../models/orders.model';

const orderReducer = createReducer(
  null,
  on(getCustomerOrderSuccess, (state, action) => ({ ...state, ...action.orders }))
);

export function reducer(state: OrdersModel, action: Action) {
  return orderReducer(state, action);
}

export const selectOrdersFeatureState = createFeatureSelector<OrdersModel>('orders');

export const ordersStatus = (state: OrdersModel) => state;

export const getUserOrdersSelect = createSelector(selectOrdersFeatureState, ordersStatus);

export const getUserOrders = createSelector(selectOrdersFeatureState, state => {
  return state;
});

export const getUserOrdersCount = createSelector(selectOrdersFeatureState, state => {
  if (!state || !state.data) {
    return;
  }
  return state.totalCount;
});
