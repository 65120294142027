import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map, retry } from 'rxjs/operators';

import { ApiService } from '../api.service';
import { Guid } from 'guid-typescript';

@Injectable({
  providedIn: 'root'
})
export class CustomersHttp {
  constructor(private api: ApiService) {}

  getExternalId(data): Observable<any> {
    return this.api.get('api/customers/getphysicalcustomerexternalidbyuserid', data, 'customersURL');
  }

  getReport(data): Observable<any> {
    return this.api.get('api/reports/customers', data, 'customersURL', 'blob');
  }

  getMerchantRepresentatives(data): Observable<any> {
    return this.api.get('api/customers/merchant-representatives', data, 'customersURL');
  }

  getCustomerProductView(data): Observable<any> {
    return this.api.get('api/customers/get-customer-product-views', data, 'customersURL');
  }

  isAdult(): Observable<any> {
    return this.api.get('api/customers/is-adult', {}, 'customersURL');
  }

  getCustomerPhysical(userId): Observable<any> {
    return this.api.get('api/customers/physical', { userId: userId }, 'customersURL');
  }

  postCustomerProductView(data): Observable<any> {
    return this.api.post('api/customers/customer-product-view', data, { requestId: this.getGuidId() }, 'customersURL');
  }

  changeShippingOptions(data): Observable<any> {
    return this.api.post('api/customers/changeshippingoptions', data, {}, 'customersURL');
  }

  getCustomers(pageNumber: number, pageSize: number, searchValue?): Observable<any> {
    return this.api.get(
      'api/customers/getphysicalcustomers',
      {
        page: pageNumber,
        pageSize: pageSize,
        searchValue: searchValue && searchValue
      },
      'customersURL'
    );
  }

  getLegalCustomersFix(pageNumber: number, pageSize: number, searchValue?): Observable<any> {
    return this.api.get(
      'api/customers/getlegalcustomers',
      {
        page: pageNumber,
        pageSize: pageSize,
        searchValue: searchValue && searchValue
      },
      'customersURL'
    );
  }

  getLegalCustomersFixById(userId): Observable<any> {
    return this.api.get(`api/customers/legal/${userId}`, { requestId: this.getGuidId() }, 'customersURL');
  }

  getSizeGuide(categoryId, customerId): Observable<any> {
    return this.api.get(
      `api/customers/sizeguide/${categoryId}/${customerId}`,
      { requestId: this.getGuidId() },
      'customersURL'
    );
  }

  getCustomer(): Observable<any> {
    return this.api
      .get('api/customers/physical', { requestId: this.getGuidId() }, 'customersURL')
      .pipe(map(res => res.data));
  }

  getMerchantAddresses(userIds, page = 1, size = 1000): Observable<any> {
    return this.api.post(
      'api/customers/getcustomeraddresses',
      {
        userIds,
        page: page,
        size: size
      },
      { requestId: this.getGuidId() },
      'customersURL'
    );
  }

  getPhysicalSendVerificationProneNumber(data): Observable<any> {
    return this.api.post(
      'api/customers/phonenumber/sendverification',
      data,
      { requestId: this.getGuidId() },
      'customersURL'
    );
  }

  addAddress(data): Observable<any> {
    return this.api.post('api/customers/addaddress', data, { requestId: this.getGuidId() }, 'customersURL');
  }

  updateCustomerAddress(data): Observable<any> {
    return this.api.put('api/customers/updateaddress', data, { requestId: this.getGuidId() }, 'customersURL');
  }

  postponeVerification(): Observable<any> {
    return this.api.put('api/customers/postponeverification', {}, { requestId: this.getGuidId() }, 'customersURL');
  }

  addManager(data): Observable<any> {
    return this.api.post('api/customers/addmanager', data, { requestId: this.getGuidId() }, 'customersURL');
  }

  changeDashboardStatus(data): Observable<any> {
    return this.api.post(
      'api/customers/change-dashboard-status',
      data,
      { requestId: this.getGuidId() },
      'customersURL'
    );
  }

  changeMerchantHasUserInfo(data): Observable<any> {
    return this.api.post(
      'api/customers/change-merchant-hasuserinformation',
      data,
      { requestId: this.getGuidId() },
      'customersURL'
    );
  }

  changeMainAddress(data): Observable<any> {
    return this.api.put('api/customers/change-basic-address', data, { requestId: this.getGuidId() }, 'customersURL');
  }

  updateAddress(data): Observable<any> {
    return this.api.put('api/customers/updateaddress', data, { requestId: this.getGuidId() }, 'customersURL');
  }

  removeAddress(addressId: string, customerId: string): Observable<any> {
    return this.api.delete(`api/customers/removeaddress/${addressId}/${customerId}`, {}, 'customersURL');
  }

  removeMerchantRepresentative(id: string): Observable<any> {
    return this.api.delete(`api/Admin/RemoveMerchantRepresentative`, {
      id: id
    });
  }

  removeManager(managerId: string, customerId: string): Observable<any> {
    return this.api.delete(`api/customers/removemanager/${managerId}/${customerId}`, {}, 'customersURL');
  }

  sendVerificationEmail(data): Observable<any> {
    return this.api.post('api/Profile/email/sendverification', data, {
      requestId: this.getGuidId()
    });
  }

  getCustomerSendVerificationDefaultPhoneNumber(data: { phoneNumber: string }): Observable<any> {
    return this.api.post('api/Profile/phone/sendverification', data, {
      requestId: this.getGuidId()
    });
  }

  addPhysicalCustomerPhone(data): Observable<any> {
    return this.api.post('api/customers/addphonenumber', data, { requestId: this.getGuidId() }, 'customersURL');
  }

  changeCustomerUserName(data): Observable<any> {
    return this.api.post('api/Profile/ChangeUserName', data, {
      requestId: this.getGuidId()
    });
  }

  updateCustomerPhoneNumber(data): Observable<any> {
    return this.api.post('api/Profile/ChangePhone', data, {
      requestId: this.getGuidId()
    });
  }

  updateCustomer(data): Observable<any> {
    return this.api.put('api/customers/updatephysical', data, { requestId: this.getGuidId() }, 'customersURL');
  }

  updatePhysicalCustomerAvatar(data): Observable<any> {
    return this.api.upload('api/customers/changeavatar', data, { requestId: this.getGuidId() }, 'customersURL');
  }

  updateUserPassword(data): Observable<any> {
    return this.api.post('api/Profile/ChangePassword', data, {
      requestId: this.getGuidId()
    });
  }

  removePhysicalCustomerPhone(id: string, userType?: string): Observable<any> {
    return this.api.delete(
      `api/customers/phonenumber/delete/${id}`,
      { userType, requestId: this.getGuidId() },
      'customersURL'
    );
  }

  addShippingAddress(data: ShippingAddressInteface): Observable<any> {
    return this.api.post('api/customers/add-shipping-address', data, { requestId: this.getGuidId() }, 'customersURL');
  }

  removeShippingAddress(id: number, customerId?: string, userType?: string): Observable<any> {
    return this.api.delete(
      `api/customers/remove-shipping-address/${id}`,
      { customerId: customerId, userType, requestId: this.getGuidId() },
      'customersURL'
    );
  }

  editShippingAddress(data: ShippingAddressInteface): Observable<any> {
    return this.api.put('api/customers/edit-shipping-address', data, { requestId: this.getGuidId() }, 'customersURL');
  }

  changeDefaultShippingAddress(data: { id: number; isDefault: boolean }): Observable<any> {
    return this.api.put(
      'api/customers/change-default-shipping-address',
      data,
      { requestId: this.getGuidId() },
      'customersURL'
    );
  }

  getShippingAddresses(customerId?: string, userType?: string): Observable<ShippingAddressInteface[]> {
    if (!localStorage.getItem('access_token')) {
      return of(null);
    }
    return this.api.get(
      'api/customers/get-shipping-address',
      { customerId: customerId, userType, requestId: this.getGuidId() },
      'customersURL'
    );
  }

  getMerchantContactDetails(merchantId: string): Observable<MerchantContactDetails> {
    return this.api.get(
      `api/customers/${merchantId}/merchant-contact-details`,
      { requestId: this.getGuidId() },
      'customersURL'
    );
  }
  getRepresentatives(pageNumber: number, pageSize: number, searchValue?: string): Observable<RepresentativesResponse> {
    return this.api.get<RepresentativesResponse>(
      'api/customers/representatives',
      {
        pageNumber,
        pageSize,
        searchValue
      },
      'customersURL'
    );
  }

  getMerchantsWithDashboard(
    pageNumber: number,
    pageSize: number,
    searchValue?: string
  ): Observable<MerchantsWithDashboard> {
    return this.api.get<MerchantsWithDashboard>(
      'api/customers/retrievemerchantswithdashboard',
      {
        pageNumber,
        pageSize,
        searchValue
      },
      'customersURL'
    );
  }

  updateRepresentative(representativeId: string, representedUserIds: RepresentedUserId[]): Observable<any> {
    return this.api.put<RepresentedUserId>(
      'api/customers/updaterepresentativerelationships',
      { representativeId, representedUserIds },
      {},
      'customersURL'
    );
  }

  createRepresentative(body: Omit<Representative, 'representedUsers' | 'userId'>): Observable<any> {
    return this.api.post<any>('api/Admin/CreateRepresentative', body);
  }

  addRepresentativeTomerchant(merchantId: string, representativeId: string): Observable<any> {
    return this.api.post<any>('api/customers/removerepresentativefrommerchant', { merchantId, representativeId });
  }

  deleteRepresentative(userId: string): Observable<any> {
    return this.api.delete<any>('api/Admin/RemoveRepresentative', { userId });
  }

  deleteReperesentativeFromMerchant(merchantId: string, representativeId: string): Observable<any> {
    return this.api.delete<any>(
      'api/customers/removerepresentativefrommerchant',
      { merchantId, representativeId },
      'customersURL'
    );
  }

  checkIfMerchantExists(merchantContact: string) {
    return this.api.get(`api/customers/merchant/exists/${merchantContact}`, {}, 'customersURL');
  }

  createLegalCustomer(data: LegalUserData): Observable<any> {
    return this.api.post('api/customers/legal/create', data, { requestId: this.getGuidId() }, 'customersURL');
  }

  getMerchantTimeSlotDetails(merchantUserId: string, categoryId: number): Observable<MerchantCurrentTimeSlots[]> {
    return this.api.get(`api/merchant/timeslot-details`, { merchantUserId, categoryId }, 'customersURL');
  }

  addMerchantTimeSlot(data): Observable<any> {
    return this.api.post('api/merchant/timeslot', data, { requestId: this.getGuidId() }, 'customersURL');
  }

  updateCutOffTime(body: { cutOffTime: number; merchantId: string }): Observable<any> {
    return this.api.put('api/merchant/cutofftime', body, { requestId: this.getGuidId() }, 'customersURL');
  }

  updateIsOptedForAds(isOptedForAds: boolean): Observable<void> {
    return this.api.put<void>('api/customers/is-opted-for-ads', { isOptedForAds }, {}, 'customersURL');
  }

  private getGuidId(): string {
    return Guid.create().toString();
  }
}

interface ShippingAddressInteface {
  id?: number;
  locationId: number;
  location: string;
  address: string;
  addressAdditionalInfo?: string;
  longitude: number;
  latitude: number;
  isDefault?: boolean;
  customerId?: string;
}

interface MerchantContactDetails {
  merchantId: 0;
  merchantSlug: string;
  commercialTitle: string;
  juridicalTitle: string;
  identityNumber: string;
  agreementType: number;
  address: string;
  contactPhoneNumber: string;
}
export interface RepresentativesResponse {
  representatives: {
    currentPage: number;
    firstRowOnPage: number;
    lastRowOnPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
    results: Representative[];
  };
}

export interface Representative {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  userId: string;
  representedUsers: RepresentedUser[];
}

export interface RepresentedUser {
  representedUserId: string;
  representedUserTitle: string;
  representedUserIdentityNumber: string;
  hasDashboard: boolean;
}

export interface RepresentedUserId {
  representedUserId: string;
  userType: number;
}

export interface MerchantsWithDashboard {
  merchants: {
    results: MerchantWithDashboard[];
    currentPage: number;
    firstRowOnPage: number;
    lastRowOnPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  };
}

export interface MerchantWithDashboard {
  userId: string;
  commercialTitle: string;
  identityNumber: string;
  titleAndIdentity?: string;
  hasDashboard: boolean;
}

interface Address {
  addressId: number;
  city: any; // You might want to define a separate interface for city if it has properties
  street: string;
  phoneNumber: string;
  contactPerson: string;
  workSchedule: {
    fromHours: string;
    toHours: string;
    weekDay: number;
  }[];
  isBasicAddress: boolean;
  emails: string[];
}

interface LegalUserData {
  email: string;
  phone: string;
  emailConfirmed: boolean;
  phoneConfirmed: boolean;
  contactPhoneNumber: string;
  avatarGuid: string;
  coverPhoto: string;
  identityNumber: string;
  juridicalTitle: string;
  commercialTitle: string;
  agreementGuid: string;
  externalId: string;
  address: Address;
  agreementType: number;
  guaranteeFileName: string;
  commercialTitleEng: string;
  commercialTitleKa: string;
  deliveryTime: number;
}

interface MerchantCurrentTimeSlots {
  slotType: number;
  merchantTimeSlotOptions: MerchantTimeSlotOptions[];
}

interface MerchantTimeSlotOptions {
  categoryId: number;
  timeSlotOptionsId: number;
  slotType: number;
}
