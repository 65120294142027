import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { getUser, getUserSuccess, removeUser } from '../actions/user.actions';
import { UserModel } from '../models/user.model';

const userReducer = createReducer(
  null,
  on(getUser, state => ({ ...state, loading: true })),
  on(getUserSuccess, (state, action) => ({ ...state, ...action, loading: false })),
  on(removeUser, () => ({}))
);

export function reducer(state: UserModel, action: Action) {
  return userReducer(state, action);
}

export const selectUserFeatureState = createFeatureSelector<UserModel>('user');
export const userStatus = (state: UserModel) => state;
export const getUserData = createSelector(selectUserFeatureState, userStatus);

export const userLoadingState = createSelector(selectUserFeatureState, (state: any) => state?.loading);
export const userVerifiedStatus = createSelector(selectUserFeatureState, (state: any) => state?.isVerified);
