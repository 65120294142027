import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { sidebarOpen } from '../../store/actions/layout.actions';
import * as fromLayout from '../../store/models/layout.model';
import { getSidebar } from '../../store/reducers/layout.reducer';
import { SignInComponent } from './sign/sign-in';
import { DynamicHostDirective } from '../../shared/directives/dynamic-host.directive';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarComponent implements OnInit {
  active: boolean;
  type: string;
  isRegistrationSuccessfull: boolean = false;
  layoutStatus$: Observable<fromLayout.SidebarModel> = this.store.pipe(select(getSidebar));
  @ViewChild(DynamicHostDirective, { static: true }) dynamicHost!: DynamicHostDirective;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<fromLayout.SidebarModel>,
    @Inject(PLATFORM_ID) private platformId: Object,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    let url = this.router.url;
    if (url.split(':')[1]) {
      url = url.split(':')[1];
      url = url.slice(0, url.length - 1);
      this.type = url;
    }
    this.subscribeSideBarStatus();
  }

  subscribeSideBarStatus(): void {
    this.layoutStatus$.subscribe(state => {
      if (state.burgerStatus) {
        switch (state.activeComponent) {
          case 'sign-in':
            this.loadSignInComponentDynamically();
            break;
          case 'sign-up':
            // TODO: its not standalone yet
            this.loadSignUpComponentDynamically();
            break;
          case 'recover-password':
            this.loadPasswordRecoveryDynamically();
            break;
          default:
            break;
        }
      }
    });
  }

  private loadSignInComponentDynamically(): void {
    const viewContainerRef = this.getViewContainerRef();
    import('./sign/sign-in/sign-in.component').then(c => {
      viewContainerRef.createComponent<SignInComponent>(c.SignInComponent);
      this.changeDetectorRef.detectChanges();
    });
  }
  private loadSignUpComponentDynamically(): void {
    const viewContainerRef = this.getViewContainerRef();
    import('./sign/sign-up/sign-up-page.component').then(c => {
      viewContainerRef.createComponent<any>(c.SignUpPageComponent);
      this.changeDetectorRef.detectChanges();
    });
  }

  private loadPasswordRecoveryDynamically(): void {
    const viewContainerRef = this.getViewContainerRef();
    import('./sign/recover-password/recover-password-page.component').then(c => {
      viewContainerRef.createComponent<any>(c.RecoverPasswordPageComponent);
      this.changeDetectorRef.detectChanges();
    });
  }

  private getViewContainerRef(): ViewContainerRef {
    const viewContainerRef = this.dynamicHost.viewContainerRef;
    viewContainerRef.clear();
    return viewContainerRef;
  }

  onClose(): void {
    this.store.dispatch(sidebarOpen({ burgerStatus: false }));
  }
}
