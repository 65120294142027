import { BanksEnum, PaymentType } from './orders.model';

export interface IBuyModel {
  merchant?: any;
  paymentType?: PaymentType;
  instalmentType?: BanksEnum;
  activeTab?: IBuyTabs;
  status?: boolean;
}

export enum IBuyTabs {
  merchant,
  paymentType,
  instalmentType
}
