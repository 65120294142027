import { makeStateKey } from '@angular/core';
import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { CampaignsModel } from 'projects/cms/src/app/routes/shell/campaigns/campaigns.model';
import { AddressesStoreModel } from './models/addresses.model';
import { AuthModel } from './models/auth.model';
import { BannerModel } from './models/banner.model';
import { BasketModel } from './models/basket.model';
import { CategoryStoreModel } from './models/category.model';
import { SidebarModel } from './models/layout.model';
import { OrdersModel } from './models/orders.model';
import { ProductModel } from './models/product.model';
import { RibbonModel } from './models/ribbon.model';
import { RouteModel } from './models/route.model';
import { SearchByImageModel } from './models/search-by-image.model';
import { SectionModel } from './models/section.model';
import { UserModel } from './models/user.model';
import { WishlistModel } from './models/wishlist.model';
import { addressReducer } from './reducers/addresses.reducer';
import * as fromAuth from './reducers/auth.reducer';
import * as fromBanners from './reducers/banner.reducer';
import * as fromBasket from './reducers/basket.reducer';
import * as fromCampaign from './reducers/campaign.reducer';
import * as fromCampaigns from './reducers/campaigns.reducer';
import * as fromCards from './reducers/cards.reducer';
import * as fromCategory from './reducers/category.reducer';
import * as fromComparison from './reducers/comparison.reducer';
import * as fromGuard from './reducers/guard.reducer';
import * as fromIBoy from './reducers/instant-buy.reducer';
import * as fromLayout from './reducers/layout.reducer';
import * as MerchantCategories from './reducers/merchant.reducer';
import * as fromNavigation from './reducers/navigation.reducer';
import * as fromOrders from './reducers/orders.reducer';
import * as fromProduct from './reducers/product.reducer';
import * as RibbonReducer from './reducers/ribbon.reducer';
import * as SearchByImageReducer from './reducers/search-by-image.reducer';
import * as fromSections from './reducers/section.reducer';
import * as fromSidebarFilter from './reducers/sidebar-filters.reducer';
import * as fromUser from './reducers/user.reducer';
import * as fromWishlist from './reducers/wishlist.reducer';

export const REDUCERS: ActionReducerMap<any> = {
  layout: fromLayout.reducer,
  navigation: fromNavigation.reducer,
  user: fromUser.reducer,
  auth: fromAuth.reducer,
  product: fromProduct.reducer,
  category: fromCategory.reducer,
  basket: fromBasket.reducer,
  orders: fromOrders.reducer,
  sidebarFilter: fromSidebarFilter.reducer,
  guard: fromGuard.reducer,
  iBuy: fromIBoy.reducer,
  // tradeIn: fromTradeIn.reducer,
  banners: fromBanners.reducer,
  sections: fromSections.reducer,
  cards: fromCards.reducer,
  campaign: fromCampaign.reducer,
  merchant: MerchantCategories.reducer,
  searchByImage: SearchByImageReducer.reducer,
  ribbon: RibbonReducer.reducer,
  wishlist: fromWishlist.reducer,
  campaigns: fromCampaigns.reducer,
  comparison: fromComparison.reducer,
  addresses: addressReducer
};

export interface State {
  layout: SidebarModel;
  navigation: RouteModel;
  user: UserModel;
  auth: AuthModel;
  product: ProductModel;
  category: CategoryStoreModel;
  basket: BasketModel;
  orders: OrdersModel;
  // tradeIn: TradeInModel,
  banner: BannerModel;
  section: SectionModel;
  merchantCategories: any;
  seachByImage: SearchByImageModel;
  ribbon: RibbonModel;
  wishlist: WishlistModel;
  campaigns: CampaignsModel;
  addresses: AddressesStoreModel;
}

export const NGRX_STATE = makeStateKey('NGRX_STATE');

export function stateSetter(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state: any, action: any) {
    if (action.type === 'SET_ROOT_STATE') {
      return action.payload;
    }
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<State>[] = [stateSetter];
