import { Injectable } from '@angular/core';

@Injectable()
export class GoogleMapOptionsService {
  constructor() {}

  public makeOnlyStreenVisible() {
    return [
      {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }]
      },

      {
        featureType: 'poi.business',
        stylers: [{ visibility: 'off' }]
      },

      {
        featureType: 'road',
        elementType: 'labels',
        stylers: [{ visibility: 'on' }]
      },

      {
        featureType: 'transit.station.rail',
        stylers: [{ visibility: 'off' }]
      },

      {
        featureType: 'transit.station.bus',
        stylers: [{ visibility: 'off' }]
      },

      {
        featureType: 'transit.station.airport',
        stylers: [{ visibility: 'off' }]
      }
    ];
  }

  public getLatLngBounds() {
    // რუკის ტერიტორიის შეზღუდვა საქართველო + სომხეთი
    const georgiaBounds = new google.maps.LatLngBounds(
      new google.maps.LatLng(41.055553, 40.010661),
      new google.maps.LatLng(43.58659, 46.736717)
    );

    const armeniaBounds = new google.maps.LatLngBounds(
      new google.maps.LatLng(38.840179, 43.447243),
      new google.maps.LatLng(41.300888, 46.634184)
    );

    // ორივე ქვეყნისთვის
    const combinedBounds = new google.maps.LatLngBounds();
    combinedBounds.union(georgiaBounds);
    combinedBounds.union(armeniaBounds);

    return combinedBounds;
  }

  public isCorrectPin(address: string, citys?: string[]): boolean {
    const lowAddress = address.toLowerCase();
    for (const city of citys) {
      if (lowAddress.includes(city.toLowerCase())) {
        return true;
      }
    }
    return false;
    // if (
    //   !lowAddress.includes('georgia') &&
    //   !lowAddress.includes('yerevan') &&
    //   !lowAddress.includes('საქართველო') &&
    //   !lowAddress.includes('ერევანი')
    // ) {
    //   return false;
    // }
    // return true;
  }
}
