<div
  class="_x_fixed _x_bottom-0 _x_left-0 _x_right-0 _x_top-0 _x_z-10 _x_flex _x_h-full _x_max-h-full _x_w-full _x_items-center _x_justify-center _x_overflow-x-hidden _x_overflow-y-hidden _x_p-4">
  <div
    (click)="orderDebtService.closeOrderDebtModal()"
    class="_x_fixed _x_left-0 _x_right-0 _x_top-0 _x_z-10 _x_h-full _x_w-full _x_bg-dark-600"></div>
  <div
    class="_x_absolute _x_bottom-auto _x_z-10 _x_mx-8 _x_h-20 _x_h-auto _x_max-h-full _x_w-full _x_max-w-171 _x_translate-y-0 _x_transform _x_overflow-hidden _x_rounded-12 _x_bg-white sm:_x_max-w-328">
    <div class="_x_relative _x_rounded-t-12 _x_bg-white sm:_x_w-full">
      <div class="_x_flex _x_items-center _x_justify-between _x_border-b _x_border-gray _x_p-8">
        <div class="_x_flex _x_items-center">
          <p class="_x_font-bold _x_text-5">ცვლილება შეკვეთაში</p>
        </div>
        <button
          (click)="orderDebtService.closeOrderDebtModal()"
          class="_x_icon _x_icon-remove _x_flex _x_h-12 _x_max-h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_cursor-pointer _x_items-center _x_justify-center _x_text-7 _x_text-dark-700"></button>
      </div>
      <div class="_x_px-8">
        <div class="_x_text-balck _x_my-8 _x_rounded-8 _x_p-7 _x_text-3 _x_shadow-priceChangeCardOnOrders sm:_x_my-4">
          <div class="_x_flex _x_flex-col _x_gap-8 md:_x_flex-row md:_x_items-center">
            <div>
              <p class="_x_mb-4 _x_font-bold">ფასის ცვლილება</p>
              <p class="_x_font-medium">
                შეკვეთაზე პროდუქტის დეტალების ცვლილების შედეგად
                {{
                  orderWithDebt.userOrderFinancial?.orderFinancialType === expressOrderType.Dept
                    ? 'უნდა დაამატო'
                    : 'დაგიბრუნდება'
                }}
                <span
                  [ngClass]="{
                    '_x_text-red': orderWithDebt.userOrderFinancial?.orderFinancialType === expressOrderType.Dept,
                    '_x_text-green': orderWithDebt.userOrderFinancial?.orderFinancialType === expressOrderType.Refund
                  }"
                  class="_x_whitespace-nowrap _x_font-bold"
                  >{{ orderWithDebt.userOrderFinancial?.amount }} ₾</span
                >
                <!-- <span class="_x_text-dark-600">(1-დან 3 სამუშაო დღეში)</span></p> -->
              </p>
            </div>
            <button
              (click)="orderDebtService.openPaymentModal(orderWithDebt)"
              class="_x_h-24 _x_rounded-8 _x_bg-purple _x_px-20 _x_font-bold _x_text-white">
              გადახდა
            </button>
          </div>
        </div>
      </div>

      <div class="_x_px-8 _x_font-medium _x_text-3">
        <span class="_x_mr-4 _x_text-dark-700">შეკვეთის ნომერი:</span>
        <span class="_x_text-balck">#{{ orderWithDebt.externalId }}</span>
      </div>

      <div
        class="sm:_x_h-203 category-custom-scroll-bar custom-scroll-bar _x_ml-8 _x_mr-5 _x_max-h-160 _x_overflow-y-auto _x_pr-3">
        <ng-container *ngFor="let groupedLine of orderWithDebt.orderLines">
          <ng-container *ngFor="let product of groupedLine.lines">
            <div
              *ngIf="product.priceDifference"
              class="_x_min-h-30 _x_grid _x_w-full _x_grid-cols-12 _x_flex-wrap _x_items-center _x_border-b-1 _x_border-dark-100 _x_py-8 _x_pl-10 _x_pr-10 last:_x_border-b-0">
              <div class="_x_col-span-4 _x_flex _x_items-center sm:_x_col-span-6">
                <a
                  [routerLink]="['/product', product.productOriginalSlug, product.productId]"
                  *ngIf="product.productAssetImage && product.productAssetImage.small"
                  class="_x_flex _x_h-30 _x_w-30 _x_items-center _x_justify-center sm:_x_h-40 sm:_x_w-40">
                  <img [src]="product.productAssetImage.small" class="_x_max-w-full _x_max-h-full" />
                </a>
                <div class="sm:_x_ml-5">
                  <a
                    [routerLink]="['/product', product.productOriginalSlug, product.productId]"
                    class="_x_no-underline _x_outline-none">
                    <div class="_x_mb-3 _x_hidden _x_font-semibold _x_text-2 _x_text-black sm:_x_block">
                      {{ product.productTitle }}
                    </div>
                    <div class="_x_hidden _x_font-medium _x_text-2 sm:_x_block">
                      <span class="_x_text-dark-700">მოიტანე </span>
                    </div>
                  </a>
                  <div class="_x_flex">
                    <div class="_x_mr-7" *ngIf="product.size">
                      <h4 class="_x_mb-0 _x_font-bold _x_text-3 _x_text-dark-800">ზომა</h4>
                      <div class="_x_mt-1">
                        {{ product.size }}
                      </div>
                    </div>
                    <div *ngIf="product.color">
                      <h4 class="_x_mb-0 _x_font-bold _x_text-3 _x_text-black">ფერი</h4>
                      <span
                        class="_s_icon _s_icon-md _s_b-solid _s_bw-1 _s_b-rgba-black-0--3 _x_mt-5"
                        [style.background]="product.color">
                        <span
                          class="_s_icon _s_icon-sm _s_color-white _s_icon-ui_done _s_opacity-0 _s_aitem-opacity-1"></span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="_x_col-span-8 _x_flex _x_flex-col _x_items-start _x_justify-end sm:_x_col-span-6 sm:_x_items-end">
                <div class="_x_mb-6 _x_font-semibold _x_text-2 _x_text-black sm:_x_mb-2 sm:_x_hidden">
                  {{ product.productTitle }}
                </div>
                <div class="_x_flex _x_w-full _x_items-center _x_justify-center _x_justify-between">
                  <div class="_x_mr-17 sm:_x_mr-26 sm:_x_p-0 sm:_x_pb-7 sm:_x_pt-7">
                    <div class="_x_flex _x_flex-col _x_items-center">
                      <div class="_x_mb-3 _x_flex">
                        <span class="_x_font-medium _x_text-3 _x_text-dark-800">
                          <span class="_x_whitespace-nowrap sm:_x_hidden">რ-ბა</span>
                          <span class="_x_hidden sm:_x_block">რაოდენობა</span>
                        </span>
                      </div>
                      <div class="s">
                        <span
                          class="_x_inline-flex _x_h-10 _x_min-w-15 _x_items-center _x_justify-center _x_rounded-8 _x_font-bold _x_text-4 _x_text-dark">
                          {{ product.productCount }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="_x_flex _x_flex-col _x_items-start sm:_x_items-end">
                    <div class="_x_flex _x_flex-col _x_items-end">
                      <div class="_x_mb-3 _x_flex">
                        <span class="_x_font-medium _x_text-3 _x_text-dark-800">ღირებულება</span>
                      </div>
                      <div class="_x_flex _x_gap-3">
                        <span
                          [ngClass]="{
                            '_x_text-red': product.priceDifference > 0,
                            '_x_text-green': product.priceDifference < 0
                          }"
                          class="mb-0 _x_flex _x_items-center _x_font-medium _x_text-4">
                          <span *ngIf="product.priceDifference > 0">+</span>
                          {{ product.priceDifference }}
                          <i
                            [ngClass]="{
                              '_x_text-red': product.priceDifference > 0,
                              '_x_text-green': product.priceDifference < 0
                            }"
                            class="_x_icon _x_icon-gel icon-black mt-n2px _x_items-center _x_p-1 _x_text-4 sm:_x_text-6">
                            <!-- <svg xmlns="http://www.w3.org/2000/svg" width="10" height="12" viewBox="0 0 10 12">
                                                                                                      <path class="a"
                                                                                                        d="M289.308,336.06l1.248-1.2v1.889c.115-.009.232-.012.351-.012s.212,0,.318.012v-.689l1.248-1.2v2.162a5.3,5.3,0,0,1,.657.3,4.772,4.772,0,0,1,.933.677,5.209,5.209,0,0,1,.764.885,5.364,5.364,0,0,1,.561,1.058,5.538,5.538,0,0,1,.322,1.193L294.48,342.4a5.117,5.117,0,0,0-.493-2.207,4.344,4.344,0,0,0-.564-.885,3.829,3.829,0,0,0-.733-.687c-.067-.048-.14-.1-.216-.139v2.633l-1.248,1.345v-4.415a2.987,2.987,0,0,0-.351-.02,2.52,2.52,0,0,0-.318.02v3.07l-1.248,1.345v-4.051a3.711,3.711,0,0,0-.974.73,3.83,3.83,0,0,0-1.058,2.665,3.651,3.651,0,0,0,.137,1,3.85,3.85,0,0,0,.385.9,3.741,3.741,0,0,0,.6.765,3.934,3.934,0,0,0,.777.594,4.019,4.019,0,0,0,.911.387,3.931,3.931,0,0,0,.758.127h4.477L294.1,346.86h-8.388l1.223-1.273h.822c-.072-.062-.142-.127-.21-.192a5,5,0,0,1-.8-1.015,4.942,4.942,0,0,1-.695-2.546,5.326,5.326,0,0,1,.172-1.355,5.129,5.129,0,0,1,1.251-2.25,4.938,4.938,0,0,1,.978-.8,4.718,4.718,0,0,1,.853-.411Z"
                                                                                                        transform="translate(-285.709 -334.86)" />
                                                                                                    </svg> -->
                          </i>
                        </span>
                        <span class="mb-0 _x_flex _x_items-center _x_font-bold _x_text-4 _x_text-dark">
                          {{ product.price }}
                          <i
                            class="_x_icon _x_icon-gel icon-black mt-n2px _x_items-center _x_p-1 _x_text-4 _x_text-dark sm:_x_text-6">
                            <!-- <svg xmlns="http://www.w3.org/2000/svg" width="10" height="12" viewBox="0 0 10 12">
                                                                      <path class="a"
                                                                        d="M289.308,336.06l1.248-1.2v1.889c.115-.009.232-.012.351-.012s.212,0,.318.012v-.689l1.248-1.2v2.162a5.3,5.3,0,0,1,.657.3,4.772,4.772,0,0,1,.933.677,5.209,5.209,0,0,1,.764.885,5.364,5.364,0,0,1,.561,1.058,5.538,5.538,0,0,1,.322,1.193L294.48,342.4a5.117,5.117,0,0,0-.493-2.207,4.344,4.344,0,0,0-.564-.885,3.829,3.829,0,0,0-.733-.687c-.067-.048-.14-.1-.216-.139v2.633l-1.248,1.345v-4.415a2.987,2.987,0,0,0-.351-.02,2.52,2.52,0,0,0-.318.02v3.07l-1.248,1.345v-4.051a3.711,3.711,0,0,0-.974.73,3.83,3.83,0,0,0-1.058,2.665,3.651,3.651,0,0,0,.137,1,3.85,3.85,0,0,0,.385.9,3.741,3.741,0,0,0,.6.765,3.934,3.934,0,0,0,.777.594,4.019,4.019,0,0,0,.911.387,3.931,3.931,0,0,0,.758.127h4.477L294.1,346.86h-8.388l1.223-1.273h.822c-.072-.062-.142-.127-.21-.192a5,5,0,0,1-.8-1.015,4.942,4.942,0,0,1-.695-2.546,5.326,5.326,0,0,1,.172-1.355,5.129,5.129,0,0,1,1.251-2.25,4.938,4.938,0,0,1,.978-.8,4.718,4.718,0,0,1,.853-.411Z"
                                                                        transform="translate(-285.709 -334.86)" />
                                                                    </svg> -->
                          </i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>
