export namespace SmartbannerFn {
  export function isFromSafari(): boolean {
    return (
      /Safari/i.test(navigator.userAgent) &&
      /Apple/i.test(navigator.vendor) &&
      !/Chrome|CriOS/i.test(navigator.userAgent)
    );
  }

  export function getUserAgent() {
    return navigator.userAgent || navigator.vendor || window['opera'];
  }

  export function isValidDevice(userAgent: any): boolean {
    return /android/i.test(userAgent) || (/iPad|iPhone|iPod/.test(userAgent) && !window['MSStream']);
  }
}
