export enum CardType {
  Visa = 1,
  MasterCard = 2,
  Amex = 3
}

export enum CampaignValueType {
  Default,
  Amount,
  Percent
}

export interface CardModel {
  id: number;
  accountId: string;
  cardExternalId: string;
  name: string;
  type: CardType;
  dateOfCreate: string;
  logoUrl: string;
  isPrimary: boolean;
}

export interface CheckoutCardResponse {
  id: number;
  name: string;
  value: number;
  campaignValueType: CampaignValueType;
  cardType: CardType;
}

export enum BankTypeForCard {
  BOG = 0,
  TBC = 1
}
