import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { UseVoucherBody } from '../../models/vouchers.model';

@Injectable({
  providedIn: 'root'
})
export class PaymentHttp {
  constructor(private api: ApiService) {}

  getBalanceTransaction(data): Observable<any> {
    return this.api.get(`v1/extra/balance-transactions`, data, 'paymentURL');
  }

  getAccountBalanceHistories(data): Observable<any> {
    return this.api.get(`v1/extra/account-balance-histories`, data, 'paymentURL');
  }

  getOrderPaymentDetails(data): Observable<any> {
    return this.api.get(`v1/extra/order-payment-details`, data, 'paymentURL');
  }

  getCardType(id): Observable<any> {
    return this.api.get(`api/cards/${id}/get-card-type`, {}, 'paymentURL');
  }

  transactionCheck(data): Observable<any> {
    return this.api.get(`v1/payment/status`, data, 'paymentURL');
  }

  transactionCheckWithOrderId(orderId: string | number): Observable<any> {
    return this.api.get(`v1/payment/status/order`, { orderId }, 'paymentURL');
  }

  getUserCards(bankType?: number): Observable<any> {
    return this.api.get(
      `api/cards`,
      { bankType: typeof bankType === 'number' ? bankType.toString() : null },
      'paymentURL'
    );
  }

  deleteCard(id: number): Observable<any> {
    return this.api.delete(`api/cards/${id}`, {}, 'paymentURL');
  }

  addNewCard(data: { referrerType: string | number; bankType?: number }): Observable<any> {
    return this.api.post(`api/cards/add-card`, data, {}, 'paymentURL');
  }

  fetchCardPaymentOptions(type?: number): Observable<any> {
    return this.api.get(`api/cards/get-by-card-type`, { type: type || undefined }, 'paymentURL');
  }

  getIPayUrl(data): Observable<any> {
    return this.api.post(`v1/payment/order`, data, { requestId: this.getGuidId() }, 'paymentURL');
  }

  getInstalmentUrl(data): Observable<any> {
    return this.api.post(`api/installments`, data, { requestId: this.getGuidId() }, 'paymentURL');
  }

  getIpayInstallmentCheck(data): Observable<any> {
    return this.api.get(`api/installments/ipay/check-status`, data, 'paymentURL');
  }

  getBalanceUrl(data): Observable<any> {
    return this.api.post(`v1/payment/balance-refill`, data, { requestId: this.getGuidId() }, 'paymentURL');
  }

  getAddTopUrl(data): Observable<any> {
    return this.api.post(`v1/payment/order/premium-service`, data, { requestId: this.getGuidId() }, 'paymentURL');
  }

  addBalanceFromExtra(data): Observable<any> {
    return this.api.post(`v1/extra/add-balance-from-extra`, data, { requestId: this.getGuidId() }, 'paymentURL');
  }

  removeBalanceFromExtra(data): Observable<any> {
    return this.api.post(`v1/extra/remove-balance-from-extra`, data, { requestId: this.getGuidId() }, 'paymentURL');
  }

  payForNewStatement(data): Observable<any> {
    return this.api.post(`v1/payment/order/pay-new-statement`, data, { requestId: this.getGuidId() }, 'paymentURL');
  }

  setMainCard(data): Observable<any> {
    return this.api.put('api/cards/change-basic-card', data, { requestId: this.getGuidId() }, 'paymentURL');
  }

  expressOrderDebtPayment(data): Observable<any> {
    return this.api.post(`v1/payment/order/dept`, data, { requestId: this.getGuidId() }, 'paymentURL');
  }

  getPartByPArtTbcStatus(orderId: string): Observable<any> {
    return this.api.get(`api/installments/tbc/check-status`, { orderId: orderId }, 'paymentURL');
  }

  useVoucher(body: UseVoucherBody): Observable<any> {
    return this.api.post('v1/extra/use-voucher', body, { requestId: this.getGuidId() }, 'paymentURL');
  }

  orderRefundDetails(data): Observable<any> {
    return this.api.get(`v1/extra/order-refund-details`, data, 'paymentURL');
  }

  private getGuidId(): string {
    return Guid.create().toString();
  }
}
