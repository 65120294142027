import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class OverflowHandlerService {
  private renderer2: Renderer2;

  constructor(
    rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.renderer2 = rendererFactory.createRenderer(null, null);
  }

  public hide(): void {
    this.renderer2.addClass(this.document.body, '_x_overflow-hidden');
    this.renderer2.addClass(document.body, '_x_top-0');
    this.renderer2.addClass(document.body, '_x_left-0');
    this.renderer2.addClass(document.body, '_x_right-0');
    this.renderer2.addClass(document.body, '_x_bottom-0');
  }

  public show(): void {
    this.renderer2.removeClass(this.document.body, '_x_overflow-hidden');
    this.renderer2.removeClass(document.body, '_x_top-0');
    this.renderer2.removeClass(document.body, '_x_left-0');
    this.renderer2.removeClass(document.body, '_x_right-0');
    this.renderer2.removeClass(document.body, '_x_bottom-0');
  }
}
