import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import {
  searchToggle,
  searchToggleClose,
  setIsAdult,
  setLoad,
  setMobileFilterState,
  setPreviousUrl,
  sidebarClose,
  sidebarOpen
} from '../actions/layout.actions';
import { SidebarModel } from '../models/layout.model';

export const initialState: SidebarModel = {
  burgerStatus: false,
  activeComponent: null,
  searchToggle: false,
  previousUrl: '/',
  queryParams: {},
  load: false,
  isAdult: null,
  mobileFilter: false
};

const sidebarReducer = createReducer(
  initialState,
  on(sidebarOpen, (state, action) => ({ ...state, ...action })),
  on(sidebarClose, state => ({ ...state, ...initialState })),
  on(searchToggle, state => ({ ...state, searchToggle: !state.searchToggle })),
  on(searchToggleClose, state => ({ ...state, searchToggle: false })),
  on(setPreviousUrl, (state, action) => ({ ...state, ...action })),
  on(setLoad, state => ({ ...state, load: true })),
  on(setIsAdult, (state, action) => ({ ...state, isAdult: action.isAdult })),
  on(setMobileFilterState, (state, action) => ({ ...state, mobileFilter: action.open }))
);

export function reducer(state: SidebarModel, action: Action) {
  return sidebarReducer(state, action);
}

export const selectSidebarFeatureState = createFeatureSelector<SidebarModel>('layout');

export const sidebarStatus = (state: SidebarModel) => state;

export const getSidebar = createSelector(selectSidebarFeatureState, sidebarStatus);

export const getSearchBarStatus = createSelector(selectSidebarFeatureState, state => {
  return state.searchToggle;
});

export const getPreviousUrl = createSelector(selectSidebarFeatureState, state => {
  return { previousUrl: state.previousUrl, queryParams: state.queryParams };
});

export const getLoadStatus = createSelector(selectSidebarFeatureState, state => {
  return state.load;
});

export const getIsAdultStatus = createSelector(selectSidebarFeatureState, state => {
  return state.isAdult;
});
