import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MarketingHttp } from 'lib-core';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as sectionActions from '../actions/section.actions';
import { SectionModel } from '../models/section.model';

@Injectable()
export class SectionEffects {
  getSections = createEffect(() =>
    this.actions$.pipe(
      ofType(sectionActions.getSections),
      mergeMap(() => this.marketingService.getSections()),
      map((sections: SectionModel[]) => sectionActions.getSectionsSuccess({ sections })),
      catchError(error => of(sectionActions.getSectionsFail({ error })))
    )
  );

  constructor(
    private actions$: Actions,
    private marketingService: MarketingHttp
  ) {}
}
