import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { CategoriesHttp } from 'lib-core';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import {
  getCategories,
  getCategoriesSuccess,
  GetCategoryTreeAttempt,
  GetCategoryTreeFail,
  GetCategoryTreeSuccess
} from '../actions/category.action';
import { CategoryStoreModel } from '../models/category.model';
import { selectCategories } from '../reducers/category.reducer';

@Injectable()
export class CategoryEffects {
  constructor(
    private actions$: Actions,
    private categoriesHttp: CategoriesHttp,
    private store: Store<CategoryStoreModel>
  ) {}

  getCategories = createEffect(() =>
    this.actions$.pipe(
      ofType(getCategories),
      withLatestFrom(this.store.pipe(select(selectCategories))),
      mergeMap(([value, store]) => {
        if (store) {
          return of();
        } else {
          return this.categoriesHttp.getMercuryCategorysNodes().pipe(
            map((res: any) => ({
              data: res.data
            }))
          );
        }
      }),
      map((data: any) => getCategoriesSuccess({ categories: data.data }))
    )
  );

  getCategoryTree = createEffect(() =>
    this.actions$.pipe(
      ofType(GetCategoryTreeAttempt),
      mergeMap(action => this.categoriesHttp.getMercuryCategorysNodesCheri(action.slug)),
      catchError(error => of(GetCategoryTreeFail({ error }))),
      map((data: any) => GetCategoryTreeSuccess({ data: data.data }))
    )
  );
}
