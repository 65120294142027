import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorModalService } from './error-modal.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
  standalone: true
})
export class ErrorModalComponent implements OnDestroy {
  @ViewChild('content', { static: false }) content: ElementRef;

  private rendererFn;

  constructor(
    private renderer: Renderer2,
    private router: Router,
    private errorModalService: ErrorModalService
  ) {
    this.rendererFn = this.renderer.listen('window', 'click', (e: Event) => {
      if (this.content && !this.content.nativeElement.contains(e.target)) {
        this.navigatoToHomepage();
      }
    });
  }

  showModal() {
    this.errorModalService.showModal();
  }

  hideModal() {
    this.errorModalService.hideModal();
  }

  navigatoToHomepage() {
    this.router.navigateByUrl('/');
    this.hideModal();
  }

  ngOnDestroy(): void {
    if (this.rendererFn) {
      this.rendererFn();
    }
  }
}
