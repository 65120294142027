import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Subject } from 'rxjs';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { PopupData } from './popup-overlay.component';

@Injectable({
  providedIn: 'root'
})
export class PopupService {
  private _event$: Subject<PopupData> = new Subject<PopupData>();
  event$ = this._event$.asObservable();

  private _hideCloseButton$: Subject<boolean> = new Subject<boolean>();
  hideCloseButton$ = this._hideCloseButton$.asObservable();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  open(data: PopupData): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    document.body.classList.add('modal-open');
    this._event$.next(data);
  }

  close(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    document.body.classList.remove('modal-open');
    this._event$.next(null);
  }

  hideCloseButton(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    this._hideCloseButton$.next(true);
  }
}
