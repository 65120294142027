import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { GetCampaignsAttempt, GetCampaignsSuccess, GetCampaignsFailed } from '../actions/campaigns.actions';
import { CampaignsModel } from '../models/campaigns.model';
import { CampaignsState } from '../states/campaigns.state';

export const CampaignsReducer = createReducer(
  CampaignsState,
  on(GetCampaignsAttempt, state => ({ ...state, loading: true, error: [] })),
  on(GetCampaignsSuccess, (state, action) => ({ ...state, data: action, loading: false, error: [] })),
  on(GetCampaignsFailed, (state, action) => ({ ...state, loading: false, error: action.error }))
);

export function reducer(state: CampaignsModel, action: Action) {
  return CampaignsReducer(state, action);
}

export const selectCampaignsState = createFeatureSelector<CampaignsModel>('campaigns');

export const selectCampaigns = createSelector(selectCampaignsState, state => {
  return state.data;
});

export const selectCampaignsLoadingState = createSelector(selectCampaignsState, state => state.loading);
