import { createAction, props } from '@ngrx/store';
import { SectionModel } from '../models/section.model';

export enum SectionActionsTypes {
  getSections = '[SECTIONS] LOAD_SECTIONS',
  getSectionsSuccess = '[SECTIONS] GET_SECTIONS_SUCCESS',
  getSectionsFail = '[SECTIONS] GET_SECTIONS_FAIL'
}

export const getSections = createAction(SectionActionsTypes.getSections);
export const getSectionsSuccess = createAction(
  SectionActionsTypes.getSectionsSuccess,
  props<{ sections: SectionModel[] }>()
);
export const getSectionsFail = createAction(SectionActionsTypes.getSectionsFail, props<{ error: any }>());
