import { ChangeDetectorRef, Injectable, OnDestroy } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { BehaviorSubject, timer } from 'rxjs';
import { catchError, first, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UtilityService implements OnDestroy {
  isLoading$ = new BehaviorSubject(false);

  toBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  checkExistingEmailOrPhone(signupService, exception?, time = 300) {
    return (control: AbstractControl) => {
      return timer(time).pipe(
        first(),
        switchMap(() => {
          this.isLoading$.next(true);
          if (signupService.check) {
            return signupService.check(control.value);
          } else if (signupService.checkIfMerchantExists) {
            return signupService.checkIfMerchantExists(control.value);
          }
        }),
        catchError(() => {
          this.isLoading$.next(false);
          return null;
        }),
        map((res: any) => {
          this.isLoading$.next(false);

          if (typeof res === 'object' && res !== null) {
            // for juridical 'res' is an object, so we need to extract 'payload' property
            return res.payload && control.value !== exception ? { used: res.payload } : null;
          } else {
            return res && control.value !== exception ? { used: res } : null;
          }
        })
      );
    };
  }

  ngOnDestroy() {
    this.isLoading$.unsubscribe();
  }
}
