import { Directive, Output, EventEmitter, ElementRef, HostListener, NgModule } from '@angular/core';

@Directive({
  selector: '[clickOutside]',
  standalone: true
})
export class ClickOutsideDirective {
  @Output() onClickOutside = new EventEmitter<void>();
  @Output() handleOutsideClick = new EventEmitter<void>();

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:click', ['$event.target'])
  public onClick(target) {
    const clickedInside = this.elementRef.nativeElement.contains(target);
    if (!clickedInside && target.id !== 'forOpenContent') {
      this.onClickOutside.emit();
    }
  }

  @HostListener('document:click', ['$event.target'])
  public onMouseClick(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.handleOutsideClick.emit();
    }
  }
}
