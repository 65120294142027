export interface BannerModel {
  bannerPlaceName: string;
  bannerPlaceId: number;
  section: number;
  bannerPlaceType: number;
  banners: BannerItemModel[];
  error: any;
}

export interface BannerItemModel {
  bannerId: number;
  fileName: string;
  name: string;
  link: string;
  url: string;
  sortIndex: number;
  status: number;
}
export enum BannerPlaceType {
  Web = 1,
  Mobile,
  Ribbon
}
export enum BannerSection {
  BannerCarousel = 1,
  Sales,
  Notebooks,
  TVS,
  SEARCH,
  FourBanners = 6,
  HorizontalBanner,
  Ribon
}
