import { createAction, props } from '@ngrx/store';
import { BannerModel } from '../models/banner.model';

export enum BannerActionsTypes {
  getBanners = '[BANNERS] LOAD_BANNERS',
  getBannersSuccess = '[BANNERS] GET_BANNERS_SUCCESS',
  getBannersFail = '[BANNERS] GET_BANNERS_FAIL'
}

export const getBanners = createAction(BannerActionsTypes.getBanners);
export const getBannersSuccess = createAction(
  BannerActionsTypes.getBannersSuccess,
  props<{ banners: BannerModel[] }>()
);
export const getBannersFail = createAction(BannerActionsTypes.getBannersFail, props<{ error: any }>());
