import { createAction, props } from '@ngrx/store';
import { AuthModel } from '../models/auth.model';

export enum AuthActionsTypes {
  LogIn = '[AUTH] LogIn',
  LogOut = '[AUTH] LogOut'
}

export const logIn = createAction(AuthActionsTypes.LogIn, props<AuthModel>());
export const logOut = createAction(AuthActionsTypes.LogOut);
