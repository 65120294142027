import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CategoryMenuService implements OnDestroy {
  private data: any;
  public closeCategories = new Subject<void>();

  constructor() {}

  public setData(value: any): void {
    this.data = value;
  }

  public getData(): void {
    return this.data;
  }

  public closeModal(): void {
    this.closeCategories.next();
  }

  ngOnDestroy() {
    this.closeCategories.complete();
  }
}
