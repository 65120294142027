import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID
} from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MarketingHttp, Unsubscriber } from 'lib-core';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { OverflowHandlerService } from '../../../services/overflow-handler.service';
import { NotificationPopupByType } from '../../../store/models/notification-popups.model';
import { NewInputModule } from '../../../shared/components/HOC/forms/new-input/new-input.component';
import { TranslateModule } from '@ngx-translate/core';
import { ClickOutsideDirective } from '../../../shared/directives/clickOutside.directive';

@Component({
  selector: 'app-notification-popup',
  standalone: true,
  templateUrl: './notification-popup.component.html',
  imports: [NewInputModule, ReactiveFormsModule, TranslateModule, CommonModule, ClickOutsideDirective],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationPopupComponent extends Unsubscriber implements OnInit {
  @Input()
  currentPopupObject$: BehaviorSubject<any>;
  public popupTypesByType = NotificationPopupByType;

  @Output() successPopupToggle = new EventEmitter<boolean>();
  @Output() closeNotificationPopup = new EventEmitter<any>();

  form: UntypedFormGroup;
  load: boolean;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private formBuilder: UntypedFormBuilder,
    private marketingHttp: MarketingHttp,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private overflowHandlerService: OverflowHandlerService
  ) {
    super();
  }

  overflowHandler(status: boolean): void {
    if (isPlatformBrowser(this.platformId)) {
      if (status) {
        this.load = true;
        this.overflowHandlerService.hide();
      } else {
        this.load = false;
        this.overflowHandlerService.show();
      }
      this.cdr.markForCheck();
    }
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  onSubmit(id) {
    if (this.form.valid) {
      let email = this.form.get('email').value;
      this.marketingHttp
        .sendEmail({ popUpId: id, email: email })
        .pipe(
          takeUntil(this.destroy$),
          catchError(err => {
            console.log('Handling error locally and rethrowing it...', err);
            // this.sendEmailSuccess$.next(false);
            this.successPopupToggle.emit(false);
            return throwError(err);
          })
        )
        .subscribe(val => {
          this.successPopupToggle.emit(true);
        });
    }
  }

  redirectToCurrentLink(e, link) {
    e.preventDefault();
    if (link) {
      this.router.navigate([link]);
      this.onClose();
    }
  }

  onClose() {
    this.closeNotificationPopup.emit();
    this.successPopupToggle.emit();
  }
}
