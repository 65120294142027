import { createAction, props } from '@ngrx/store';
import {
  ExpressOrderDebtPaymentSuccessModel,
  GetOrdersModel,
  OrdersModel,
  PayExpressOrderDebtModel
} from '../models/orders.model';

export enum OrdersActionsTypes {
  GetCustomerOrders = '[ORDERS] GetCustomerOrders',
  GetCustomerOrderSuccess = '[ORDERS] GetCustomerOrder Success',
  PayExpressOrderDebt = '[ORDERS] PayExpressOrderDebt',
  PayExpressOrderDebtSuccess = '[ORDERS] PayExpressOrderDebt Success',
  PayExpressOrderDebtFailure = '[ORDERS] PayExpressOrderDebt Failure'
}

export const getCustomerOrders = createAction(OrdersActionsTypes.GetCustomerOrders, props<GetOrdersModel>());
export const getCustomerOrderSuccess = createAction(
  OrdersActionsTypes.GetCustomerOrderSuccess,
  props<{ orders: OrdersModel[] }>()
);
export const PayExpressOrderDebt = createAction(
  OrdersActionsTypes.PayExpressOrderDebt,
  props<PayExpressOrderDebtModel>()
);
export const PayExpressOrderDebtSuccess = createAction(
  OrdersActionsTypes.PayExpressOrderDebtSuccess,
  props<ExpressOrderDebtPaymentSuccessModel>()
);
export const PayExpressOrderDebtFailure = createAction(OrdersActionsTypes.PayExpressOrderDebtFailure, props<any>());
