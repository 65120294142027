import { createAction, props } from '@ngrx/store';

export enum GuardActionsTypes {
  SetSelectedCategory = '[GUARD] SetSelectedCategory',
  OpenBirthDateTab = '[GUARD] OpenBirthDateTab',
  CloseBirthDateTab = '[GUARD] CloseBirthDateTab',
  LegalAgeStatusSuccess = '[GUARD] legalAgeStatusSuccess',
  LegalAgeStatusFail = '[GUARD] legalAgeStatusFail'
}

export const setGuardSelectedCategory = createAction(
  GuardActionsTypes.SetSelectedCategory,
  props<{ selectedCategory: any }>()
);
export const openBirthDateTab = createAction(GuardActionsTypes.OpenBirthDateTab);
export const closeBirthDateTab = createAction(GuardActionsTypes.CloseBirthDateTab);
export const legalAgeStatusSuccess = createAction(GuardActionsTypes.LegalAgeStatusSuccess);
export const legalAgeStatusFail = createAction(GuardActionsTypes.LegalAgeStatusFail);
