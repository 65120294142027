import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PopularBannerService {
  public showPopularBannerPopup = new BehaviorSubject(false);

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  showModal() {
    this.showPopularBannerPopup.next(true);
  }

  hideModal() {
    this.showPopularBannerPopup.next(false);
  }

  detectCookieBannerFirstLoadState() {
    if (localStorage.getItem('access_token')) {
      if (localStorage.getItem('close-popular-popup')) {
        this.hideModal();
      } else {
        this.showModal();
      }
    } else {
      const lastShownTime = localStorage.getItem('unauthorized-popular-popup');
      if (!lastShownTime || this.isMoreThan24Hours(new Date(lastShownTime))) {
        this.showModal();
        localStorage.removeItem('unauthorized-popular-popup');
      } else {
        this.hideModal();
      }
    }
  }

  isMoreThan24Hours(date: Date): boolean {
    const currentTime = new Date().getTime();
    const lastShownTime = date.getTime();
    const twentyFourHoursInMilliseconds = 24 * 60 * 60 * 1000;
    return currentTime > lastShownTime + twentyFourHoursInMilliseconds;
  }

  closePopup(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.hideModal();
      if (localStorage.getItem('access_token') && !localStorage.getItem('close-popular-popup')) {
        localStorage.setItem('close-popular-popup', 'true');
      }
      if (!localStorage.getItem('access_token') && !localStorage.getItem('unauthorized-popular-popup')) {
        localStorage.setItem('unauthorized-popular-popup', new Date().toString());
      }
    }
  }
}
