import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  Output,
  PLATFORM_ID
} from '@angular/core';
import { Unsubscriber } from 'lib-core';
import { AddressService } from '../../services/address.service';
import { OverflowHandlerService } from '../../services/overflow-handler.service';

@Component({
  selector: 'app-popular-banner',
  templateUrl: './popular-banner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopularBannerComponent extends Unsubscriber implements OnDestroy {
  @Output() closeExpressAddressSuggestion: EventEmitter<void> = new EventEmitter<void>();
  status: boolean = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private addressService: AddressService,
    private overflowService: OverflowHandlerService
  ) {
    super();
  }

  public openAddressFormModal(): void {
    this.addressService.isFromExpress = true;
    this.closePopup();
    this.addressService.openAddressFormModal();
  }

  closePopup() {
    this.closeExpressAddressSuggestion.emit();
  }
}
