import { Injectable } from '@angular/core';

interface ElementWithId {
  id?: number;
}

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  constructor() {}

  public clearOrderIdsFromLocalStorage(): void {
    localStorage.getItem('orderExternalId') && localStorage.removeItem('orderExternalId');
    localStorage.getItem('orderStrId') && localStorage.removeItem('orderStrId');
  }

  public moveElementToStartOfTheArray<T extends ElementWithId>(array: T[], elementId: number): T[] {
    const elementIndex = array.findIndex(({ id }) => id === elementId);
    if (elementIndex > -1) {
      const [element] = array.splice(elementIndex, 1);
      array.unshift(element);
    }
    return array;
  }

  public findAdditionalElementBetweenTwoArrayWithId<T extends ElementWithId>(array1: T[], array2: T[]): T | undefined {
    const set = new Set(array1?.map(({ id }) => id));
    return array2.find(({ id }) => !set.has(id));
  }
}
