import { createAction, props } from '@ngrx/store';
import { GetImageByExternalIdDataModel } from '../models/search-by-image.model';

export enum ESearchByImageActions {
  UploadSearchImageAttempt = '[IMAGE] Upload Image Attempt',
  UploadSearchImageSuccess = '[IMAGE] Upload Image Success',
  UploadSearchImageFailed = '[IMAGE] Upload Image Failed',

  getImageByExternalIdAttempt = '[IMAGE] Get Image by external Id Attempt',
  getImageByExternalIdSuccess = '[IMAGE] Get Image by external Id Success',
  getImageByExternalIdFailed = '[IMAGE] Get Image by external Id Failed',
  clearImageByExternalId = '[IMAGE] Clear Image by external Id'
}

export const UploadSearchImageAttempt = createAction(
  ESearchByImageActions.UploadSearchImageAttempt,
  props<{ imageData: any }>()
);
export const UploadSearchImageSuccess = createAction(ESearchByImageActions.UploadSearchImageSuccess);
export const UploadSearchImageFailed = createAction(ESearchByImageActions.UploadSearchImageFailed, props<any>());

export const getImageByExternalIdAttempt = createAction(
  ESearchByImageActions.getImageByExternalIdAttempt,
  props<{ externalId: string }>()
);
export const getImageByExternalIdSuccess = createAction(
  ESearchByImageActions.getImageByExternalIdSuccess,
  props<GetImageByExternalIdDataModel>()
);
export const getImageByExternalIdFailed = createAction(ESearchByImageActions.getImageByExternalIdFailed, props<any>());
export const clearImageByExternalIdState = createAction(ESearchByImageActions.clearImageByExternalId);
