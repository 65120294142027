import { Injectable } from '@angular/core';
import { OverflowHandlerService } from './overflow-handler.service';
import { AddressService } from './address.service';

export interface confirmationDialogData {
  title?: string;
  text?: string;
  confirmAction: () => void;
}

@Injectable({
  providedIn: 'root'
})
export class ConfirmationDialogService {
  public isConfirmationDialogOpen: boolean = false;

  public confirmationDialogData: confirmationDialogData = null;

  constructor(
    private overflowHandlerService: OverflowHandlerService,
    private addressService: AddressService
  ) {}

  public openConfirmationModal(data: confirmationDialogData): void {
    this.isConfirmationDialogOpen = true;
    this.confirmationDialogData = data;
    this.overflowHandlerService.hide();
  }

  public closeConfirmationModal(): void {
    this.isConfirmationDialogOpen = false;
    this.confirmationDialogData = null;
    if (!this.addressService.isAddressListingModalOpen) {
      this.overflowHandlerService.show();
    }
  }
}
