import { createAction, props } from '@ngrx/store';
import { ShippingAddressInteface } from '../models/addresses.model';

export enum EAddressesActions {
  GetShippingAddressesAttempt = '[Addresses] Get Shipping Addresses Attempt',
  GetShippingAddressesSuccess = '[Addresses] Get Shipping Addresses Success',
  GetShippingAddressesFailed = '[Addresses] Get Shipping Addresses Failed',

  AddShippingAddressAttempt = '[Addresses] Add Shipping Address Attempt',
  AddShippingAddressSuccess = '[Addresses] Add Shipping Address Success',
  AddShippingAddressFailed = '[Addresses] Add Shipping Address Failed',

  EditShippingAddressAttempt = '[Addresses] Edit Shipping Address Attempt',
  EditShippingAddressSuccess = '[Addresses] Edit Shipping Address Success',
  EditShippingAddressFailed = '[Addresses] Edit Shipping Address Failed',

  RemoveShippingAddressAttempt = '[Addresses] Remove Shipping Address Attempt',
  RemoveShippingAddressSuccess = '[Addresses] Remove Shipping Address Success',
  RemoveShippingAddressFailed = '[Addresses] Remove Shipping Address Failed',

  ChangeDefaultShippingAddressAttempt = '[Addresses] Change Default Shipping Address Attempt',
  ChangeDefaultShippingAddressSuccess = '[Addresses] Change Default Shipping Address Success',
  ChangeDefaultShippingAddressFailed = '[Addresses] Change Default Shipping Address Failed'
}

export const GetShippingAddressesAttempt = createAction(EAddressesActions.GetShippingAddressesAttempt);
export const GetShippingAddressesSuccess = createAction(EAddressesActions.GetShippingAddressesSuccess, props<any>());
export const GetShippingAddressesFailed = createAction(EAddressesActions.GetShippingAddressesFailed, props<any>());

export const AddShippingAddressAttempt = createAction(
  EAddressesActions.AddShippingAddressAttempt,
  props<{ shippingAddress: ShippingAddressInteface; force?: boolean }>()
);
export const AddShippingAddressSuccess = createAction(
  EAddressesActions.AddShippingAddressSuccess,
  props<{ res: any; force: boolean }>()
);
export const AddShippingAddressFailed = createAction(EAddressesActions.AddShippingAddressFailed, props<any>());

export const EditShippingAddressAttempt = createAction(
  EAddressesActions.EditShippingAddressAttempt,
  props<ShippingAddressInteface>()
);
export const EditShippingAddressSuccess = createAction(EAddressesActions.EditShippingAddressSuccess, props<any>());
export const EditShippingAddressFailed = createAction(EAddressesActions.EditShippingAddressFailed, props<any>());

export const RemoveShippginAddressAttempt = createAction(
  EAddressesActions.RemoveShippingAddressAttempt,
  props<{ addressId: number }>()
);
export const RemoveShippginAddressSuccess = createAction(EAddressesActions.RemoveShippingAddressSuccess, props<any>());
export const RemoveShippginAddressFailed = createAction(EAddressesActions.RemoveShippingAddressFailed, props<any>());

export const ChangeDefaultShippingAddressAttempt = createAction(
  EAddressesActions.ChangeDefaultShippingAddressAttempt,
  props<{ id: number; isDefault: boolean }>()
);
export const ChangeDefaultShippingAddressSuccess = createAction(
  EAddressesActions.ChangeDefaultShippingAddressSuccess,
  props<any>()
);
export const ChangeDefaultShippingAddressFailed = createAction(
  EAddressesActions.ChangeDefaultShippingAddressFailed,
  props<any>()
);
