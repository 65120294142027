import { Routes } from '@angular/router';

export const STATIC_ROUTES: Routes = [
  {
    path: 'static',
    loadChildren: () => import('./footer-static-page/footer-static-page.module').then(m => m.FooterStaticPageModule)
  }

  // {
  //   path: 'promotions',
  //   loadChildren: () =>
  //     import('./about-team/about-team.module').then(
  //       m => m.AboutTeamModule
  //     )
  // },
  // {
  //   path: 'about-us',
  //   loadChildren: () =>
  //     import('./about-us/about-us.module').then(
  //       m => m.AboutUsModule
  //     )
  // },
  // {
  //   path: 'rebank',
  //   loadChildren: () =>
  //     import('./rebank/rebank.module').then(
  //       m => m.RebankModule
  //     )
  // },
  // {
  //   path: 'career',
  //   loadChildren: () =>
  //     import('./career/career.module').then(
  //       m => m.CareerModule
  //     )
  // },
  // {
  //   path: 'publicity',
  //   loadChildren: () =>
  //     import('./publicity/publicity.module').then(
  //       m => m.PublicityModule
  //     )
  // },
  // {
  //   path: 'how-to-sell',
  //   loadChildren: () =>
  //     import('./how-to-sell/how-to-sell.module').then(
  //       m => m.HowToSellModule
  //     )
  // },
  // {
  //   path: 'FAQ',
  //   loadChildren: () =>
  //     import('./faq/faq.module').then(
  //       m => m.FaqModule
  //     )
  // },
  // {
  //   path: 'return-change',
  //   loadChildren: () =>
  //     import('./return-change/return-change.module').then(
  //       m => m.ReturnChangeModule
  //     )
  // },
  // {
  //   path: 'customer-agreement',
  //   loadChildren: () =>
  //     import('./customer-agreement/customer-agreement.module').then(
  //       m => m.CustomerAgreementModule
  //     )
  // },
  // {
  //   path: 'privacy',
  //   loadChildren: () =>
  //     import('./privacy/privacy.module').then(
  //       m => m.PrivacyModule
  //     )
  // },
  // {
  //   path: 'privacy-policy',
  //   loadChildren: () =>
  //     import('./privacy-policy/privacy-policy.module').then(
  //       m => m.PrivacyPolicyModule
  //     )
  // },
  // {
  //   path: 'installment',
  //   loadChildren: () =>
  //     import('./installment-purchase/installment-purchase.module').then(
  //       m => m.InstallmentPurchaseModule
  //     )
  // }
];
