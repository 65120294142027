import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { Guid } from 'guid-typescript';
@Injectable()
export class HomeHttp {
  constructor(private api: ApiService) {}
  public getCustomerProductView(data): Observable<any> {
    return this.api.get('api/customers/get-customer-product-views', data, 'customersURL');
  }
  public getMercuryBillieJean(data) {
    if (!data.sortBy) {
      data.sortBy = 1;
    }
    if (!data.sortType) {
      data.sortType = 1;
    }
    return this.api.post('search/billie-jean', data, {}, 'mercury-api-url');
  }
  public getBannerDetails(param: any) {
    return this.api.get('api/banners/details', param, 'marketingURL');
  }
  private getGuidId(): string {
    return Guid.create().toString();
  }
}
