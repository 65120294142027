import { Injectable } from '@angular/core';
import { ApiService } from '../services/api.service';

@Injectable({
  providedIn: 'root'
})
export class EmailOrPhoneService {
  constructor(private api: ApiService) {}

  check(email: string) {
    return this.api.get(`api/Account/exists/${email}`);
  }
}
