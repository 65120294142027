import { createAction, props } from '@ngrx/store';
import { BankTypeForCard, CardModel } from '../models/card.model';

export enum CardActionTypes {
  GetCards = '[CARDS] GetCards',
  GetCardsSuccess = '[CARDS] GetCards Success',
  DeleteCard = '[CARDS] Delete Card',
  DeleteCardSuccess = '[CARDS] Delete Card Success',
  SetCardtoMain = '[Cards] Set Card to Main',
  SetCardtoMainSuccess = '[Cards Set Card to Main Success]'
}

export const getCards = createAction(CardActionTypes.GetCards, (bankType: BankTypeForCard = null) => ({ bankType }));
export const getCardsSuccess = createAction(CardActionTypes.GetCardsSuccess, props<{ data: CardModel[] }>());
export const deleteCard = createAction(CardActionTypes.DeleteCard, props<{ id: number }>());
export const deleteCardSuccess = createAction(CardActionTypes.DeleteCardSuccess, props<{ id: number }>());
export const setCardtoMain = createAction(CardActionTypes.SetCardtoMain, props<{ id: number; accountId: number }>());
export const setCardtoMainSuccess = createAction(CardActionTypes.SetCardtoMainSuccess, props<{ id: number }>());
