import { ChangeDetectionStrategy, Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { SmartBannerService } from '../../services/smart-banner.service';
import { SmartbannerFn } from './smart-banner.fn';

@Component({
  selector: 'app-smart-banner',
  templateUrl: './smart-banner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmartBannerComponent implements OnInit {
  public isFromSafari: boolean;
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private smartBannerService: SmartBannerService
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.isFromSafari = SmartbannerFn.isFromSafari();
      this.smartBannerService.isFromSafari = this.isFromSafari;
    }
  }

  public redirectToMobileApp(): void {
    if (isPlatformBrowser(this.platformId)) {
      const userAgent = SmartbannerFn.getUserAgent();
      if (SmartbannerFn.isValidDevice(userAgent)) {
        const url: string = 'https://mobile.extra.ge/share';
        return window.location.replace(url);
      }
      console.error('device operating system is undefined');
    }
  }

  public closeSmartBanner(): void {
    this.smartBannerService.closeSmartBanner();
  }
}
