import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ProductsHttp } from 'lib-core';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import {
  getImageByExternalIdAttempt,
  getImageByExternalIdFailed,
  getImageByExternalIdSuccess,
  UploadSearchImageAttempt,
  UploadSearchImageFailed,
  UploadSearchImageSuccess
} from '../actions/search-by-image.action';
import { GetImageByExternalIdDataModel } from '../models/search-by-image.model';

@Injectable()
export class SearchByImageEffects {
  constructor(
    private actions$: Actions,
    private productsHttp: ProductsHttp,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  uploadSearchImage = createEffect(() =>
    this.actions$.pipe(
      ofType(UploadSearchImageAttempt),
      mergeMap(({ imageData }: any) => {
        return this.productsHttp.uploadSearchImage(imageData).pipe(
          map((res: any) => {
            this.router.navigate([this.router.url.includes('search') ? this.router.url.split('?')[0] : 'search'], {
              queryParams: {
                ...this.route.snapshot.queryParams,
                img: res.externalId
                // p: 1,
              },
              queryParamsHandling: ''
            });
            return UploadSearchImageSuccess();
          }),
          catchError(err => {
            return of(UploadSearchImageFailed(err));
          })
        );
      })
    )
  );

  getImageByExternalId = createEffect(() =>
    this.actions$.pipe(
      ofType(getImageByExternalIdAttempt),
      mergeMap(({ externalId }: any) =>
        this.productsHttp.getImageByExternalId(externalId).pipe(
          map((res: GetImageByExternalIdDataModel) => {
            return getImageByExternalIdSuccess(res);
          }),
          catchError(err => {
            return of(getImageByExternalIdFailed(err));
          })
        )
      )
    )
  );
}
