import { Injectable, OnDestroy } from '@angular/core';
import { CatalogHttp, Unsubscriber } from 'lib-core';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ServerTimeService extends Unsubscriber implements OnDestroy {
  public serverTime?: number = new Date().getTime();

  constructor(private catalogService: CatalogHttp) {
    super();
  }
  public waitForServerReponse$ = new ReplaySubject();

  private timeDiff: number = null;

  public getServerTime() {
    this.catalogService
      .getServerTime()
      .pipe(takeUntil(this.destroy$))
      .subscribe(item => {
        this.timeDiff = new Date().getTime() - new Date(item).getTime();
        this.waitForServerReponse$.next(true);
      });
  }

  get getRealTime() {
    return this.timeDiff && new Date().getTime() - this.timeDiff;
  }

  ngOnDestroy(): void {
    this.waitForServerReponse$.complete();
    super.ngOnDestroy();
  }
}
