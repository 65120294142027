import { Directive, ElementRef, HostListener, Input, NgModule } from '@angular/core';

@Directive({
  selector: '[appInputWothoutNumber]'
})
export class InputWothoutNumberDirective {
  @Input() withoutNumber: boolean = true;
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInput(event: KeyboardEvent): void {
    if (!this.withoutNumber) return;
    const inputElement = this.el.nativeElement as HTMLInputElement;
    inputElement.value = inputElement.value.replace(/\d/g, '');
  }
}

@NgModule({
  declarations: [InputWothoutNumberDirective],
  exports: [InputWothoutNumberDirective]
})
export class InputWothoutNumberDirectiveModule {}
