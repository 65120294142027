import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import {
  getSidebarFilter,
  getSidebarFilterSuccess,
  setSidebarFilterCriteria
} from '../actions/sidebar-filters.actions';
import { SidebarFilterModel } from '../models/sidebar-filters.model';

export const initialState: SidebarFilterModel = {
  data: null,
  result: null,
  criteria: null
};

const sidebarFiltersReducer = createReducer(
  initialState,
  on(getSidebarFilter, (state, action) => ({ ...state, data: action.data })),
  on(getSidebarFilterSuccess, (state, action) => ({ ...state, result: action.filter })),
  on(setSidebarFilterCriteria, (state, action) => ({ ...state, criteria: action }))
);

export function reducer(state, action: Action) {
  return sidebarFiltersReducer(state, action);
}

export const selectSidebarState = createFeatureSelector('sidebarFilter');

export const getSidebarFilterState = createSelector(selectSidebarState, state => state);
