<div
  class="_s_label-a-center _x_fixed _x_left-0 _x_top-0 _x_z-12 _x_mb-auto _x_flex _x_h-100vh _x_w-full _x_items-center _x_justify-center _x_bg-dark-900">
  <div
    *ngIf="currentPopupObject$ | async as popupObj"
    class="_x_relative _x_mx-8 _x_flex _x_w-294 _x_max-w-294 _x_flex-col _x_items-center _x_justify-center _x_overflow-hidden _x_rounded-8 sm:_x_h-294 sm:_x_max-h-294"
    clickOutside
    (onClickOutside)="this.closeNotificationPopup.emit(); this.overflowHandler(false)">
    <button (click)="onClose()" class="_x_absolute _x_right-8 _x_top-8 _x_flex _x_items-center _x_justify-center">
      <i
        class="_x_icon _x_icon-remove _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_cursor-pointer _x_items-center _x_justify-center _x_text-4 _x_text-light-gray"></i>
    </button>
    <a href="javascript:void(0);" (click)="redirectToCurrentLink($event, popupObj.link)" class="_x_w-full">
      <img class="_x_w-full" [src]="popupObj.url" alt="image of popup objectives" />
    </a>

    <div
      *ngIf="popupObj.form === popupTypesByType.Email"
      class="_x_absolute _x_bottom-10 _x_flex _x_grid _x_w-full _x_max-w-195 _x_grid-cols-12 _x_px-10 md:_x_bottom-15 md:_x_px-unset">
      <form [formGroup]="form" (ngSubmit)="onSubmit(popupObj.id, $event)" class="_x_relative _x_col-span-12 _x_m-0">
        <app-new-input
          [isInvalid]="form.get('email').touched && form.get('email').invalid"
          formControlName="email"
          [placeholder]="'PLACEHOLDER.EMAIL' | translate"
          [inputType]="'text'"></app-new-input>

        <button
          class="_x_col-span-12 _x_mt-5 _x_h-24 _x_w-full _x_items-center _x_justify-center _x_rounded-8 _x_bg-purple _x_text-white _x_transition _x_duration-300 lg:_x_flex">
          <span class="_x_font-bold _x_text-2">გაგზავნა</span>
        </button>
      </form>
    </div>
  </div>
</div>
