import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  DynamicSelectComponent,
  DynamicSelectFooterTemplateDirective,
  DynamicSelectOptionTemplateDirective
} from './dynamic-select.component';

@NgModule({
  imports: [CommonModule, FormsModule, NgSelectModule],
  declarations: [DynamicSelectComponent, DynamicSelectFooterTemplateDirective, DynamicSelectOptionTemplateDirective],
  exports: [DynamicSelectComponent, DynamicSelectFooterTemplateDirective, DynamicSelectOptionTemplateDirective]
})
export class DynamicSelectModule {}
