import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { moveToNextRoute } from '../actions/navigation.actions';
import { RouteModel } from '../models/route.model';

export const initialState: RouteModel = {
  currentLocation: '/',
  previousLocation: '/'
};

const navigationReducer = createReducer(
  initialState,
  on(moveToNextRoute, (state, action) => ({ ...state, ...action }))
);

export const selectNavigationFeatureState = createFeatureSelector<RouteModel>('navigation');

export const navigationStatus = (state: RouteModel) => state;

export function reducer(state: RouteModel, action: Action) {
  return navigationReducer(state, action);
}

export const getPreviousUrl = createSelector(selectNavigationFeatureState, state => {
  return { currentLocation: state.currentLocation, previousLocation: state.previousLocation };
});
