import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import {
  clearBasketSuccess,
  clearCheckoutBasket,
  deleteBasket,
  deleteBasketFail,
  deleteBasketSuccess,
  getBasketSuccess,
  getCheckOutBasket,
  getCheckOutBasketFail,
  getCheckOutBasketSuccess,
  makeCheckoutSuccessFalse,
  updateBasketSuccess
} from '../actions/basket.actions';
import { BasketModel } from '../models/basket.model';

export const initialState: any = {
  loading: false,
  data: [],
  totalPrice: 0,
  checkout: null,
  quantityFailed: false,
  checkoutStatus: false,
  checkoutBasketLoading: false
};

const basketReducer = createReducer(
  initialState,
  on(getBasketSuccess, (state, action) => ({
    ...state,
    ...action,
    checkoutStatus: false
  })),
  on(makeCheckoutSuccessFalse, state => ({
    ...state,
    checkoutStatus: false
  })),
  on(clearCheckoutBasket, (state, action) => ({ ...state, checkout: null })),
  on(getCheckOutBasket, state => ({
    ...state,
    checkout: null,
    checkoutStatus: false,
    checkoutBasketLoading: true
  })),
  on(getCheckOutBasketSuccess, (state, action) => ({
    ...state,
    checkout: action,
    checkoutStatus: true,
    checkoutBasketLoading: false
  })),
  on(getCheckOutBasketFail, state => ({
    ...state,
    checkout: null,
    checkoutStatus: false,
    checkoutBasketLoading: false
  })),
  on(updateBasketSuccess, (state, action) => ({
    ...state,
    ...action,
    checkoutStatus: false
  })),
  on(deleteBasket, (state, action) => ({ ...state, ...action, loading: true })),
  on(deleteBasketSuccess, (state, action) => ({
    ...state,
    ...action,
    checkoutStatus: false,
    loading: false
  })),
  on(deleteBasketFail, (state, action) => ({
    ...state,
    ...action,
    loading: false
  })),
  on(clearBasketSuccess, (state, action) => ({
    ...state,
    ...action,
    clearStatus: true
  }))
);

export function reducer(state: BasketModel, action: Action) {
  return basketReducer(state, action);
}

export const selectBasketFeatureState = createFeatureSelector<BasketModel>('basket');

export const basketStatus = (state: BasketModel) => state;

export const getUserBasket = createSelector(selectBasketFeatureState, basketStatus);

export const getUserCheckOutBasket = createSelector(selectBasketFeatureState, state => {
  if (state && state.checkout) {
    return state.checkout;
  }
  return;
});

export const getDeleteBasketLoadingState = createSelector(selectBasketFeatureState, state => state.loading);

export const getBasketProductCount = createSelector(selectBasketFeatureState, state => {
  let count = 0;
  if (state && state.data) {
    state.data.forEach(item => (count = count + item.productCount));
  }
  return count;
});

export const getBasketProductCountById = id =>
  createSelector(selectBasketFeatureState, state => {
    if (state && state.data) {
      return state.data.find(item => item.productId == id);
    }
    return;
  });

export const getBasketClearStatus = createSelector(selectBasketFeatureState, state => state.clearStatus);

export const checkoutBasketLoadingState = createSelector(
  selectBasketFeatureState,
  state => state.checkoutBasketLoading
);
