import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { GetRibbonAttempt, GetRibbonFailed, GetRibbonSuccess } from '../actions/ribbon.actions';
import { RibbonModel } from '../models/ribbon.model';
import { RibbonStates } from '../states/ribbon.state';

export const RibbonReducer = createReducer(
  RibbonStates,
  on(GetRibbonAttempt, state => ({ ...state, loading: true, error: [] })),
  on(GetRibbonSuccess, (state, action) => ({ data: action.data, loading: false, error: [] })),
  on(GetRibbonFailed, (state, action) => ({ ...state, loading: false, error: action.error }))
);

export function reducer(state: RibbonModel, action: Action) {
  return RibbonReducer(state, action);
}

export const selectRibbonState = createFeatureSelector<RibbonModel>('ribbon');

export const selectRibbon = createSelector(selectRibbonState, data => {
  if (data?.data?.banners.length) {
    const { ribbonColor, bannerDescription, url } = data.data.banners[0];
    return { ribbonColor, bannerDescription, url };
  }
  return null;
});
export const selectRibbonLoadingState = createSelector(selectRibbonState, data => data.loading);

export const selectRibbonActiveState = createSelector(selectRibbonState, data => Boolean(data?.data?.banners?.length));
