import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import * as ProductActions from '../actions/product.actions';
import { ProductModel } from '../models/product.model';

export interface State extends EntityState<ProductModel> {
  activeProductId: number | null;
  adultAge: boolean | null;
}

export const productAdapter: EntityAdapter<ProductModel> = createEntityAdapter<ProductModel>();

const initialState: State = productAdapter.getInitialState({
  activeProductId: null,
  adultAge: false
});

const userReducer = createReducer(
  initialState,
  on(ProductActions.getProductByIdSuccess, (state, action) => {
    return productAdapter.upsertOne(action, state);
  }),
  on(ProductActions.setActiveProductSuccess, (state, { id }) => {
    return { ...state, activeProductId: id };
  }),
  on(ProductActions.getProducts, (state, action) => {
    return { ...state, adultAge: false };
  }),
  on(ProductActions.getProductsSuccess, (state, action: any) => {
    const nState = { ...state };
    /* if(!nState.adultAge && !action.hasAgeContentRestriction) {
      nState.adultAge = action.hasAgeContentRestriction;
      return productAdapter.addMany(action.data, nState);
    }*/
    nState.adultAge = action.hasAgeContentRestriction;
    return productAdapter.addMany(action.data, nState);
  }),
  on(ProductActions.getLandingFilteredProductsSuccess, (state, action: any) => {
    return productAdapter.setAll(action.data, state);
  })
);

export function reducer(state: State | undefined, action: Action) {
  return userReducer(state, action);
}

export const selectProductState = createFeatureSelector<ProductModel>('product');

export const selectProducts = createSelector(selectProductState, allItems => allItems);

export const selectActiveProduct = createSelector(selectProductState as any, (val: selectActiveProductInterface) => {
  return val.entities[val.activeProductId] && val.entities[val.activeProductId];
});

export const selectProductsByIDs = createSelector(selectProductState, (state, props) => {
  return props.ids.map(id => (state.entities[id] ? state.entities[id] : null)).filter(item => item);
});

export const selectProductEntities = createSelector(selectProductState, (state): ProductModel[] =>
  state.ids.map(id => state.entities.data[id])
);
export const selectProductAgeRestriction = createSelector(selectProductState, (state): boolean => state.adultAge);

interface selectActiveProductInterface {
  entities: any;
  ids: any[];
  activeProductId: any;
}
