import { AfterViewInit, ChangeDetectionStrategy, Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { ScriptsService } from './services/scripts.service';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, of, startWith, Subscription, switchMap, takeUntil } from 'rxjs';
import { GlobalDataHttp, LegalEntityService, Unsubscriber } from 'lib-core';
import { GlobalModalService } from './services/global-modal-service';
import { PrivacyPolicyPopupComponent } from './shared/components/global-modal-wrapper/privacy-policy-popup/privacy-policy-popup.component';
import { Store } from '@ngrx/store';
import { getUserData } from './store/reducers/user.reducer';
import { PrivacyPolicyService } from './services/privacy-policy.service';
import { UserTypeService } from './services/user-type.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent extends Unsubscriber {
  privacyPolicySubscription: Subscription;

  constructor(
    private meta: Meta,
    private scripstService: ScriptsService,
    private router: Router,
    private globalModalService: GlobalModalService,
    private store: Store,
    private privacyPolicyService: PrivacyPolicyService,
    private userTypeService: UserTypeService,
    private legalEntityService: LegalEntityService,
    private globalDataHttp: GlobalDataHttp
  ) {
    super();
    if (!environment.production) {
      this.meta.addTag({ name: 'robots', content: 'noindex' });
    }
    this.scripstService.loadScript();
  }

  private get userData$(): Observable<any> {
    if (this.userTypeService.isUserLegalEntity) {
      const response = this.legalEntityService.getCustomer();
      this.userTypeService.setlegalEntityUser(response);
      return response;
    } else {
      return this.store.select(getUserData);
    }
  }

  ngOnInit(): void {
    this.ListenPrivacyPolicyPopup();
    this.listenPrivacyPolicyDestroy();
    this.isImpersonatedUser();
  }

  private ListenPrivacyPolicyPopup() {
    if (this.privacyPolicyService.isUserAgreebyRegister) return;
    this.privacyPolicySubscription = this.userData$
      .pipe(
        takeUntil(this.destroy$),
        switchMap(res => {
          if (res?.userId && !res.optedForAdsLastUpdateDate) {
            this.privacyPolicyService.hasUserPrivacyPolicyUpdated$.next(false);
            return this.router.events.pipe(takeUntil(this.destroy$), startWith('firstEmit'));
          } else {
            this.privacyPolicyService.hasUserPrivacyPolicyUpdated$.next(true);
            return of();
          }
        })
      )
      .subscribe({
        next: event => {
          this.onRoucteChange(event);
        }
      });
  }

  private onRoucteChange(event: any) {
    if (event instanceof NavigationEnd || event === 'firstEmit') {
      const url: string = this.router.url;
      if (url === '/static/privacy') {
        this.globalModalService.close();
      } else {
        this.globalModalService.open(PrivacyPolicyPopupComponent, null, null, false, true, '_x_z-11');
      }
    }
  }

  private listenPrivacyPolicyDestroy() {
    this.privacyPolicyService.destroyPrivacyPolicy$.pipe(takeUntil(this.destroy$)).subscribe({
      next: destroy => {
        destroy && this.privacyPolicySubscription.unsubscribe();
      }
    });
  }

  private isImpersonatedUser() {
    this.globalDataHttp.isImpersonatedUser().subscribe({
      next: res => {
        if (res) {
          alert('ადმინკიდან გახსნილი მომხმარებლის გვერდი');
        }
      }
    });
  }
}
