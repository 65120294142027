import { createAction, props } from '@ngrx/store';

export enum CategoryActionsTypes {
  GetCategories = '[CATEGORY] GetCategories',
  GetCategoriesSuccess = '[CATEGORY] GetCategoriesSuccess',

  GetCategoryTreeAttempt = '[CATEGORY] GetCategoryTreeAttempt',
  GetCategoryTreeSuccess = '[CATEGORY] GetCategoryTreeSuccess',
  GetCategoryTreeFail = '[CATEGORY] GetCategoryTreeFail'
}

export const getCategories = createAction(CategoryActionsTypes.GetCategories);
export const getCategoriesSuccess = createAction(
  CategoryActionsTypes.GetCategoriesSuccess,
  props<{ categories: any }>()
);

export const GetCategoryTreeAttempt = createAction(
  CategoryActionsTypes.GetCategoryTreeAttempt,
  props<{ slug: string }>()
); //or number if value is id
export const GetCategoryTreeSuccess = createAction(CategoryActionsTypes.GetCategoryTreeSuccess, props<{ data: any }>());
export const GetCategoryTreeFail = createAction(CategoryActionsTypes.GetCategoryTreeFail, props<{ error: any }>());
