<div
  class="_x_fixed _x_inset-0 _x_z-12 _x_m-auto _x_flex _x_h-20 _x_h-auto _x_max-h-full _x_w-full _x_max-w-266 _x_items-center _x_justify-center _x_overflow-hidden _x_rounded-12 _x_px-8">
  <div class="_x_relative _x_rounded-12 _x_bg-white sm:_x_w-full">
    <div class="_x_flex _x_flex-col _x_items-center _x_items-stretch">
      <div class="_x_border-b _x_border-b-dark-100 _x_p-8 _x_text-left">
        <p class="_x_font-bold _x_text-5 _x_text-black">{{ title }}</p>
      </div>
      <div class="_x_p-8">
        <p class="_x_mb-9 _x_font-medium _x_text-3 _x_text-dark-900">
          გაცნობებთ, რომ განახლდა
          <a
            [href]="baseUrl + '/static/privacy'"
            class="_x_cursor-pointer _x_text-link-blue _x_underline"
            target="_blank"
            >კონფიდენციალურობის პოლიტიკა</a
          >. გთხოვთ, გაეცნოთ და დაეთანხმოთ მას
        </p>
        <button
          (click)="onSubmit()"
          class="_x_h-24 _x_w-full _x_items-center _x_justify-center _x_rounded-8 _x_border-1 _x_border-purple _x_bg-purple _x_font-bold _x_font-medium _x_text-3 _x_text-white _x_transition _x_duration-300 hover:_x_border-purple-100 hover:_x_bg-purple-100 hover:_x_text-purple lg:_x_flex">
          {{ btnTitle }}
        </button>
      </div>
    </div>
  </div>
</div>
