import { Action, createFeatureSelector, createReducer, on } from '@ngrx/store';
import { deleteCardSuccess, getCardsSuccess, setCardtoMainSuccess } from '../actions/cards.actions';
import { CardModel } from '../models/card.model';

export const initialState: CardModel[] = [];

const cardsReducer = createReducer(
  initialState,
  on(getCardsSuccess, (state, action) => {
    return action.data;
  }),
  on(deleteCardSuccess, (state, action) => {
    return state.filter(card => card.id !== action.id);
  }),
  on(setCardtoMainSuccess, (state, action) => {
    let cardsData = state.map(card => {
      if (card.id == action.id) {
        return { ...card, isPrimary: true };
      } else {
        return { ...card, isPrimary: false };
      }
    });

    return cardsData;
  })
);

export function reducer(state: any, action: Action) {
  return cardsReducer(state, action);
}

export const cardsFeatureSelector = createFeatureSelector<CardModel[]>('cards');
