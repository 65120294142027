import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable, map, of, switchMap } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { IsMobileService } from './is-mobile.service';

@Injectable({
  providedIn: 'root'
})
export class SmartBannerService {
  private openSmartBanner_: BehaviorSubject<boolean>;
  public isFromSafari: boolean = false;

  get openSmartBanner$(): Observable<boolean> {
    return this.openSmartBanner_
      ?.asObservable()
      .pipe(
        switchMap(isOpenSmartBanner =>
          this.isMobileService.isMobileDevice.pipe(map(isMobie => isOpenSmartBanner && isMobie))
        )
      );
  }
  get IsSmartBannerClosed$(): Observable<boolean> {
    return of(!sessionStorage.getItem('closeSmartBanner'));
  }

  set openSmartBanner(status) {
    this.openSmartBanner_.next(status);
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private isMobileService: IsMobileService
  ) {
    this.initSmartBanner();
  }

  private initSmartBanner() {
    if (isPlatformBrowser(this.platformId)) {
      const isSmartBannerClosed: boolean = !!sessionStorage.getItem('closeSmartBanner');
      this.openSmartBanner_ = new BehaviorSubject<boolean>(!isSmartBannerClosed);
    }
  }

  closeSmartBanner() {
    if (isPlatformBrowser(this.platformId)) {
      this.openSmartBanner = false;
      sessionStorage.setItem('closeSmartBanner', 'true');
    }
  }
}
