import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { BasketHttp, CatalogHttp, ProductsHttp, Unsubscriber } from 'lib-core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  catchError,
  distinctUntilChanged,
  finalize,
  forkJoin,
  of,
  takeUntil
} from 'rxjs';
import { DARK_STORE_DATA, DARK_STORE_DATA_KEY } from '../constants/express.constants';
import { nonExpressAddressWarningModalComponent } from '../shared/components/non-express-address-warning-modal/non-express-address-warning-modal.component';
import { GetShippingAddressesAttempt } from '../store/actions/addresses.actions';
import { setActiveProduct } from '../store/actions/product.actions';
import { ShippingAddressInteface } from '../store/models/addresses.model';
import * as fromProduct from '../store/models/product.model';
import { ProductModel } from '../store/models/product.model';
import { UserModel } from '../store/models/user.model';
import { selectAddresses } from '../store/reducers/addresses.reducer';
import { getUserStatus } from '../store/reducers/auth.reducer';
import { selectActiveProduct } from '../store/reducers/product.reducer';
import { AddressService } from './address.service';
import { GlobalModalService } from './global-modal-service';

export interface darkStore {
  darkStoreId?: number;
  defaultDarkStoreId?: number;
  districtId?: number;
  isDefault?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ExpressService extends Unsubscriber implements OnDestroy {
  product$: Observable<fromProduct.ProductModel> = this.store.pipe(select(selectActiveProduct));
  //search cases variables
  private _searchCase: boolean = null;
  private _searchCase$ = new BehaviorSubject<boolean>(false);
  //loading state variables
  private _loadingState: boolean = false;
  private _loadingState$ = new BehaviorSubject<boolean>(false);
  //Addresses variables
  private _darkStoreId: number = null;
  private _darkStoreData$: Subject<darkStore> = new Subject<darkStore>();
  private _darkStoreData: darkStore = null;
  private userAddresses$: Observable<ShippingAddressInteface[]> = this.store.select(selectAddresses);
  private userAddresses: ShippingAddressInteface[] = null;
  public isExpressAddressSuggestionClosed = false;
  public showAddressSuggestionPopup = false;
  userStatus$ = this.store.pipe(select(getUserStatus));
  isUserLoggedIn = false;
  private outOfStockExpressProducts_ = new BehaviorSubject<any[]>(null);

  constructor(
    private _catalogService: CatalogHttp,
    private addressService: AddressService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private store: Store<UserModel>,
    private _basketService: BasketHttp,
    private route: ActivatedRoute,
    private router: Router,
    private productsHttp: ProductsHttp,
    private catalogHttp: CatalogHttp,
    private globalModalService: GlobalModalService
  ) {
    super();
    this.store.dispatch(GetShippingAddressesAttempt());
    this.userAddresses$
      .pipe(
        distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
        takeUntil(this.destroy$)
      )
      .subscribe((addresses: ShippingAddressInteface[]) => {
        this.userAddresses = addresses;
      });
    this.userStatus$.pipe(takeUntil(this.destroy$)).subscribe(status => {
      this.isUserLoggedIn = status;
    });
  }

  public get outOfStockExpressProducts$(): Observable<ProductModel[]> {
    return this.outOfStockExpressProducts_.asObservable();
  }

  public set outOfStockExpressProducts(products: any[]) {
    this.outOfStockExpressProducts_.next(products);
  }

  //  Search cases
  getSearchCase(): boolean {
    return this._searchCase;
  }

  getSearchCase$(): Observable<boolean> {
    return this._searchCase$.asObservable();
  }

  setSearchCase(value: boolean): void {
    this._searchCase = value;
    this._searchCase$.next(value);
  }

  // Express Cases
  getExpressProductsLoadingState(): boolean {
    return this._loadingState;
  }

  getExpressProductsLoadingState$(): Observable<boolean> {
    return this._loadingState$.asObservable();
  }

  setExpressProductsLoadingState(value: boolean): void {
    this._loadingState = value;
    this._loadingState$.next(value);
  }

  addSearchCase(): void {
    this.setSearchCase(true);
  }

  removeSearchCase(): void {
    this.setSearchCase(false);
  }

  //  Addresses
  getDarkStoreId(): number {
    return this._darkStoreData?.darkStoreId || this._darkStoreData?.defaultDarkStoreId;
  }

  getDarkStoreData(): darkStore {
    return this._darkStoreData;
  }

  getDarkStoreData$(): Observable<darkStore> {
    return this._darkStoreData$.asObservable();
  }

  setDarkStoreId(reload = false, darkStoreDataFromApi: darkStore = null) {
    let darkStoreIdFromApi = darkStoreDataFromApi?.darkStoreId || darkStoreDataFromApi?.defaultDarkStoreId;
    let cachedDarkStoreData = JSON.parse(localStorage.getItem(DARK_STORE_DATA_KEY));
    if (isPlatformBrowser(this.platformId)) {
      this._darkStoreData = darkStoreDataFromApi || cachedDarkStoreData || DARK_STORE_DATA;
      this._darkStoreData$.next(this._darkStoreData);
      if (darkStoreIdFromApi || cachedDarkStoreData?.darkStoreId || cachedDarkStoreData?.defaultDarkStoreId) {
        this.addressService.setSelectedAddressId(parseInt(this.addressService.getSelectedAddressIdFromLocalStorage()));
      }
      if (
        reload &&
        !(
          darkStoreDataFromApi?.darkStoreId == cachedDarkStoreData?.darkStoreId &&
          darkStoreDataFromApi?.defaultDarkStoreId == cachedDarkStoreData?.defaultDarkStoreId &&
          darkStoreDataFromApi?.districtId == cachedDarkStoreData?.districtId
        )
      ) {
        localStorage.setItem(DARK_STORE_DATA_KEY, JSON.stringify(darkStoreDataFromApi));
        const isCheckoutFromDetailed: boolean =
          this.router.url.includes('checkout') && !!this.route.snapshot.queryParams?.productId;
        if (isCheckoutFromDetailed) {
          this.store.dispatch(
            setActiveProduct({
              id: this.route.snapshot.queryParams?.productId,
              slug: this.route.snapshot.queryParams?.productOriginalSlug
            })
          );
          this.product$
            .subscribe(product => {
              if (product) {
                this.getProductFromAnotherDarkstore(product)
                  .then(productId => {
                    productId && this.navigateWithNewProductId(productId);
                    this.updateProducts();
                  })
                  .catch(error => {
                    this.updateProducts();
                  });
              } else {
                this.updateProducts();
              }
            })
            .unsubscribe();
        } else {
          //update Basket or Wishlist Products should be called last, cause it trigers location.reload
          this.updateProducts();
        }
      } else {
        this.setExpressProductsLoadingState(false);
      }
    }
  }

  navigateWithNewProductId(newProductId: number) {
    this.route.queryParams.subscribe(params => {
      // Create a new params object with the new productId
      const newParams = { ...params, productId: newProductId, id: newProductId };
      // Navigate to the new URL with updated productId
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: newParams,
        queryParamsHandling: 'merge', // Preserve other query params
        replaceUrl: true // Replace the current state in history
      });
    });
  }

  updateProducts() {
    if (this.isUserLoggedIn && this._darkStoreData?.darkStoreId) {
      const basketUpdate$ = this._basketService.updateBasketExpressProducts(this._darkStoreData?.darkStoreId);
      const wishlistUpdate$ = this._basketService.updateWishlistExpressProducts(this._darkStoreData?.darkStoreId);

      forkJoin([basketUpdate$, wishlistUpdate$])
        .pipe(
          finalize(() => this.finalizeUpdates()),
          takeUntil(this.destroy$)
        )
        .subscribe();
    } else {
      this.finalizeUpdates();
    }
  }

  finalizeUpdates() {
    window.location.reload();
  }

  clearDarkStoreData() {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem(DARK_STORE_DATA_KEY);
      this.addressService.clearSelectedAddressId();
    }
  }

  updateDarkStoreData(reload = true, address: ShippingAddressInteface) {
    this.setExpressProductsLoadingState(true);
    this.addressService.setSelectedAddressId(address?.id);
    this._catalogService
      .getExpressDarkStoreId(address)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res: any) => {
          this.setDarkStoreId(reload, res);
        },
        () => {
          console.error('err');
          this.setExpressProductsLoadingState(false);
        }
      );
  }

  checkProductDarkStoreId(productDarkStoreId, func: any, arg?: any) {
    if (
      !productDarkStoreId ||
      (productDarkStoreId === this._darkStoreData?.darkStoreId && !this._darkStoreData?.isDefault)
    ) {
      func(arg);
    } else {
      this.globalModalService.open(nonExpressAddressWarningModalComponent);
    }
  }

  openAddressPopup() {
    if (this.userAddresses && this.userAddresses?.length) {
      this.addressService.openAddressListingModal();
    } else {
      this.addressService.openAddressFormModal();
    }
  }

  showProductActionButtons(productDarkStoreId): boolean {
    return (
      !productDarkStoreId ||
      (productDarkStoreId === this._darkStoreData?.darkStoreId && !this._darkStoreData?.isDefault)
    );
  }

  public checkOutOfSrockExpressProducts(products: any[]) {
    if (!products?.length) return;
    const payload = {
      products: products
        ?.filter(product => product.isExpress && product.darkStoreId === this.getDarkStoreData()?.darkStoreId)
        .map(product => ({
          productSku: product.sku,
          productQuantity: product.productCount || 1
        }))
    };
    if (!payload.products?.length) return;
    this.callCheckExpressProductStock(payload, products);
  }

  private callCheckExpressProductStock(payload: any, products: any[]) {
    this.productsHttp
      .checkExpressProductStock(payload)
      .pipe(
        takeUntil(this.destroy$),
        catchError(error => {
          console.error('API Error:', error);
          return of(null);
        })
      )
      .subscribe({
        next: res => {
          if (!res?.products?.length) {
            return;
          }
          const isNotStockMap = new Map();
          res.products.forEach(returnedProd => {
            isNotStockMap.set(returnedProd.productSku, returnedProd.isNotStock);
          });
          this.outOfStockExpressProducts = products
            ?.map(product => {
              if (isNotStockMap.has(product?.sku)) {
                return { ...product, isNotStock: isNotStockMap.get(product.sku) };
              }
              return null;
            })
            .filter(product => product);
        }
      });
  }

  private getProductFromAnotherDarkstore(product: fromProduct.ProductModel): Promise<any> {
    return new Promise((resolve, reject) => {
      if (
        this.getDarkStoreData().darkStoreId &&
        product?.darkStoreId !== this.getDarkStoreData().darkStoreId &&
        product?.isExpress
      ) {
        const darkStoreId: number = this.getDarkStoreId();
        this.catalogHttp
          .findExpressProductByDarkstore(product?.id, darkStoreId)
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            productId => {
              resolve(productId);
            },
            error => {
              reject(error);
            }
          );
      } else {
        resolve(null);
      }
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._searchCase$.complete();
    this._darkStoreData$.complete();
  }
}
