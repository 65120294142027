import { environment } from '../../environments/environment';

let timedDealsId = null;
let popularProductsId = null;

if (environment.develop) {
  timedDealsId = 75;
  popularProductsId = 110;
} else if (environment.staging) {
  timedDealsId = 4988;
  popularProductsId = 5710;
} else if (environment.production) {
  timedDealsId = 4988;
  popularProductsId = 5748;
}

export const TIMED_DEALS_ID = timedDealsId;
export const POPULAR_PRODUCTS_ID = popularProductsId;
export const SPECIAL_SET_IDS = [TIMED_DEALS_ID, POPULAR_PRODUCTS_ID];
