import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { logIn, logOut } from '../actions/auth.actions';
import { AuthModel } from '../models/auth.model';

export const initialState: AuthModel = {
  authStatus: null
};

const authReducer = createReducer(
  initialState,
  on(logIn, (state, action) => ({ ...state, ...action })),
  on(logOut, state => ({ ...state, ...initialState }))
);

export function reducer(state: AuthModel, action: Action) {
  return authReducer(state, action);
}

export const selectAuthFeatureState = createFeatureSelector<AuthModel>('auth');

export const authStatus = (state: AuthModel) => state.authStatus;

export const getUserStatus = createSelector(selectAuthFeatureState, authStatus);
