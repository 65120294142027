import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CookieBannerService implements OnDestroy {
  public showCookieBannerPopup = new BehaviorSubject(false);

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  showModal() {
    this.showCookieBannerPopup.next(true);
  }

  hideModal() {
    this.showCookieBannerPopup.next(false);
  }

  detectCookieBannerFirstLoadState() {
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem('access_token')) {
        if (localStorage.getItem('close-cookie-popup')) {
          this.hideModal();
        } else {
          this.showModal();
        }
      } else {
        const lastShownTime = localStorage.getItem('unauthorized-cookie-popup');
        if (!lastShownTime || this.isMoreThan24Hours(new Date(lastShownTime))) {
          this.showModal();
          localStorage.removeItem('unauthorized-cookie-popup');
        } else {
          this.hideModal();
        }
      }
    }
  }

  isMoreThan24Hours(date: Date): boolean {
    const currentTime = new Date().getTime();
    const lastShownTime = date.getTime();
    const twentyFourHoursInMilliseconds = 24 * 60 * 60 * 1000;
    return currentTime > lastShownTime + twentyFourHoursInMilliseconds;
  }

  ngOnDestroy() {
    this.showCookieBannerPopup.complete();
  }
}
