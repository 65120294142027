import { CommonModule } from '@angular/common';
import { Component, OnInit, NgModule } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { ToastService } from '../../../services/toast.service';
import { RibbonModel } from '../../../store/models/ribbon.model';
import { selectRibbonActiveState } from '../../../store/reducers/ribbon.reducer';
import { ToastComponent } from './toast/toast.component';

@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss']
})
export class ToasterComponent implements OnInit {
  currentToasts = [];
  currentToastsArray$ = new BehaviorSubject<any>([]);
  public selectRibbonActiveState$: Observable<any> = this.store.pipe(select(selectRibbonActiveState));

  constructor(
    private toastService: ToastService,
    private store: Store<RibbonModel>
  ) {}

  ngOnInit() {
    this.toastService.toastEvents.subscribe(toasts => {
      const currentToast = {
        type: toasts.type,
        title: toasts.title,
        message: toasts.message,
        isComparison: toasts.isComparison
      };
      this.currentToasts.push(currentToast);
      this.currentToastsArray$.next(this.currentToasts);
    });
  }

  closeToast(index: number) {
    this.currentToasts.splice(index, 1);
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [ToasterComponent, ToastComponent],
  exports: [ToasterComponent, ToastComponent]
})
export class ToasterModule {}
