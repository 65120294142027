import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  Directive,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Unsubscriber } from 'lib-core';
import { sortBy, uniqBy } from 'lodash-es';
import { BehaviorSubject, Observable, of, OperatorFunction } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';

@Directive({ selector: '[app-dynamic-select-footer-tmp]' })
export class DynamicSelectFooterTemplateDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Directive({ selector: '[app-dynamic-select-option-tmp]' })
export class DynamicSelectOptionTemplateDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Component({
  selector: 'app-dynamic-select',
  templateUrl: './dynamic-select.component.html',
  styleUrls: ['./dynamic-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DynamicSelectComponent),
      multi: true
    }
  ]
})
export class DynamicSelectComponent extends Unsubscriber implements ControlValueAccessor, OnInit, OnDestroy {
  @ViewChild('select', { static: true })
  select: ElementRef;

  @ViewChild(NgSelectComponent, { static: true })
  selectComponent: NgSelectComponent;

  @ContentChild(DynamicSelectFooterTemplateDirective, {
    static: true,
    read: TemplateRef
  })
  footerTemplate: TemplateRef<any>;

  @ContentChild(DynamicSelectOptionTemplateDirective, {
    static: true,
    read: TemplateRef
  })
  optionTemplate: TemplateRef<any>;

  @Input() sortField = 'name';
  @Input() placeholder: string;
  @Input('value') _value: any;
  @Input() dataGetter: (state: object) => Observable<any>;
  @Input() itemGetterById: (id: number) => Observable<any>;
  @Input() showClearButton: boolean;
  @Input() multiple: boolean;
  @Input() closeOnSelect = true;
  @Input() maxSelectedItems: number;
  @Input() searchable: boolean = true;
  @Input() customSearch: boolean = false;
  @Input() growable = true;
  @Input() addOptionButton = false;
  @Input() bindValue: string = 'id';
  @Input() required: boolean;
  @Output() change = new EventEmitter<any>();
  @Output() clear = new EventEmitter<void>();
  @Output() open = new EventEmitter<void>();
  @Output() closed = new EventEmitter<void>();
  @Input() filterBy;
  @Input() sendRequestOnOpen = true;

  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;
    this.currentState.searchValue = null;
    // this.dataRequester.next();
    this.onChange(val);
    this.onTouched();
    this.emitChange();
  }

  dataSource: Array<{ id: number; name?: string; caption?: string }> = [];
  isLoading = true;
  isDisabled: boolean;

  private waitingForData: boolean;
  private dataRequester = new BehaviorSubject<void>(null);
  private currentState = {
    pageSize: 500,
    pageIndex: 0,
    searchValue: null,
    totalCount: 0
  };

  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.listenDataRequester();
  }

  private listenDataRequester(): void {
    this.dataRequester.pipe(takeUntil(this.destroy$), this.toHttpGetItems).subscribe(({ data, totalCount }) => {
      this.currentState.totalCount = totalCount;
      this.updateDataSource(data);
    });
  }

  private get toHttpGetItems(): OperatorFunction<void, any> {
    return switchMap(() => {
      return this.dataGetter(this.currentState).pipe(
        takeUntil(this.destroy$),
        catchError(() => {
          return of([]);
        })
      );
    });
  }

  private requestItemById(id: number): void {
    if (this.itemGetterById) {
      this.itemGetterById(id)
        .pipe(
          takeUntil(this.destroy$),
          map(item => [item])
        )
        .subscribe(this.updateDataSource.bind(this));
    }
  }

  private updateDataSource(newItems: Array<{ id: number; name: string }> = []): void {
    let data: Array<{ id: number; name: string }>;
    if (this.growable) {
      data = uniqBy([...this.dataSource, ...newItems], 'id');
    } else {
      data = newItems;
    }

    this.dataSource = sortBy(data, i => i[this.sortField]);

    this.isLoading = false;

    if (this.waitingForData) {
      this.waitingForData = false;
    }

    this.emitChange();
    this.cdr.markForCheck();
  }

  private emitChange(): void {
    let value = this.value;
    if (value) {
      if (this.multiple) {
        value = this.value.map(id => this.dataSource.find(d => d.id === id));
      } else {
        value = this.dataSource.find(d => d.id === this.value);
      }
      if (!value) {
        this.waitingForData = true;
      }
    }
    this.change.emit(value);
  }

  onSearch({ term }): void {
    if (this.currentState.searchValue !== term) {
      this.currentState.searchValue = term;
      this.currentState.pageIndex = 0;
      // this.dataSource = [];
      this.isLoading = true;
      this.dataRequester.next();
    }
  }

  addOption(): void {
    const custId = new Date().getHours() + '' + new Date().getMinutes() + '' + new Date().getSeconds();
    this.dataSource = [...this.dataSource, { id: parseInt(custId), caption: this.currentState.searchValue }];
    this.cdr.markForCheck();
  }

  onScrollToEnd(): void {
    if (this.dataSource.length < this.currentState.totalCount) {
      this.currentState.pageIndex++;
      this.isLoading = true;
      this.dataRequester.next();
    }
  }

  onClear(): void {
    this.clear.emit();
    this.close();
  }

  writeValue(value: any): void {
    this.value = value;
    if (value || value === 0) {
      if (typeof value === 'number') {
        this.checkOrGetItem(value);
      } else {
        value.forEach(element => {
          this.checkOrGetItem(element);
        });
      }
    }
    this.cdr.markForCheck();
  }

  private checkOrGetItem(value): void {
    if (!this.dataSource.some(obj => obj.id === value)) {
      this.requestItemById(value);
    }
  }

  onChange = (val: any) => {};
  onTouched = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    this.cdr.markForCheck();
  }

  reload(): void {
    this.value = null;
    this.dataSource = [];
    this.dataRequester.next();
  }

  onOpen(): void {
    if (this.sendRequestOnOpen === true) {
      this.dataRequester.next();
    }
    this.open.emit();
  }

  onClose() {
    this.closed.emit();
  }

  close(): void {
    this.selectComponent.close();
  }

  sortData(): any {
    if (this.filterBy) {
      return this.dataSource.sort((a, b) => {
        if (a && b && a[this.filterBy] && b[this.filterBy]) {
          return a[this.filterBy] - b[this.filterBy];
        } else {
          return;
        }
      });
    } else {
      return this.dataSource;
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.dataRequester.complete();
  }
}
