export enum NotificationPopupByDate {
  Daily = 1,
  BySession,
  OnSuccessPage
}

export enum NotificationPopupByType {
  Email = 1,
  Default
}

export enum PlatformType {
  Desktop = 1,
  Mobile
}
