import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export type EventType = 'success' | 'info' | 'warning' | 'error' | 'successLight';
@Injectable({
  providedIn: 'root'
})
export class ToastService {
  toastEvents: Observable<any>;
  private _toastEvents = new Subject<any>();

  constructor() {
    this.toastEvents = this._toastEvents.asObservable();
  }

  showToastEvent(title: string, message: string, type: EventType, isComparison: boolean = false) {
    this._toastEvents.next({
      message,
      title,
      type,
      isComparison
    });
  }
}
