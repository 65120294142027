import { Injectable } from '@angular/core';
import { ShippingOptionType } from 'projects/web/src/app/store/models/orders.model';

@Injectable({
  providedIn: 'root'
})
export class CheckoutCommonService {
  public _selectedShippingOptionType = ShippingOptionType.Courier;

  constructor() {}

  setSelectedShippingOptionType(value: ShippingOptionType): void {
    this._selectedShippingOptionType = value;
  }

  getSelectedShippingOptionType(): ShippingOptionType {
    return this._selectedShippingOptionType;
  }
}
