import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';

import { OverflowHandlerService } from './overflow-handler.service';
import { ShippingAddressInteface } from '../store/models/addresses.model';

export const SELECTED_ADDRESS_ID_KEY = 'selected_address_id';

@Injectable({
  providedIn: 'root'
})
export class AddressService {
  public isAddressFormModalOpen = false;

  public isAddressListingModalOpen = false;

  public editAddressData: ShippingAddressInteface | null = null;

  public selectedAddressId: number | null = null;

  public selectedAddressId$: BehaviorSubject<number | null> = new BehaviorSubject(null);

  public selectedPickUpAddressId: number | null = null;

  public selectedPickUpAddress: ShippingAddressInteface | null = null;

  public selectedPickUpAddressId$: Subject<number | null> = new Subject();

  // this value is for update address listing checked value in checkout page
  public updateAddressListingWithNewValue: number | boolean = null;

  public isFromExpress = false;

  constructor(
    private formBuilder: FormBuilder,
    private overflowHandlerService: OverflowHandlerService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  public createAddressForm(): FormGroup {
    return this.formBuilder.group({
      id: [null],
      locationId: [null, Validators.required],
      location: [null, Validators.required],
      address: [null, Validators.required],
      addressAdditionalInfo: [null],
      longitude: [null],
      latitude: [null],
      isDefault: [false]
    });
  }

  public openAddressFormModal(): void {
    this.isAddressFormModalOpen = true;
    this.overflowHandlerService.hide();
  }

  public closeAddressFormModal(): void {
    this.isFromExpress = false;
    this.isAddressFormModalOpen = false;
    if (this.editAddressData) {
      this.editAddressData = null;
    }
    if (!this.isAddressListingModalOpen) {
      this.overflowHandlerService.show();
    }
  }

  public openAddressListingModal(): void {
    this.isAddressListingModalOpen = true;
    this.overflowHandlerService.hide();
  }

  public closeAddressListingModal(): void {
    this.isAddressListingModalOpen = false;
    this.overflowHandlerService.show();
  }

  public setSelectedAddressId(id: number | null): void {
    if (this.selectedAddressId == id) return;
    this.selectedAddressId = id;
    this.selectedAddressId$.next(id);
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(SELECTED_ADDRESS_ID_KEY, id?.toString());
    }
  }

  public setSelectedPickUpAddressId(id: number | null): void {
    this.selectedPickUpAddressId = id;
    this.selectedPickUpAddressId$.next(id);
  }

  public setSelectedPickUpAddress(address: ShippingAddressInteface | null): void {
    this.selectedPickUpAddress = address;
  }

  public clearSelectedAddressId() {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem(SELECTED_ADDRESS_ID_KEY);
    }
  }
  public getSelectedAddressIdFromLocalStorage(): any {
    return parseInt(localStorage.getItem(SELECTED_ADDRESS_ID_KEY));
  }
}
