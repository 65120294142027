<div class="_x_relative _x_flex _x_w-full _x_max-w-172 _x_justify-center _x_pl-8 sm:_x_px-0">
  <button
    class="_x_absolute _x_right-8 _x_top-9"
    (click)="closePopup()"
    aria-label="Close popup"
    role="button">
    <i
      class="hover:_x_opacity-75 _x_icon _x_icon-remove _x_flex _x_h-8 _x_min-h-8 _x_w-8 _x_min-w-8 _x_cursor-pointer _x_items-center _x_justify-center _x_text-3 _x_text-black"></i>
  </button>
  <i
    class="_x_absolute _x_top-minus-2 _x_flex _x_h-5 _x_w-5 _x_rotate-45 _x_transform _x_rounded-2 _x_bg-white sm:_x_left-25"
    aria-hidden="true"></i>
  <div
    class="_x_w-full _x_max-w-172 _x_rounded-8 _x_bg-white _x_p-8"
    role="dialog"
    aria-labelledby="dialogTitle"
    aria-describedby="dialogDescription">
    <div class="_x_mb-4 _x_flex _x_items-center">
      <p id="dialogTitle" class="_x_mr-4 _x_font-bold _x_text-4 _x_text-dark">ექსპრესი</p>
    </div>
    <p id="dialogDescription" class="_font-medium _x_text-3 _x_text-dark">ექსპრესით სარგებლობისთვის მიუთითე თბილისის მისამართი</p>
    <div class="_x_mt-8 _x_flex _x_justify-end">
      <a
        class="_x_cursor-pointer _x_font-bold _x_text-3 _x_text-link-blue"
        (click)="openAddressFormModal()"
        role="button"
        aria-label="Specify address">
        მისამართის მითითება
      </a>
    </div>
  </div>
</div>
