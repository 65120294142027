import { SearchByImageModel } from '../models/search-by-image.model';

export const SearchByImageStates: SearchByImageModel = {
  uploadImage: {
    loading: false,
    error: null
  },

  getImageByExternalId: {
    data: null,
    loading: false,
    error: null
  }
};
