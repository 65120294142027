import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class RecoverPasswordService {
  public isFromCheckout: boolean;
  public passwordIsRecovered = new Subject<boolean>();

  constructor() {}

  get getIsFromCheckout(): boolean {
    return this.isFromCheckout;
  }
}
