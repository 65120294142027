import { Injectable, OnDestroy } from '@angular/core';
import { OverflowHandlerService } from './overflow-handler.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { globalModalParams } from '../shared/models/interfaces/global-modal-params.interface';

@Injectable({
  providedIn: 'root'
})
export class GlobalModalService<InputModel = any> implements OnDestroy {
  public component$ = new BehaviorSubject<any>(null);
  public isOpen = new Subject<boolean>();
  public params: globalModalParams | null = null;
  public inputs$ = new BehaviorSubject<InputModel>(null);

  public response$ = new Subject<any>();
  public closeOnOutsideClick = true;
  public disableInspect: boolean = false;
  public modalWrapperClass: string = '';
  // Add new params in interface if you need one

  constructor(private overflowHandlerService: OverflowHandlerService) {}

  open(
    component: any,
    params = null,
    inputs: InputModel = null,
    closeOnOutsideClick: boolean = true,
    disableInspect: boolean = false,
    modalWrapperClass: string = ''
  ): void {
    this.modalWrapperClass = modalWrapperClass;
    this.overflowHandlerService.hide();
    this.component$.next(component);
    this.isOpen.next(true);
    this.params = params;
    this.inputs$.next(inputs);
    this.closeOnOutsideClick = closeOnOutsideClick;
    this.disableInspect = disableInspect;
  }

  close(response: any = null): void {
    this.overflowHandlerService.show();
    this.component$?.next(null);
    this.isOpen.next(false);
    this.inputs$.next(null);
    this.params = null;
    if (response !== null) {
      this.response$.next(response);
    }
    this.closeOnOutsideClick = true;
    this.disableInspect = false;
    this.modalWrapperClass = '';
  }

  hideCloseButton(): void {}

  ngOnDestroy(): void {
    this.component$?.unsubscribe();
    this.response$?.unsubscribe();
  }
}
