import { NgModule, ModuleWithProviders } from '@angular/core';
import { Config, CONFIG } from './config.model';
import { ApiService } from './services/api.service';

@NgModule({})
export class LibCoreModule {
  static forRoot(config: Config): ModuleWithProviders<LibCoreModule> {
    return {
      ngModule: LibCoreModule,
      providers: [
        {
          provide: CONFIG,
          useValue: config
        },
        ApiService
      ]
    };
  }
}
