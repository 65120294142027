import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ApiService } from '../api.service';
import { Guid } from 'guid-typescript';

@Injectable({
  providedIn: 'root'
})
export class LegalEntityService {
  constructor(private api: ApiService) {}

  addUserInfo(data: { companyName: string; juridicalId: string; userName: string }): Observable<any> {
    return this.api.post('api/Profile/register', data, { requestId: this.getGuidId() }, 'juridicalUrl');
  }

  check(email: string) {
    const params = {
      userName: email
    };
    return this.api.get(`api/Profile/exists`, params, 'juridicalUrl');
  }

  addUserSignUoValidation(data: userNameOtp) {
    return this.api.post('api/Profile/verify', data, { requestId: this.getGuidId() }, 'juridicalUrl');
  }

  addUserSingUpPassword(data: { username: string; password: string; token: string }) {
    return this.api.post('api/Profile/setPassword', data, { requestId: this.getGuidId() }, 'juridicalUrl');
  }

  updateUserPassword(data: { currentPassword: string; newPassword: string }) {
    return this.api.post('api/Profile/changePassword', data, { requestId: this.getGuidId() }, 'juridicalUrl');
  }

  removeOldPassword(data: userNameOtp) {
    return this.api.post('api/Profile/resetPassword', data, { requestId: this.getGuidId() }, 'juridicalUrl');
  }

  getCustomer() {
    return this.api.get('api/juridical', { requestId: this.getGuidId() }, 'customersURL').pipe(map(res => res.data));
  }

  getShippingAddresses(): Observable<ShippingAddressInteface[]> {
    return this.api.get('api/juridicalcustomers/get-shipping-address', { requestId: this.getGuidId() }, 'customersURL');
  }

  getCustomerSendVerificationDefaultPhoneNumber(data: { phoneNumber: string }): Observable<any> {
    return this.api.post('api/Profile/sendverification/phone', data, { requestId: this.getGuidId() }, 'juridicalUrl');
  }

  updateCustomerPhoneNumber(data: { phoneNumber: string; otp: string | number }): Observable<any> {
    return this.api.post('api/Profile/changePhone', data, { requestId: this.getGuidId() }, 'juridicalUrl');
  }

  sendVerificationEmail(data: { email: string }): Observable<any> {
    return this.api.post('api/Profile/sendverification/email', data, { requestId: this.getGuidId() }, 'juridicalUrl');
  }

  changeCustomerUserName(data: userNameOtp): Observable<any> {
    return this.api.post('api/Profile/changeUserName', data, { requestId: this.getGuidId() }, 'juridicalUrl');
  }

  updateCustomer(data): Observable<any> {
    return this.api.put('api/juridical', data, { requestId: this.getGuidId() }, 'customersURL');
  }

  getUserSignUoValidation(data: { username: string }): Observable<any> {
    return this.api.post('api/Profile/sendVerification', data, { requestId: this.getGuidId() }, 'juridicalUrl');
  }

  getUserPasswordResetToken(data: { username: string }): Observable<any> {
    return this.api.post('api/Profile/sendpasswordresetcode', data, { requestId: this.getGuidId() }, 'juridicalUrl');
  }

  resetPasswordToken(data): Observable<any> {
    return this.api.post('api/Profile/sendpasswordresettoken', data, { requestId: this.getGuidId() }, 'juridicalUrl');
  }

  getCustomers(pageNumber: number, pageSize: number, searchValue?): Observable<any> {
    return this.api.get(
      'api/juridical/list',
      {
        page: pageNumber,
        pageSize: pageSize,
        searchValue: searchValue && searchValue
      },
      'customersURL'
    );
  }

  getCustomerJuridical(userId: string): Observable<any> {
    const params = {
      juridicalId: userId
    };
    return this.api.get('api/juridical', params, 'customersURL');
  }

  DeleteJuridicalCustomer(userId: string): Observable<any> {
    return this.api.delete('api/profile/delete', { userId }, 'juridicalUrl');
  }

  getDeletedJuridicalCustomers(pageSize: number, pageNumber: number, searchValue?: string): Observable<any> {
    return this.api.get('api/Admin/users/deleted', { pageSize, pageNumber, searchValue }, 'juridicalUrl');
  }

  private getGuidId(): string {
    return Guid.create().toString();
  }
}

interface ShippingAddressInteface {
  id?: number;
  locationId: number;
  location: string;
  address: string;
  addressAdditionalInfo?: string;
  longitude: number;
  latitude: number;
  isDefault?: boolean;
  customerId?: string;
}

interface userNameOtp {
  userName: string;
  otp: string | number;
}
