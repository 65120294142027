<!--Custom Overlay-->
<div
  *ngIf="layoutStatus$ | async"
  class="_x_fixed _x_left-0 _x_top-0 _x_z-11 _x_h-screen _x_w-full _x_bg-dark-900 _x_opacity-90"
  (click)="onClose()"></div>
<!--Auth Sidebar-->

<div class="_x_w-full">
  <div
    [ngClass]="{
      _x_hidden: !(layoutStatus$ | async).burgerStatus,
      _x_flex: (layoutStatus$ | async).burgerStatus,
      'md:_x_justify-between': type !== 'basket'
    }"
    class="_s_z-9 _x_max-w-full _x_fixed _x_bottom-0 _x_left-0 _x_right-0 _x_top-0 _x_m-8 _x_my-8 _x_mb-auto _x_mt-auto _x_flex _x_max-h-340 _x_flex-col _x_justify-between _x_overflow-y-auto _x_rounded-20 _x_bg-white sm:_x_left-auto sm:_x_my-8 sm:_x_max-h-screen sm:_x_w-200"
    style="z-index: 10000000000000000000000000000">
    <div>
      <div class="_x_absolute _x_right-8 _x_top-8 _x_z-1 sm:_x_right-15 sm:_x_top-15">
        <button (click)="onClose()" type="button" class="_s_ml-auto _x_h-12">
          <i class="_x_icon _x_icon-remove _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_text-8 _x_text-dark"></i>
        </button>
      </div>
      <ng-template appDynamicHost></ng-template>
    </div>
    <div class="_x_mb-8 _x_flex _x_w-full _x_justify-center">
      <img
        *ngIf="!isRegistrationSuccessfull"
        class="_x_min-h-30 _x_max-h-55 _x_min-w-118 _x_max-w-118 sm:_x_max-h-71 sm:_x_max-w-140 xl:_x_max-h-92 xl:_x_max-w-170"
        src="../assets/img/svg/urban-line.svg"
        alt="icon of urban line" />
      <img
        *ngIf="isRegistrationSuccessfull"
        class="_x_max-h-106 _x_max-w-155 sm:_x_max-h-117 sm:_x_max-w-170"
        src="../assets/img/svg/success.svg"
        alt="icon of success" />
    </div>
  </div>
</div>
