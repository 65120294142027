import { CommonModule, isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, NgModule, OnDestroy, PLATFORM_ID } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Subject } from 'rxjs';
import { CookieBannerService } from './cookie-banner.service';
import { Unsubscriber } from 'lib-core';

@Component({
  selector: 'app-cookie-banner',
  templateUrl: './cookie-banner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CookieBannerComponent extends Unsubscriber implements OnDestroy {
  status: boolean = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private cookieBannerService: CookieBannerService
  ) {
    super();
  }

  closePopup(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.cookieBannerService.hideModal();
      if (localStorage.getItem('access_token') && !localStorage.getItem('close-cookie-popup')) {
        localStorage.setItem('close-cookie-popup', 'true');
      }
      if (!localStorage.getItem('access_token') && !localStorage.getItem('unauthorized-cookie-popup')) {
        localStorage.setItem('unauthorized-cookie-popup', new Date().toString());
      }
    }
  }
}

@NgModule({
  imports: [RouterModule, CommonModule],
  declarations: [CookieBannerComponent],
  exports: [CookieBannerComponent]
})
export class CookieBannerModule {}
