import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import {
  clearComparisonSuccess,
  deleteComparisonFail,
  deleteComparisonSuccess,
  getComparisonFail,
  getComparisonSuccess,
  updateComparisonFail,
  updateComparisonSuccess
} from '../actions/comparison.actions';

export const initialState: any = {
  productIds: []
};

const comparisonReducer = createReducer(
  initialState,
  on(getComparisonSuccess, (state, action) => ({ ...state, productIds: action.productIds })),
  on(getComparisonFail, (state, action) => ({ ...state, error: action.error })),
  on(updateComparisonSuccess, (state, action) => ({ ...state })),
  on(updateComparisonFail, (state, action) => ({ ...state, error: action.error })),
  on(deleteComparisonFail, (state, action) => ({ ...state, error: action.error }))
);

export function reducer(state: any, action: Action) {
  //add model
  return comparisonReducer(state, action);
}

export const selectComparisonFeatureState = createFeatureSelector<any>('comparison'); //add model

export const getComparisonProducts = createSelector(selectComparisonFeatureState, state => {
  return state.productIds;
});

export const getComparisonProductCount = createSelector(selectComparisonFeatureState, state => {
  let count = 0;
  if (state.productIds && state.productIds.length !== 0) {
    count = state.productIds.length;
  }
  return count;
});
