import { CommonModule, isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-backdrop',
  templateUrl: './backDrop.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
  standalone: true
})
export class BackDropComponent {
  constructor(public backdropService: BackDropService) {}
}

@Injectable({
  providedIn: 'root'
})
export class BackDropService implements OnDestroy {
  private _event$: Subject<boolean> = new Subject<boolean>();
  event$ = this._event$.asObservable();

  constructor(@Inject(PLATFORM_ID) private platformId: object) {}

  open(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    this._event$.next(true);
  }

  close(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    this._event$.next(false);
  }

  ngOnDestroy() {
    this._event$.complete();
  }
}
