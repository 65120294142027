import { WishlistModel } from '../models/wishlist.model';

export const WishlistStates: WishlistModel = {
  addProduct: {
    loading: false,
    error: null,
    temporaryIdsArray: []
  },

  products: {
    data: null,
    loading: false,
    error: null
  },

  productIds: {
    data: null,
    loading: false,
    error: null
  },

  removeProduct: {
    loading: false,
    error: null,
    temporaryIdsArray: []
  }
};
