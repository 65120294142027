import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { getSections, getSectionsFail, getSectionsSuccess } from '../actions/section.actions';

export const initialState: any = {
  categoryId: 0,
  categorySlug: '',
  id: 0,
  sectionId: 0,
  sectionName: ''
};

const sectionReducer = createReducer(
  initialState,
  on(getSections, () => ({ sections: {} })),
  on(getSectionsSuccess, (state, action: any) => {
    // const data = [...action.data];
    const data = [...action.sections.data];
    const dataObject = {};
    data.forEach(item => {
      if (!dataObject[item.sortIndex]) {
        dataObject[item.sortIndex] = {};
      }
      dataObject[item.sortIndex] = item;
    });
    return { ...action, data: dataObject };
  }),
  on(getSectionsFail, (state, action) => ({ sections: state.sections, error: action.error }))
);

export function reducer(state: any, action: any) {
  return sectionReducer(state, action);
}

export const selectSectionsFeatureState = createFeatureSelector<any>('sections');

export const SectionsStatus = (state: any) => state;

export const selectSectionsState = createSelector(selectSectionsFeatureState, SectionsStatus);
