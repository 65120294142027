import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CatalogHttp } from 'lib-core';
import { map, switchMap, tap } from 'rxjs/operators';
import { getSidebarFilter, getSidebarFilterSuccess } from '../actions/sidebar-filters.actions';
import { CategoryStoreModel } from '../models/category.model';

@Injectable()
export class SidebarFiltersEffects {
  constructor(
    private actions$: Actions,
    private catalogHttp: CatalogHttp,
    private store: Store<CategoryStoreModel>
  ) {}

  getBasket = createEffect(() =>
    this.actions$.pipe(
      ofType(getSidebarFilter),
      switchMap(data => this.catalogHttp.getCatalogSidebarFilter(data).pipe(map(res => res.data))),
      tap(res => {}),
      map(data => getSidebarFilterSuccess({ filter: data }))
    )
  );
}
