import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalModalService } from '../../../services/global-modal-service';
import { VerificationService } from '../../../services/verification.service';

@Component({
  selector: 'app-need-verification',
  templateUrl: './need-verification.component.html',
  styleUrls: ['./need-verification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class NeedVerificationComponent {
  constructor(
    private verificationService: VerificationService,
    private router: Router,
    private globalModalService: GlobalModalService
  ) {}

  public postponeVerification(navigateToProfile = false): void {
    if (navigateToProfile) {
      this.router.navigate(['/user', 'profile', 'data']);
    }

    this.verificationService.postponeVerification();
    this.closeModal();
  }

  public closeModal(): void {
    this.globalModalService.close();
  }
}
