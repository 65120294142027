export enum QuantityAction {
  Minus = 'minus',
  HandWrite = 'handWrite',
  Plus = 'plus'
}

export enum CustomerType {
  PhysicalCustomer = 1,
  JuridicalCustomer
}

export enum PaymentFlowType {
  ByBasket,
  ByBuyNowButton
}

export enum PromoCodeErrorTypes {
  None = 0,
  Default = 1,
  Limit,
  AppOnly,
  NotVerified
}

export enum ProductCardTypes {
  Standart,
  Express
}
