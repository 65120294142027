import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IConfig, NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { DynamicHostDirectiveModule } from '../../shared/directives/dynamic-host.directive';
import { SidebarComponent } from './sidebar.component';
import { RecoverPasswordService } from './sign/recover-password/recover-password.service';

export const SIDEBAR_ROUTES: Routes = [];

export const COMPONENTS = [SidebarComponent];

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    // NgxMaskModule.forRoot(options),
    NgxMaskDirective,
    NgxMaskPipe,
    DynamicHostDirectiveModule
  ],
  declarations: [...COMPONENTS],
  providers: [RecoverPasswordService, provideNgxMask()],
  exports: [SidebarComponent]
})
export class SidebarModule {}
