import { createAction, props } from '@ngrx/store';
import { CampaignsDataModel } from '../models/campaigns.model';

export enum ECampaignsActions {
  GetCampaignsAttempt = '[Campaigns] Get Campaigns Attempt',
  GetCampaignsSuccess = '[Campaigns] Get Campaigns Success',
  GetCampaignsFailed = '[Campaigns] Get Campaigns Failed'
}

export const GetCampaignsAttempt = createAction(ECampaignsActions.GetCampaignsAttempt);
export const GetCampaignsSuccess = createAction(ECampaignsActions.GetCampaignsSuccess, props<CampaignsDataModel>());
export const GetCampaignsFailed = createAction(ECampaignsActions.GetCampaignsFailed, props<any>());
