import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PrivacyPolicyService {
  public destroyPrivacyPolicy$ = new BehaviorSubject<boolean>(false);
  public isUserAgreebyRegister: boolean = false;
  public hasUserPrivacyPolicyUpdated$ = new BehaviorSubject<boolean>(false);

  constructor() {}
}
