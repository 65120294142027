import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

export class TrackByUtil {
  static trackById(_index: number, item: any): string {
    return item.id;
  }

  static trackByIndex(index: number): number {
    return index;
  }
}

@Injectable()
export abstract class Unsubscriber implements OnDestroy {
  protected destroy$ = new Subject<void>();
  private changeDetectorTriger_ = new BehaviorSubject<void>(null);
  timeoutIds: any[] = [];

  get changeDetectorTriger$(): Observable<void> {
    return this.changeDetectorTriger_.asObservable();
  }

  constructor() {
    // super();
  }

  /**
      შექმენი კომპონენტის template-ში ცარიელი div ელემენტი 
      და დაადე changeDetectorTriger$ ობზერვებლი 
      async ფაიფის გამოყენებით
  */
  trigerChangeDetection() {
    this.changeDetectorTriger_.next();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
    this.timeoutIds.forEach(id => clearTimeout(id));
  }
}
