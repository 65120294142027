import { createAction, props } from '@ngrx/store';

export enum MerchantActionsTypes {
  GetMerchantCategories = `[Merchant] GetMerchantCategories`,
  GetMerchantCategoriesSuccess = `[Merchant] GetMerchantCategoriesSuccess`,
  GetMerchantCategoriesFail = `[Merchant] GetMerchantCategoriesFail`,

  GetMerchantMammaMia = `[Merchant] GetMerchantMammaMia`,
  GetMerchantMammaMiaSuccess = `[Merchant] GetMerchantMammaMiaSuccess`,
  GetMerchantMammaMiaFail = `[Merchant] GetMerchantMammaMiaFail`
}

export const GetMerchantCategories = createAction(
  MerchantActionsTypes.GetMerchantCategories,
  props<{ categories: string }>()
);
export const GetMerchantCategoriesSuccess = createAction(
  MerchantActionsTypes.GetMerchantCategoriesSuccess,
  props<Object>()
);
export const GetMerchantCategoriesFail = createAction(
  MerchantActionsTypes.GetMerchantCategoriesFail,
  props<{ error }>()
);

export const GetMerchantMammaMia = createAction(MerchantActionsTypes.GetMerchantMammaMia, props<any>());
export const GetMerchantMammaMiaSuccess = createAction(
  MerchantActionsTypes.GetMerchantMammaMiaSuccess,
  props<Object>()
);
export const GetMerchantMammaMiaFail = createAction(MerchantActionsTypes.GetMerchantMammaMiaFail, props<{ error }>());
