import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CustomersHttp } from 'lib-core';
import { GlobalModalService } from 'projects/web/src/app/services/global-modal-service';
import { PrivacyPolicyService } from 'projects/web/src/app/services/privacy-policy.service';
import { getUser } from 'projects/web/src/app/store/actions/user.actions';
import { environment } from 'projects/web/src/environments/environment';
@Component({
  selector: 'app-privacy-policy-popup',
  templateUrl: './privacy-policy-popup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule]
})
export class PrivacyPolicyPopupComponent {
  title: string = 'კონფიდენციალურობის პოლიტიკის განახლება';
  btnTitle: string = 'ვეთანხმები';
  baseUrl: string = environment.baseUrl;

  constructor(
    private globalModalService: GlobalModalService,
    private customersHttp: CustomersHttp,
    private router: Router,
    private store: Store,
    private privacyPolicyService: PrivacyPolicyService
  ) {}

  public onSubmit() {
    this.customersHttp.updateIsOptedForAds(true).subscribe({
      next: () => {
        const url: string = this.router.url;
        if (url === '/user/profile/data') {
          this.store.dispatch(getUser());
        }
        this.privacyPolicyService.destroyPrivacyPolicy$.next(true);
        this.privacyPolicyService.hasUserPrivacyPolicyUpdated$.next(true);
        this.globalModalService.close();
      }
    });
  }
}
