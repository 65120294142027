import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function OnlySpaceValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (value && typeof value !== 'number' && value.trim() === '') {
      return { onlySpaceIsNotAllowed: 'მხოლოდ სფეისები არ არის დაშვებული' };
    }
    return null;
  };
}
