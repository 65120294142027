import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from '../api.service';
import { Guid } from 'guid-typescript';

@Injectable({
  providedIn: 'root'
})
export class MerchantsHttp {
  constructor(private api: ApiService) {}
  getMerchant(merchantName: string): Observable<any> {
    return this.api.get(`api/products/getcategoriesbysearch?userSlug=${merchantName}`, {}, 'cmsURL');
  }

  getExternal(pId: string): Observable<any> {
    return this.api.get('api/customers/legal/external/' + pId, {}, 'customersURL');
  }

  getLegalCustomers(pageNumber: number, pageSize: number, searchValue?): Observable<any> {
    return this.api.get(
      'api/customers/getlegalcustomers',
      {
        page: pageNumber,
        pageSize: pageSize,
        searchValue: searchValue && searchValue
      },
      'customersURL'
    );
  }

  getLegalCustomersByUserId(id: number): Observable<any> {
    return this.api.get(`api/customers/legal/${id}`, {}, 'customersURL').pipe(map(res => res['data']));
  }

  getLegalMerchantByUserId(id: number): Observable<any> {
    return this.api.get(`api/merchants/${id}`, {}, 'cmsURL');
  }

  getLegalMerchantDetailsByUserId(id: number | string): Observable<any> {
    return this.api.get(`api/customers/${id}/details`, {}, 'customersURL');
  }

  // getLegalMerchantDetailsByUserId(id: number): Observable<any> {
  //   return this.api.get(`api/merchants/${id}/details`, {}, 'cmsURL')
  // }

  createMerchantRepresentative(data): Observable<any> {
    return this.api.post('api/Admin/CreateMerchantRepresentative', data, { requestId: this.getGuidId() });
  }

  addLegalCustomer(data): Observable<any> {
    return this.api.post('api/Admin/legal/create', data, { requestId: this.getGuidId() });
  }

  getPaymentMethods(customerIds): Observable<any> {
    return this.api.post(
      'api/customers/paymentmethods/customerids',
      [customerIds],
      { requestId: this.getGuidId() },
      'customersURL'
    );
  }

  changePaymentMethod(paymentType: any, customerId): Observable<any> {
    return this.api.post('api/customers/changepaymentmethod', paymentType, { customerId: customerId }, 'customersURL');
  }

  getUserEmployees(): Observable<any> {
    return this.api.get('api/Admin/User/Employees');
  }

  updateLegalCustomerInfo(data): Observable<any> {
    return this.api.put('api/customers/updatelegal', data, { requestId: this.getGuidId() }, 'customersURL');
  }

  updateLegalCustomerContact(data): Observable<any> {
    return this.api.post('api/Admin/Legal/Update', data, { requestId: this.getGuidId() });
  }

  resetPassword(data): Observable<any> {
    return this.api.post('api/Admin/Legal/ResetPassword', data, { requestId: this.getGuidId() });
  }

  uploadMerchantLogo(data): Observable<any> {
    return this.api.upload('api/upload/image', data, { requestId: this.getGuidId() }, 'customersURL');
  }

  uploadMerchantCover(data): Observable<any> {
    return this.api.upload('api/upload/coverimage', data, { requestId: this.getGuidId() }, 'customersURL');
  }

  uploadMerchantPdf(data): Observable<any> {
    return this.api.upload('api/upload/document', data, { requestId: this.getGuidId() }, 'customersURL');
  }

  uploadMerchantSizeGuide(data): Observable<any> {
    return this.api.upload('api/upload/sizeguidepdf', data, { requestId: this.getGuidId() }, 'customersURL');
  }

  addSizeGuide(data): Observable<any> {
    return this.api.post('api/customers/addsizeguide', data, { requestId: this.getGuidId() }, 'customersURL');
  }

  getSizeGuides(pcustomerId: string, pageNumber: number, pageSize: number): Observable<any> {
    return this.api.get(
      'api/customers/getsizeguides',
      {
        customerId: pcustomerId,
        page: pageNumber,
        pageSize: pageSize
      },
      'customersURL'
    );
  }

  removeSizeGuide(categoryId: string, customerId: string): Observable<any> {
    return this.api.delete(`api/customers/removesizeguide/${categoryId}/${customerId}`, {}, 'customersURL');
  }

  uploadReturnPolicy(data): Observable<any> {
    return this.api.upload('api/upload/returnpolicy', data, { requestId: this.getGuidId() }, 'customersURL');
  }

  replaceReturnPolicy(data): Observable<any> {
    return this.api.put(`api/customers/returnpolicy`, data, {}, 'customersURL');
  }

  removeReturnPolicy(userId): Observable<any> {
    return this.api.delete(`api/customers/${userId}/returnpolicy`, {}, 'customersURL');
  }

  uploadWarranty(data): Observable<any> {
    return this.api.upload('api/upload/guarantee', data, { requestId: this.getGuidId() }, 'customersURL');
  }

  addWarranty(data): Observable<any> {
    return this.api.put(`api/customers/guarantee`, data, {}, 'customersURL');
  }

  removeWarranty(userId): Observable<any> {
    return this.api.delete(`api/customers/${userId}/guarantee`, {}, 'customersURL');
  }

  updateMerchantWorkingHours(data: WorkingHoursObject) {
    return this.api.post('api/customers/merchant/workinghours', data, { requestId: this.getGuidId() }, 'customersURL');
  }

  getMerchantPriorities(): Observable<{ priorities: number[] }> {
    return this.api.get<{ priorities: number[] }>('api/customers/merchant/priorities', {}, 'customersURL');
  }

  private getGuidId(): string {
    return Guid.create().toString();
  }
}

interface WorkingHoursObject {
  userId: string;
  workingHours: WorkingHoursEntry[];
}

interface WorkingHoursEntry {
  workStartHour: string;
  workEndHour: string;
  isAvailable: boolean;
  weekDay: number;
}
