import { Directive, NgModule, ViewContainerRef } from '@angular/core';

@Directive({ selector: '[appDynamicHost]' })
export class DynamicHostDirective {
  constructor(public viewContainerRef: ViewContainerRef) {}
}

export const DIRECTIVES = [DynamicHostDirective];

@NgModule({
  declarations: DIRECTIVES,
  exports: DIRECTIVES
})
export class DynamicHostDirectiveModule {}
