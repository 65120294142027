import { BannerPlaceType } from '../models/banner.model';
import { RibbonModel } from '../models/ribbon.model';

export const RibbonStates: RibbonModel = {
  data: {
    bannerPlaceName: '',
    bannerPlaceId: 0,
    section: 0,
    bannerPlaceType: BannerPlaceType.Ribbon,
    banners: []
  },
  loading: false,
  error: null
};
