import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import {
  getCategoriesSuccess,
  GetCategoryTreeAttempt,
  GetCategoryTreeFail,
  GetCategoryTreeSuccess
} from '../actions/category.action';

const initialState = {
  categoryTree: {},
  categoryTreeLoading: false,
  categoryTreeErrors: [],
  categories: null
};
const categoryReducer = createReducer(
  initialState,
  on(getCategoriesSuccess, (state, action) => ({ ...state, categories: action.categories })),
  on(GetCategoryTreeAttempt, state => ({ ...state, categoryTreeLoading: true, errors: [] })),
  on(GetCategoryTreeSuccess, (state, action) => ({
    ...state,
    categoryTree: { ...state.categoryTree, [action.data.slug]: action.data },
    categoryTreeLoading: false
  })),
  on(GetCategoryTreeFail, (state, action) => ({ ...state, categoryTreeLoading: false, errors: action.error }))
);

export function reducer(state: any, action: Action) {
  return categoryReducer(state, action);
}

export const selectCategoryState = createFeatureSelector<any>('category');
export const selectCategories = createSelector(selectCategoryState, allItems => allItems.categories);
export const selectCategoryTree = createSelector(selectCategoryState, allItems => allItems.categoryTree);
export const selectCategoryTreeLoading = createSelector(selectCategoryState, allItems => allItems.categoryTreeLoading);

// there should be data and loading state selector

export const selectCategoryByID = id =>
  createSelector(selectCategories, items => {
    if (items) {
      return findById(items.data, id);
    }
  });

function findById(obj, id) {
  let result;
  for (let k in obj) {
    if (k == id) {
      return obj[k];
    } else {
      if (typeof obj[k] === 'object') {
        result = findById(obj[k].children, id);
        if (result) {
          return result;
        }
      }
    }
  }
  return result;
}
