<div
  class="_x_fixed _x_left-0 _x_right-0 _x_top-0 _x_z-10 _x_flex _x_h-full _x_max-h-full _x_w-full _x_items-center _x_justify-center _x_overflow-x-hidden _x_overflow-y-hidden _x_p-4">
  <div
    class="_x_fixed _x_left-0 _x_right-0 _x_top-0 _x_z-10 _x_h-full _x_w-full _x_bg-dark-600"
    (click)="closeModal()"></div>
  <div
    class="_x_absolute _x_bottom-auto _x_z-10 _x_mx-8 _x_h-20 _x_h-auto _x_max-h-full _x_max-w-200 _x_translate-y-0 _x_transform _x_overflow-hidden _x_rounded-12 _x_bg-white">
    <div class="_x_relative _x_rounded-t-12 _x_bg-white sm:_x_w-full">
      <div class="_x_flex _x_items-center _x_justify-between _x_border-b _x_border-gray _x_p-8">
        <div class="_x_flex _x_w-full _x_items-center _x_justify-between">
          <p class="_x_font-bold _x_text-5">გაფრთხილება</p>
          <i
            (click)="closeModal()"
            class="_x_icon _x_icon-remove _x_flex _x_h-12 _x_max-h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_cursor-pointer _x_items-center _x_justify-center _x_text-7 _x_text-black"></i>
        </div>
      </div>
      <div class="_x_p-8">
        <p class="_x_font-medium _x_text-3 _x_text-dark">
          {{ 'მითითებულ მისამართზე ექსპრეს მიწოდება ვერ ხერხდება, გთხოვ შეცვალე მისამართი.' }}
        </p>
      </div>
      <div class="_x_mb-8 _x_flex _x_flex-col-reverse _x_gap-4 _x_px-8 _x_pt-4 sm:_x_flex-row">
        <button
          (click)="openAddressPopup()"
          class="_x_h-24 _x_w-full _x_rounded-8 _x_bg-purple _x_font-bold _x_text-3 _x_text-white hover:_x_bg-purple-100 hover:_x_text-purple">
          მისამართის შეცვლა
        </button>
      </div>
    </div>
  </div>
</div>
