import { createAction, props } from '@ngrx/store';
import { UserModel } from '../models/user.model';

export enum UserActionsTypes {
  GetUser = '[USER] GetUser',
  GetUserSuccess = '[USER] GetUserSuccess',
  RemoveUser = '[USER] RemoveUser'
}

export const getUser = createAction(UserActionsTypes.GetUser);
export const getUserSuccess = createAction(UserActionsTypes.GetUserSuccess, props<UserModel>());
export const removeUser = createAction(UserActionsTypes.RemoveUser);
