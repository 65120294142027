import { environment } from '../../environments/environment';
import { darkStore } from '../services/express.service';

let darkStoreData: darkStore;

if (environment.develop) {
  darkStoreData = {
    darkStoreId: null,
    defaultDarkStoreId: 23,
    districtId: null,
    isDefault: false
  };
} else if (environment.staging) {
  darkStoreData = {
    darkStoreId: null,
    defaultDarkStoreId: 23,
    districtId: null,
    isDefault: false
  };
} else if (environment.production) {
  darkStoreData = {
    darkStoreId: null,
    defaultDarkStoreId: 91,
    districtId: null,
    isDefault: false
  };
}
export const DARK_STORE_DATA = darkStoreData;
export const DARK_STORE_DATA_KEY = 'darkStoreData';
export const UNAUTHORIZED_USER_ADDRESS = 'unauthorizedUserAddress';
