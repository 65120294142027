import { createAction, props } from '@ngrx/store';
import { SidebarModel } from '../models/layout.model';

export enum SidebarActionsTypes {
  SidebarOpen = '[LAYOUT] SidebarOpen',
  SidebarClose = '[LAYOUT] SidebarClose',
  SearchToggle = '[LAYOUT] SearchToggle',
  SearchToggleClose = '[LAYOUT] SearchToggleClose',
  SetPreviousUrl = '[LAYOUT] SetPreviousUrl',
  SetLoad = '[LAYOUT] SetLoad',
  SetIsAdult = '[LAYOUT] SetIsAdult',
  MobileFilterState = '[LAYOUT] IsMobileFilterState'
}

export const sidebarOpen = createAction(SidebarActionsTypes.SidebarOpen, props<SidebarModel>());
export const sidebarClose = createAction(SidebarActionsTypes.SidebarClose, props<SidebarModel>());

export const searchToggle = createAction(SidebarActionsTypes.SearchToggle);
export const searchToggleClose = createAction(SidebarActionsTypes.SearchToggleClose);

export const setPreviousUrl = createAction(SidebarActionsTypes.SetPreviousUrl, props<SidebarModel>());
export const setLoad = createAction(SidebarActionsTypes.SetLoad);

export const setMobileFilterState = createAction(SidebarActionsTypes.MobileFilterState, props<{ open: boolean }>());

export const setIsAdult = createAction(SidebarActionsTypes.SetIsAdult, props<SidebarModel>());
