import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '../api.service';
import { Guid } from 'guid-typescript';

@Injectable({
  providedIn: 'root'
})
export class MarketingHttp {
  constructor(private api: ApiService) {}

  // POP UPS CONTROLLER
  getPopUps(data): Observable<any> {
    return this.api.get('api/popups/admin-panel', data, 'marketingURL');
  }

  uploadPopUpImage(data): Observable<any> {
    return this.api.upload('api/popups/upload-image', data, { requestId: this.getGuidId() }, 'marketingURL');
  }

  createPopUp(data): Observable<any> {
    return this.api.post('api/popups', data, { requestId: this.getGuidId() }, 'marketingURL');
  }

  removePopUp(id): Observable<any> {
    return this.api.delete(`api/popups/${id}`, {}, 'marketingURL');
  }

  updatePopup(id, data): Observable<any> {
    return this.api.put(`api/popups/${id}`, data, { requestId: this.getGuidId() }, 'marketingURL');
  }

  // End POP UPs CONTROLLER
  changeSortIndex(data): Observable<any> {
    return this.api.put('api/banners/updatebannersortindex', data, { requestId: this.getGuidId() }, 'marketingURL');
  }

  uploadBannerImage(data): Observable<any> {
    return this.api.upload('api/banners/uploadbannerimage', data, { requestId: this.getGuidId() }, 'marketingURL');
  }

  getStaticPages(data): Observable<any> {
    return this.api.get('api/staticpages/static-pages', data, 'marketingURL');
  }

  getDynamicBannerPlaces(data): Observable<any> {
    return this.api.get(
      `api/banners/dynamic-bannerplace?OwnerLink=${encodeURIComponent(data.OwnerLink)}`,
      {},
      'marketingURL'
    );
  }

  getDynamicBannerPlacesForExpressPage(data): Observable<any> {
    return this.api.get(
      `api/banners/dynamic-bannerplaces?OwnerLink=${encodeURIComponent(data.OwnerLink)}`,
      {},
      'marketingURL'
    );
  }

  getStaticPageGroups(data): Observable<any> {
    return this.api.get('api/staticpages/static-page-groups', data, 'marketingURL');
  }

  getStaticPageDetail(data): Observable<any> {
    return this.api.get('api/staticpages/static-page-details', data, 'marketingURL');
  }

  postStaticPage(data): Observable<any> {
    return this.api.post('api/staticpages/createstaticpage', data, { requestId: this.getGuidId() }, 'marketingURL');
  }

  postStaticPageGroup(data): Observable<any> {
    return this.api.post(
      'api/staticpages/createstaticpagegroup',
      data,
      { requestId: this.getGuidId() },
      'marketingURL'
    );
  }

  putStaticPage(data): Observable<any> {
    return this.api.put('api/staticpages/updatestaticpage', data, { requestId: this.getGuidId() }, 'marketingURL');
  }

  putStaticPageGroup(data): Observable<any> {
    return this.api.put('api/staticpages/updatestaticpagegroup', data, { requestId: this.getGuidId() }, 'marketingURL');
  }

  getBannerPlaces(params: any) {
    return this.api.get('api/banners/bannerplaces', params, 'marketingURL');
  }
  getBannerDetails(param: any) {
    return this.api.get('api/banners/details', param, 'marketingURL');
  }
  getBannerPlaceDetails(params: any) {
    return this.api.get('api/banners/bannerplace-details', params, 'marketingURL');
  }

  getSectionDetails(pId: string) {
    return this.api.get('api/section/section-details', { id: pId }, 'marketingURL');
  }

  putSections(data): Observable<any> {
    return this.api.put('api/section/changesection', data, {}, 'marketingURL');
  }

  postCreateSection(data): Observable<any> {
    return this.api.post('api/section/createsection', data, { requestId: this.getGuidId() }, 'marketingURL');
  }
  deleteSection(id): Observable<any> {
    return this.api.delete(
      'api/section/section',
      {
        sectionId: id
      },
      'marketingURL'
    );
  }

  getFooterStaticPages(): Observable<any> {
    return this.api.get('api/staticpages/static-pages-and-groups', {}, 'marketingURL');
  }

  getFooterStaticPageDescription(url: string): Observable<any> {
    return this.api.get('api/staticpages/static-page-description', { url: url }, 'marketingURL', 'text/html');
  }

  getStaticPageDetails(url: string): Observable<any> {
    return this.api.get('api/staticpages/static-page-details-web', { url }, 'marketingURL');
  }

  removeBannerPlace(id): Observable<any> {
    return this.api.delete(
      'api/banners/deletebannerplace',
      {
        requestId: this.getGuidId(),
        bannerPlaceId: id
      },
      'marketingURL'
    );
  }

  deleteStaticPageGroup(id): Observable<any> {
    return this.api.delete(
      'api/staticpages/removestaticpagegroup',
      {
        StaticPageGroupId: id
      },
      'marketingURL'
    );
  }

  deleteStaticPage(id): Observable<any> {
    return this.api.delete(
      'api/staticpages/removestaticpage',
      {
        id: id
      },
      'marketingURL'
    );
  }

  removeBanner(bannerPlaceId, bannerId): Observable<any> {
    return this.api.delete(
      'api/banners/removebanner',
      {
        requestId: this.getGuidId(),
        bannerPlaceId: bannerPlaceId,
        bannerId: bannerId
      },
      'marketingURL'
    );
  }

  changeStatus(data): Observable<any> {
    return this.api.post('api/banners/changebannerstatus', data, { requestId: this.getGuidId() }, 'marketingURL');
  }

  changeBannerPlaceOwner(data): Observable<any> {
    return this.api.put('api/banners/change-bannerplace-owner', data, { requestId: this.getGuidId() }, 'marketingURL');
  }

  createBannerPlace(data): Observable<any> {
    return this.api.post('api/banners/createbannerplace', data, { requestId: this.getGuidId() }, 'marketingURL');
  }

  addBanner(data): Observable<any> {
    return this.api.post('api/banners/addbanner', data, { requestId: this.getGuidId() }, 'marketingURL');
  }

  updateBanner(data): Observable<any> {
    return this.api.put('api/banners/updatebanner', data, { requestId: this.getGuidId() }, 'marketingURL');
  }

  getLandingPages(params: any) {
    return this.api.get('api/landingpages', params, 'marketingURL');
  }

  getLandingPage(id: any) {
    return this.api.get(`api/landingpages/${id}`, {}, 'marketingURL');
  }

  getLandingPageProducts(id: any, params: any) {
    return this.api.get(`api/landingpages/${id}/products`, params, 'marketingURL');
  }

  getLandingPageSliders() {
    return this.api.get('api/banners/bannerplaces', {}, 'marketingURL');
  }

  getRibbon() {
    return this.api.get('api/banners/ribbon', {}, 'marketingURL');
  }

  removeLandingPage(id): Observable<any> {
    return this.api.delete(
      'api/landingpages',
      {
        requestId: this.getGuidId(),
        id: id
      },
      'marketingURL'
    );
  }

  getPopups(type): Observable<any> {
    return this.api.get('api/popups', { type: type }, 'marketingURL');
  }

  sendEmail(data) {
    return this.api.post('api/popups/send-email', data, { requestId: this.getGuidId() }, 'marketingURL');
  }

  getSections() {
    return this.api.get('api/section/sections', {}, 'marketingURL');
  }

  getSectionsAdmin(pageNumber?: number, pageSize?: number) {
    return this.api.get(
      'api/section/sections-admin',
      {
        Page: pageNumber,
        pageSize: pageSize
      },
      'marketingURL'
    );
  }

  updateLandingPage(data): Observable<any> {
    return this.api.put('api/landingpages', data, { requestId: this.getGuidId() }, 'marketingURL');
  }

  addLandingPage(data): Observable<any> {
    return this.api.post('api/landingpages', data, { requestId: this.getGuidId() }, 'marketingURL');
  }

  removeProductFromLanding(id, data): Observable<any> {
    return this.api.post(
      `api/landingpages/${id}/products/remove`,
      data,
      { requestId: this.getGuidId() },
      'marketingURL'
    );
  }

  importProducts(id, data): Observable<any> {
    return this.api.upload(
      `api/landingpages/${id}/products/import`,
      data,
      { requestId: this.getGuidId() },
      'marketingURL'
    );
  }

  uploadSectionImage(data): Observable<any> {
    return this.api.upload('api/section/uploadimage', data, { requestId: this.getGuidId() }, 'marketingURL');
  }

  deleteSectionImage(data): Observable<any> {
    return this.api.delete(
      `api/section/image`,
      {
        WebFileName: data?.WebFileName ? data?.WebFileName : null,
        MobileFileName: data?.MobileFileName ? data?.MobileFileName : null,
        SectionId: data.SectionId
      },
      'marketingURL'
    );
  }
  updateSortIndex(data): Observable<any> {
    return this.api.put('api/section/sort-index', data, { id: data.id, sortIndex: data.sortIndex }, 'marketingURL');
  }

  private getGuidId(): string {
    return Guid.create().toString();
  }
}
