import { createAction, props } from '@ngrx/store';

export enum ComparisonActionsTypes {
  GetComparison = '[COMPARISON] GetComparison',
  GetComparisonSuccess = '[COMPARISON] GetComparison Success',
  GetComparisonFail = '[COMPARISON] GetComparison Fail',
  UpdateComparison = '[COMPARISON] UpdateComparison',
  UpdateComparisonSuccess = '[COMPARISON] UpdateComparison Success',
  UpdateComparisonFail = '[COMPARISON] UpdateComparison Fail',
  DeleteComparison = '[COMPARISON] DeleteComparison',
  DeleteComparisonSuccess = '[COMPARISON] DeleteComparisonSuccess',
  DeleteComparisonFailed = '[COMPARISON] DeleteComparisonFailed',
  ClearComparison = '[COMPARISON] ClearComparison',
  ClearComparisonSuccess = '[COMPARISON] ClearComparisonSuccess',
  CheckComparisonProducts = '[COMPARISON] Check Local Comparison'
}

export const getComparison = createAction(ComparisonActionsTypes.GetComparison);
export const getComparisonSuccess = createAction(ComparisonActionsTypes.GetComparisonSuccess, props<any>());
export const getComparisonFail = createAction(ComparisonActionsTypes.GetComparisonFail, props<any>());

export const checkComparisonProducts = createAction(ComparisonActionsTypes.CheckComparisonProducts);

export const updateComparison = createAction(
  ComparisonActionsTypes.UpdateComparison,
  props<{ productId: number; categoryId: number }>()
);
export const updateComparisonSuccess = createAction(ComparisonActionsTypes.UpdateComparisonSuccess);
export const updateComparisonFail = createAction(ComparisonActionsTypes.UpdateComparisonFail, props<any>());

export const deleteComparison = createAction(
  ComparisonActionsTypes.DeleteComparison,
  props<{ productId: number; isComparisonPage: boolean }>()
);
export const deleteComparisonSuccess = createAction(ComparisonActionsTypes.DeleteComparisonSuccess, props<any>());
export const deleteComparisonFail = createAction(ComparisonActionsTypes.DeleteComparisonFailed, props<any>());

export const clearComparison = createAction(ComparisonActionsTypes.ClearComparison);
export const clearComparisonSuccess = createAction(
  ComparisonActionsTypes.ClearComparisonSuccess,
  props<{ productIds: number[] }>()
);
