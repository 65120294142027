<!--<div class="w-100 bg-danger position-fixed d-flex justify-content-center align-items-center" (click)="popupService.close()" *ngIf="popupService.event$ | async as popup;" style="top: 0; height: 100vh;">
  <div class="bg-white w-50" (click)="$event.stopPropagation();">
    <button class="" (click)="popupService.close()">asdexit</button>
    <ng-container *ngTemplateOutlet="popup.template"></ng-container>
  </div>
</div>-->

<!-- Modal -->
<div
  class="modal fade show"
  id="exampleModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
  (click)="close()"
  *ngIf="popupService.event$ | async as popup">
  <div class="modal-dialog modal-dialog-centered _x_justify-center" role="document">
    <!--small-->
    <div class="modal-content _x_max-w-232 _x_overflow-hidden _x_rounded-14" (click)="$event.stopPropagation()">
      <!--<div class="modal-header">
        <button type="button" class="close bg-rebeccapurple-10 icon-rebeccapurple" data-dismiss="modal" aria-label="Close" (click)="popupService.close()">
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><path class="a" d="M4.43,3.293,3.293,4.43l5.863,5.863L3.293,16.156,4.43,17.293l5.863-5.863,5.863,5.863,1.137-1.137L11.43,10.293,17.293,4.43,16.156,3.293,10.293,9.156Z" transform="translate(-3.293 -3.293)"/></svg>
        </button>
      </div>-->
      <div class="modal-body _x_p-0" [ngClass]="bodyClass || ''">
        <div
          *ngIf="!hideCloseButton"
          class="_s_position-right-px--0 _x_absolute _x_top-0 _x_flex _x_w-full _x_justify-end _x_pl-15 _x_pr-15 _x_pt-15 sm:_x_flex">
          <button
            style="margin-right: 0px !important"
            type="button"
            class="_s_size-w-px--12 _x_ml-auto _x_flex _x_justify-end"
            data-dismiss="modal"
            aria-label="Close"
            (click)="popupService.close()"
            [ngClass]="closeButtonClass || ''">
            <i
              class="_x_align-center _x_icon _x_icon-remove _x_flex _x_h-10 _x_min-h-10 _x_w-10 _x_min-w-10 _x_justify-center _x_text-6"
              *ngIf="!customSvgPath"></i>
            <i
              class="_x_align-center _x_icon _x_icon-remove _x_flex _x_h-10 _x_min-h-10 _x_w-10 _x_min-w-10 _x_justify-center _x_text-6"
              *ngIf="customSvgPath"></i>
          </button>
        </div>
        <ng-container *ngTemplateOutlet="popup.template"></ng-container>
      </div>
    </div>
  </div>
</div>
<div class="modal-backdrop show" (click)="popupService.close()" *ngIf="popupService.event$ | async"></div>
