import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  AllEMployeeProfile,
  EmployeeProfileCreateBody,
  EmployeeProfileEditBody,
  EmployeeProfileInfo,
  EmployeeProfileOrdersParameters,
  VacationEditBody
} from '../../models/employee-profile.model';
import { OrdersParameters, OrdersResponse } from '../../models/orders.model';

import { ApiService } from '../api.service';
import { Guid } from 'guid-typescript';
import { VoucherBody, VoucherDetail, VoucherValidate } from '../../models/vouchers.model';
import {
  CreateSlaConfigurationOption,
  DeleteSlaConfigurationOption,
  SlaConfigurationOptions,
  SlaList,
  UpdateSlaConfigurationOption
} from '../../models/sla.model';

@Injectable({
  providedIn: 'root'
})
export class OrderingHttp {
  constructor(private api: ApiService) {}

  // SHIPPING OPTIONS CONTROLLERS
  createShippingOptions(data): Observable<any> {
    return this.api.post(`api/shippingoptions`, data, { requestId: this.getGuidId() }, 'orderingURL');
  }

  updateShippingOptions(data): Observable<any> {
    return this.api.put('api/shippingoptions', data, { requestId: this.getGuidId() }, 'orderingURL');
  }

  getShippingOptions(): Observable<any> {
    return this.api.get('api/shippingoptions', {}, 'orderingURL');
  }

  deleteShippingOption(id): Observable<any> {
    return this.api.delete(`api/shippingoptions/${id}`, { requestId: this.getGuidId() }, 'orderingURL');
  }

  getShippingOptionsById(id: any): Observable<any> {
    return this.api.get(`api/shippingoptions/${id}`, {}, 'orderingURL');
  }

  sendOrderExternalIdForAdmin(data): Observable<any> {
    return this.api.post('api/orders/send-lost-order', data, { requestId: this.getGuidId() }, 'orderingURL');
  }

  IziBoxFailedOrderSend(data): Observable<any> {
    return this.api.post(
      `api/IziBoxIntegration/failed/order/send`,
      data,
      { requestId: this.getGuidId() },
      'deliveryIntegrationApi'
    );
  }

  changeOrderGeneralStatusForAdmin(data): Observable<any> {
    return this.api.put(
      'api/orders/order/general/status/for/admin',
      data,
      { requestId: this.getGuidId() },
      'orderingURL'
    );
  }

  shippingOptionChangeActivation(data: { id: string; status: number }): Observable<any> {
    return this.api.patch(
      `api/shippingoptions/${data.id}/status`,
      data.status,
      { requestId: this.getGuidId() },
      'orderingURL'
    );
  }

  shippingPackagesChangeActivation(data): Observable<any> {
    return this.api.put('api/shippingpackages/change-activation', data, { requestId: this.getGuidId() }, 'orderingURL');
  }

  //END OF SHIPPING OPTIONS CONTROLLERS

  getPickUpPointsLocationsForCheckout(params): Observable<any> {
    return this.api.get('api/pickuppoints/locations/checkout', params, 'orderingURL');
  }

  // START OF PICKUPPOINTS
  createPickUpPoints(data: PickUpPointsCreate): Observable<any> {
    return this.api.post(`api/pickuppoints`, data, { requestId: this.getGuidId() }, 'orderingURL');
  }

  updatePickUpStatus(id: string, value): Observable<any> {
    return this.api.patch<any>(`api/pickuppoints/${id}/status`, value, { requestId: this.getGuidId() }, 'orderingURL');
  }

  pickupPointsSync(): Observable<any> {
    return this.api.post('api/pickuppoints/sync/inex', {}, { requestId: this.getGuidId() }, 'orderingURL');
  }

  updatePickUpPoints(data: PickUpPointsUpdate): Observable<any> {
    return this.api.put('api/pickuppoints', data, { requestId: this.getGuidId() }, 'orderingURL');
  }

  deletePickUpPoints(id: number): Observable<any> {
    return this.api.delete(`api/pickuppoints`, { Id: id, requestId: this.getGuidId() }, 'orderingURL');
  }

  getPickUpPoints(params: PickUpPoints): Observable<any> {
    return this.api.get('api/pickuppoints', params, 'orderingURL');
  }

  getPickUpPointById(id: number): Observable<any> {
    return this.api.get(`api/pickuppoints/${id}`, {}, 'orderingURL');
  }

  getPickUpPointsLocations(): Observable<any> {
    return this.api.get('api/pickuppoints/locations', {}, 'orderingURL');
  }
  // END OF PICKUPPOINTS

  changePromoCodeStatus(promoCodeId, status): Observable<any> {
    return this.api.put(
      'api/promocodes/changepromocodestatus',
      {
        promoCodeId: promoCodeId,
        status: status
      },
      { requestId: this.getGuidId() },
      'orderingURL'
    );
  }

  getReportItem(params): Observable<any> {
    return this.api.get('api/reports/order_items', params, 'orderingURL', 'blob');
  }

  updatePromoCode(data): Observable<any> {
    return this.api.put('api/promocodes/updatepromocode', data, { requestId: this.getGuidId() }, 'orderingURL');
  }

  orderDeliveryTypeChange(data): Observable<any> {
    return this.api.put('api/orders/order-deliverytype-change', data, { requestId: this.getGuidId() }, 'orderingURL');
  }

  orderCancelationReasonChange(data): Observable<any> {
    return this.api.put('api/orders/change-order-cancel-reason', data, { requestId: this.getGuidId() }, 'orderingURL');
  }

  changeMerchantAddressForOrder(data): Observable<any> {
    return this.api.put(
      'api/orders/change-merchantaddress-for-order',
      data,
      { requestId: this.getGuidId() },
      'orderingURL'
    );
  }

  changeMerchantAddressForOrderItem(data): Observable<any> {
    return this.api.put('api/orders/merchant/address/orderitem', data, { requestId: this.getGuidId() }, 'orderingURL');
  }

  changeOrderItemComment(data): Observable<any> {
    return this.api.put('api/orders/orderitem/comment', data, { requestId: this.getGuidId() }, 'orderingURL');
  }

  changeOrderLineComment(data): Observable<any> {
    return this.api.put('api/orders/change-orderline-comment', data, { requestId: this.getGuidId() }, 'orderingURL');
  }

  generatePromoCode(data): Observable<any> {
    return this.api.post(`api/promocodes/generatepromocode`, data, { requestId: this.getGuidId() }, 'orderingURL');
  }

  getPromoCodeById(id: any): Observable<any> {
    return this.api.get(`api/promocodes/promocode-details/${id}`, {}, 'orderingURL');
  }

  getPromoCodeProducts(data): Observable<any> {
    return this.api.get(`api/promocodes/${data.Id}/products`, data, 'orderingURL');
  }

  getRefferal(): Observable<any> {
    return this.api.get(`api/referral`, { requestId: this.getGuidId() }, 'customersURL');
  }

  getRefferers(params): Observable<any> {
    return this.api.get(`api/referral/customer-benefits-admin`, params, 'customersURL');
  }

  getCostumerBenefits(): Observable<any> {
    return this.api.get(`api/referral/customer-benefits`, { requestId: this.getGuidId() }, 'customersURL');
  }

  getRefferersDetails(id: number): Observable<any> {
    return this.api.get(`api/referral/customer-benefits-admin/${id}`, { requestId: this.getGuidId() }, 'customersURL');
  }

  updateRefferal(data): Observable<any> {
    return this.api.put('api/referral', data, { requestId: this.getGuidId() }, 'customersURL');
  }

  reciveBenefit(data: object): Observable<any> {
    return this.api.post(`api/referral/receivemilestone`, data, { requestId: this.getGuidId() }, 'customersURL');
  }

  addBenefit(data): Observable<any> {
    return this.api.post('api/referral/addmilestone', data, { requestId: this.getGuidId() }, 'customersURL');
  }

  updateBenefit(data): Observable<any> {
    return this.api.put('api/referral/updatemilestone', data, { requestId: this.getGuidId() }, 'customersURL');
  }

  deletBenefit(id): Observable<any> {
    return this.api.delete(`api/referral/removemilestone/${id}`, { requestId: this.getGuidId() }, 'customersURL');
  }

  promoCodeImportProducts(id, data): Observable<any> {
    return this.api.upload(`api/promocodes/${id}/products`, data, { requestId: this.getGuidId() }, 'orderingURL');
  }

  deleteAllPromoCodeItems(id): Observable<any> {
    return this.api.delete(`api/promocodes/${id}/products/clear`, { requestId: this.getGuidId() }, 'orderingURL');
  }

  deletePromoCodeItems(data): Observable<any> {
    return this.api.delete(
      `api/promocodes/${data.Id}/products`,
      {
        productIds: data.productIds,
        requestId: this.getGuidId()
      },
      'orderingURL'
    );
  }

  getOrderProductViews(params): Observable<any> {
    return this.api.get(`api/orders/getorderproductsview`, params, 'orderingURL');
  }

  getMerchantHasActiveOrder(params: any): Observable<any> {
    return this.api.get(`api/merchants/has-active-order`, params, 'orderingURL');
  }

  orderChangeHistory(data): Observable<any> {
    return this.api.post(`api/orders/order-change-histories`, data, { requestId: this.getGuidId() }, 'orderingURL');
  }

  cashOnHandPhoneNumberApproval(data: { orderId: string; phoneNumber: string }): Observable<any> {
    return this.api.post(`api/orders/approve/phonenumber`, data, { requestId: this.getGuidId() }, 'orderingURL');
  }

  verifyCashOnHandOrder(data: { orderId: string; otp: string | number }): Observable<any> {
    return this.api.put(`api/orderverification/verify`, data, { requestId: this.getGuidId() }, 'emailUrl');
  }

  getOrderVerificationOtp(params: { orderId: string; otp: number }): Observable<any> {
    return this.api.get('api/orderverification/otp', params, 'emailUrl');
  }

  getPromoCodes(params: any): Observable<any> {
    return this.api.get('api/promocodes/getpromocodes', params, 'orderingURL');
  }

  getOrderLineStatusHistory(params: any): Observable<any> {
    return this.api.get('api/orders/getorderlinestatuschangehistory', params, 'orderingURL');
  }

  getReservationStatusHistory(params: any): Observable<any> {
    return this.api.get(`api/orderreservation/reservation-order-line-status-change-histories`, params, 'orderingURL');
  }

  getOrders(params: Partial<OrdersParameters>): Observable<any> {
    return this.api.get('api/orders', params, 'orderingURL');
  }

  getPromoCodeValidation(promoCode: any, data: any): Observable<any> {
    return this.api.get(`api/promocodes/promocode-validation/${encodeURIComponent(promoCode)}`, data, 'orderingURL');
  }

  importShippingPackages(data): Observable<any> {
    return this.api.upload(
      `api/shippingpackages/import-multiple`,
      data,
      { requestId: this.getGuidId() },
      'orderingURL'
    );
  }

  getOrderInvoice(id): Observable<any> {
    return this.api.get(`api/orders/order-invoice`, id, 'orderingURL');
  }

  getShippingPackagesTemplate(): Observable<any> {
    return this.api.get('api/shippingpackages/import-template', {}, 'orderingURL', 'blob');
  }

  updateOrderMultiple(data): Observable<any> {
    return this.api.upload('api/orders/update-order-multiple', data, { requestId: this.getGuidId() }, 'orderingURL');
  }

  addMultiplePromoCode(data): Observable<any> {
    return this.api.upload('api/promocodes/addmultiplepromocode', data, { requestId: this.getGuidId() }, 'orderingURL');
  }

  getCustomerOrders(data): Observable<any> {
    return this.api.get('api/orders/for-customer-web', data, 'orderingURL');
  }

  fetchCustomerSingleOrder(id: string): Observable<any> {
    return this.api.get(`api/orders/${id}/for-customer`, {}, 'orderingURL').pipe(map(response => response?.data));
  }

  getReport(data): Observable<any> {
    return this.api.get('api/reports/orders', data, 'orderingURL', 'blob');
  }

  updateOrders(data): Observable<any> {
    let gUID = this.getGuidId();
    data.correlationId = gUID;
    return this.api.put('api/orders/update-order', data, { requestId: this.getGuidId() }, 'orderingURL');
  }

  changeOrderComment(data): Observable<any> {
    return this.api.put('api/orders/change-order-comment', data, { requestId: this.getGuidId() }, 'orderingURL');
  }

  changeOrderDeliveryMethod(data): Observable<any> {
    return this.api.put('api/orders/change-order-deliverymethod', data, { requestId: this.getGuidId() }, 'orderingURL');
  }

  changeOrderPaymentType(data): Observable<any> {
    return this.api.put('api/orders/change-order-paymenttype', data, { requestId: this.getGuidId() }, 'orderingURL');
  }

  changeOrderExtraCash(data): Observable<any> {
    return this.api.put('api/orders/change-order-extracash', data, { requestId: this.getGuidId() }, 'orderingURL');
  }

  deleteOrderProduct(id, orderId): Observable<any> {
    return this.api.delete(
      `api/orders/product/delete/${id}/${orderId}`,
      {
        requestId: this.getGuidId(),
        Id: id
      },
      'orderingURL'
    );
  }

  deleteOrderItem(orderId, orderLineId, orderItemId, darkStoreId): Observable<any> {
    return this.api.delete(
      `api/orders/orderlines/orderitem`,
      {
        requestId: this.getGuidId(),
        orderId,
        orderLineId,
        orderItemId,
        darkStoreId
      },
      'orderingURL'
    );
  }

  addOrderItem(orderId, orderLineId, darkStoreId): Observable<any> {
    return this.api.post(
      `api/orders/orderlines/orderitem`,
      {
        orderId,
        orderLineId,
        darkStoreId
      },
      { requestId: this.getGuidId() },
      'orderingURL'
    );
  }

  getDraft(): Observable<any> {
    return this.api.get('api/orders/last-order-details', {}, 'orderingURL');
  }

  getOrderById(id): Observable<any> {
    return this.api.get('api/orders/' + id, {}, 'orderingURL').pipe(map(res => res.data));
  }

  getOrderLines(id): Observable<any> {
    return this.api.get(`api/orders/${id}/order-lines`, {}, 'orderingURL');
  }

  // changeOrderStatus(orderId, status): Observable<any> {
  //   return this.api.put('api/orders/change-order-status', {
  //     orderId: orderId,
  //     status: status
  //   }, { requestId: this.getGuidId() }, 'orderingURL');
  // }
  changeOrderLineGeneralStatus(orderId, status, productId): Observable<any> {
    return this.api.put(
      'api/orders/orderLine-returned',
      {
        orderId: orderId,
        orderLineGeneralStatus: status,
        productId
      },
      { requestId: this.getGuidId() },
      'orderingURL'
    );
  }

  changeOrderLineGeneralStatusNew(orderId, status, orderLineId): Observable<any> {
    return this.api.put(
      'api/orders/orderLine/general/status',
      {
        orderId: orderId,
        orderLineGeneralStatus: status,
        orderLineId
      },
      { requestId: this.getGuidId() },
      'orderingURL'
    );
  }

  changeOrderItemGeneralStatus(orderId, status, orderLineId, orderItemId): Observable<any> {
    return this.api.put(
      'api/orders/orderitem/general/status',
      {
        orderId: orderId,
        orderItemGeneralStatus: status,
        orderLineId,
        orderItemId
      },
      { requestId: this.getGuidId() },
      'orderingURL'
    );
  }

  // changeOrderLineStatus(orderId, productId, orderLineStatus): Observable<any> {
  //   return this.api.put('api/orders/change-order-productstatus', {
  //     orderId: orderId,
  //     productId: productId,
  //     orderLineStatus: orderLineStatus
  //   }, { requestId: this.getGuidId() }, 'orderingURL');
  // }
  changeOrderGeneralStatus(orderId, orderGeneralStatus, orderCancellationReason?): Observable<any> {
    return this.api.put(
      'api/orders/change-order-general-status',
      {
        orderId,
        orderGeneralStatus,
        orderCancellationReason
      },
      { requestId: this.getGuidId() },
      'orderingURL'
    );
  }

  multipleChangeOderItemReservationStatus(params: any[]): Observable<any> {
    return this.api.put(
      `api/orderreservation/reservation/orderline/status/multiple`,
      { reservationOrderLines: params },
      { requestId: this.getGuidId() },
      'orderingURL'
    );
  }

  changeReservationStatus(params: any): Observable<any> {
    return this.api.put(
      `api/orderreservation/change-reservation-status`,
      params,
      { requestId: this.getGuidId() },
      'orderingURL'
    );
  }

  changeOrderItemReservationStatus(params: any): Observable<any> {
    return this.api.put(
      `api/orderreservation/reservation/orderitem/status`,
      params,
      { requestId: this.getGuidId() },
      'orderingURL'
    );
  }

  changeMerchantWillPickUpStatus(params: any): Observable<any> {
    return this.api.put(
      `api/orders/change-merchant-will-pickup`,
      params,
      { requestId: this.getGuidId() },
      'orderingURL'
    );
  }

  changeMerchantWillPickUpOrderItemStatus(params: any): Observable<any> {
    return this.api.put(
      `api/orders/orderitem/merchant/will/pickup`,
      params,
      { requestId: this.getGuidId() },
      'orderingURL'
    );
  }

  changeOrderBankInstalment(orderId, bankOfInstalments): Observable<any> {
    return this.api.put(
      'api/orders/change-order-bank-of-instalments',
      {
        orderId: orderId,
        bankOfInstalments: bankOfInstalments
      },
      { requestId: this.getGuidId() },
      'orderingURL'
    );
  }

  postOrders(data, params): Observable<any> {
    return this.api.post('api/orders', data, { ...params }, 'orderingURL');
  }

  addTop(data): Observable<any> {
    return this.api.post(
      'api/premiumserviceorders/premium-service',
      data,
      { requestId: this.getGuidId() },
      'orderingURL'
    );
  }

  changeOrderStatusMultiple(data): Observable<any> {
    return this.api.post(
      'api/orders/change-order-status-multiple',
      data,
      { requestId: this.getGuidId() },
      'orderingURL'
    );
  }

  orderReversal(data): Observable<any> {
    return this.api.post(`v1/payment/order/reversal`, data, { requestId: this.getGuidId() }, 'paymentURL');
  }

  addOrderProduct(orderId, data): Observable<any> {
    return this.api.post(`api/orders/${orderId}/product/add`, data, { requestId: this.getGuidId() }, 'orderingURL');
  }

  payTradeInShipping(orderId, returnUrl): Observable<any> {
    return this.api.post(
      `v1/payment/order/shipping-service`,
      {
        orderId,
        returnUrl
      },
      { requestId: this.getGuidId() },
      'paymentURL'
    );
  }

  getShippingPackages(params): Observable<any> {
    return this.api.get('api/shippingpackages', params, 'orderingURL');
  }

  postShippingPackages(data): Observable<any> {
    return this.api.post(`api/shippingpackages`, data, { requestId: this.getGuidId() }, 'orderingURL');
  }

  putShippingPackages(params): Observable<any> {
    return this.api.put('api/shippingpackages', params, { requestId: this.getGuidId() }, 'orderingURL');
  }

  deleteShippingPackages(id): Observable<any> {
    return this.api.delete(`api/shippingpackages`, { requestId: this.getGuidId(), Id: id }, 'orderingURL');
  }

  getShippingPackagesById(id): Observable<any> {
    return this.api.get(`api/shippingpackages/${id}`, {}, 'orderingURL');
  }

  getLocations(params): Observable<any> {
    return this.api.get(`api/locations`, params, 'orderingURL');
  }

  updateLocations(id: number, deliveryDays: number): Observable<any> {
    return this.api.put(
      `api/locations/DeliveryDate`,
      {
        id: id,
        deliveryDays: deliveryDays
      },
      { requestId: this.getGuidId() },
      'orderingURL'
    );
  }

  putLocations(params): Observable<any> {
    return this.api.put('api/locations', params, { requestId: this.getGuidId() }, 'orderingURL');
  }

  getShippingPackagesSizes(): Observable<any> {
    return this.api.get(`api/shippingpackagesizes`, {}, 'orderingURL');
  }

  getCampaigns(params): Observable<any> {
    return this.api.get(`api/campaign/getcampaigns`, params, 'orderingURL');
  }

  changeCampaignStatus(data): Observable<any> {
    return this.api.put(`api/campaign/changecampaignstatus`, data, { requestId: this.getGuidId() }, 'orderingURL');
  }

  getCashBackDetailsCampaign(id): Observable<any> {
    return this.api.get(`api/campaign/getcashbackdetails`, { campaignId: id }, 'orderingURL');
  }

  getCampaignProducts(params): Observable<any> {
    return this.api.get(`api/campaign/getcampaignproducts`, params, 'orderingURL');
  }

  getCampaigDatails(id): Observable<any> {
    return this.api.get(`api/campaign/getcampaigndetails`, { campaignId: id }, 'orderingURL');
  }

  registerCampaign(data): Observable<any> {
    return this.api.post(`api/campaign/registercampaign`, data, { requestId: this.getGuidId() }, 'orderingURL');
  }

  addCashBack(data): Observable<any> {
    return this.api.post(`api/campaign/cashback`, data, { requestId: this.getGuidId() }, 'orderingURL');
  }

  updateCashBack(data): Observable<any> {
    return this.api.put(`api/campaign/cashback`, data, { requestId: this.getGuidId() }, 'orderingURL');
  }

  editCampaign(data): Observable<any> {
    return this.api.put(`api/campaign/editcampaign`, data, { requestId: this.getGuidId() }, 'orderingURL');
  }

  deleteCampaignProduct(data): Observable<any> {
    return this.api.post(`api/campaign/deletecampaignproduct`, data, { requestId: this.getGuidId() }, 'orderingURL');
  }

  importProducts(id, data): Observable<any> {
    return this.api.upload(`api/campaign/addmultiplecampaignproduct`, data, { campaignId: id }, 'orderingURL');
  }

  getSuccesPageShippingInfo(orderId): Observable<any> {
    return this.api.post(
      `api/orders/success-page-shipping-info-text`,
      { orderId },
      { requestId: this.getGuidId() },
      'orderingURL'
    );
  }

  getOrderIziboxUrl(OrderExternalId): Observable<any> {
    return this.api.get(
      `api/IziBoxIntegration/iziBox-dashboard-url`,
      { OrderExternalId },
      'deliveryIntegrationApi',
      'text/html'
    );
  }

  uploadCampaignLogo(data): Observable<any> {
    return this.api.upload('api/upload/image', data, { requestId: this.getGuidId() }, 'orderingURL');
  }

  getActiveCampaigns(): Observable<any> {
    return this.api.get('api/campaign/active/campaigns', {}, 'orderingURL');
  }

  setDeliveryDateForOrderLine(params): Observable<any> {
    return this.api.put('api/orders/orderline/deliverydate', params, { requestId: this.getGuidId() }, 'orderingURL');
  }

  orderLineExternalFailetAction(
    ExternalFailedAction,
    orderId,
    orderLineId,
    ExtraComment,
    ReturnAddressId,
    addressId?,
    address?,
    city?
  ): Observable<any> {
    return this.api.put(
      'api/orders/orderLine/externalFailedAction',
      {},
      {
        ExternalFailedAction: ExternalFailedAction,
        OrderId: orderId,
        OrderLineId: orderLineId,
        ExtraComment: ExtraComment,
        AddressId: addressId,
        Address: address,
        ReturnAddressId,
        City: city,
        requestId: this.getGuidId()
      },
      'orderingURL'
    );
  }

  orderItemxternalFailetAction(
    ExternalFailedAction,
    orderId,
    orderLineId,
    OrderItemId,
    ExtraComment,
    ReturnAddressId,
    addressId?,
    address?,
    city?
  ): Observable<any> {
    return this.api.put(
      'api/orders/orderItem/externalFailedAction',
      {},
      {
        ExternalFailedAction: ExternalFailedAction,
        requestId: this.getGuidId(),
        OrderId: orderId,
        OrderLineId: orderLineId,
        OrderItemId: OrderItemId,
        ExtraComment: ExtraComment,
        AddressId: addressId,
        Address: address,
        ReturnAddressId,
        City: city
      },
      'orderingURL'
    );
  }

  getExpressDeliveryType(DarkStoreId): Observable<any> {
    return this.api.get(`api/expressdeliverytype`, { DarkStoreId }, 'orderingURL');
  }

  getUserDebt(): Observable<any> {
    return this.api.get(`api/orderfinancial/dept`, {}, 'orderingURL');
  }

  getDebtRefundDetails(subOrderId: string): Observable<any> {
    return this.api.get('api/orderfinancial/details', { subOrderId }, 'orderingURL');
  }

  updateOrderFinancialStatus(id: string): Observable<any> {
    return this.api.put('api/orderfinancial/status', { id }, { requestId: this.getGuidId() }, 'orderingURL');
  }

  getAllEmployeeProfile(pageNumber: number, pageSize: number, searchValue: string): Observable<AllEMployeeProfile> {
    return this.api
      .get<AllEMployeeProfile>('api/employeeprofile', { pageNumber, pageSize, searchValue }, 'orderingURL')
      .pipe(
        map(res => ({
          ...res,
          data: res.data.map(profile => ({
            ...profile,
            vacation: {
              vacationDateFrom: profile.vacation?.vacationDateFrom
                ? new Date(profile.vacation?.vacationDateFrom)
                : null,
              vacationDateTo: profile.vacation?.vacationDateTo ? new Date(profile.vacation?.vacationDateTo) : null
            }
          }))
        }))
      );
  }

  updateEmployeeProfileVacations(body: VacationEditBody): Observable<any> {
    return this.api.patch<any>('api/employeeprofile/vacations', body, { requestId: this.getGuidId() }, 'orderingURL');
  }

  updateEmployeeProfile(body: EmployeeProfileEditBody): Observable<any> {
    return this.api.put<any>('api/employeeprofile', body, { requestId: this.getGuidId() }, 'orderingURL');
  }

  createEmployeeProfile(body: EmployeeProfileCreateBody): Observable<any> {
    return this.api.post<any>('api/employeeprofile', body, { requestId: this.getGuidId() }, 'orderingURL');
  }

  deleteEmployeeProfile(id: number): Observable<any> {
    return this.api.delete<any>(`api/employeeprofile/${id}`, { requestId: this.getGuidId() }, 'orderingURL');
  }

  getAllEmployessForProfile(): Observable<{ userId: string; email: string }[]> {
    return this.api.get<{ userId: string; email: string }[]>('api/Admin/user/employees-for-profile');
  }

  getEmployeeProfileInfo(): Observable<EmployeeProfileInfo> {
    return this.api.get<EmployeeProfileInfo>('api/employeeprofile/info', {}, 'orderingURL');
  }

  getEmployProfileById(id: number): Observable<EmployeeProfileInfo> {
    return this.api.get<EmployeeProfileInfo>(`api/employeeprofile/${id}`, {}, 'orderingURL');
  }

  updateOwnerEmployee(body: OwnerEmployeeBody): Observable<any> {
    return this.api.patch('api/orders/owner-employee', body, { requestId: this.getGuidId() }, 'orderingURL');
  }

  getOrdersByProfile(params: EmployeeProfileOrdersParameters): Observable<OrdersResponse> {
    return this.api.get<OrdersResponse>('api/employeeprofile/orders', params, 'orderingURL');
  }

  getTimeSlots(): Observable<TimeSlot[]> {
    return this.api.get<TimeSlot[]>('api/timeslot', { requestId: this.getGuidId() }, 'orderingURL');
  }

  updateTimeSlots(body: TimeSlot): Observable<any> {
    return this.api.put('api/timeslot', body, { requestId: this.getGuidId() }, 'orderingURL');
  }

  checkTimeSlots(body: OrderDetails): Observable<any> {
    return this.api.post('api/orders/check/valid-timeslot', body, { requestId: this.getGuidId() }, 'orderingURL');
  }

  buyVoucher(body: Omit<VoucherBody, 'electricAlso'>): Observable<string[]> {
    return this.api.post<string[]>('api/vouchers', body, { requestId: this.getGuidId() }, 'orderingURL');
  }

  voucherValidate(voucherCode: string): Observable<VoucherValidate> {
    return this.api.get<VoucherValidate>('api/vouchers/validate', { voucherCode }, 'orderingURL');
  }

  voucherDetails(VoucherId: string): Observable<VoucherDetail> {
    return this.api.get<VoucherDetail>('api/vouchers/details', { VoucherId }, 'orderingURL');
  }

  createSlaConfiguration(slaLocation: number): Observable<any> {
    const guid = this.getGuidId();
    return this.api.post(
      'api/slaconfiguration',
      { slaLocation, correlationId: guid },
      { requestId: guid },
      'orderingURL'
    );
  }

  getSlaConfigurationList(): Observable<SlaList[]> {
    return this.api.get<SlaList[]>('api/slaconfiguration', {}, 'orderingURL');
  }

  deleteSlaConfiguration(id: number) {
    const guid = this.getGuidId();
    return this.api.delete('api/slaconfiguration', { id, correlationId: guid, requestId: guid }, 'orderingURL');
  }

  editSlaConfiguration(data: SlaList) {
    const guid = this.getGuidId();
    return this.api.put('api/slaconfiguration', { ...data, correlationId: guid }, { requestId: guid }, 'orderingURL');
  }

  getSlaConfigurationOptions(slaConfigurationId: number): Observable<SlaConfigurationOptions[]> {
    return this.api.get<SlaConfigurationOptions[]>(
      'api/slaconfiguration/options',
      { slaConfigurationId },
      'orderingURL'
    );
  }

  createSlaConfigurationOptions(data: CreateSlaConfigurationOption): Observable<any> {
    const guid = this.getGuidId();
    data.correlationId = guid;
    return this.api.post('api/slaconfiguration/options/create', data, { requestId: guid }, 'orderingURL');
  }

  updateSlaConfigurationOptions(data: UpdateSlaConfigurationOption): Observable<any> {
    const guid = this.getGuidId();
    data.correlationId = guid;
    return this.api.put('api/slaconfiguration/options/update', data, { requestId: guid }, 'orderingURL');
  }

  deleteSlaConfigurationOptions(data: DeleteSlaConfigurationOption): Observable<any> {
    const guid = this.getGuidId();
    data.correlationId = guid;
    return this.api.delete('api/slaconfiguration/options/delete', { ...data, requestId: guid }, 'orderingURL');
  }

  getCashbacks(params): Observable<any> {
    return this.api.get(`api/campaign/cashbacks`, params, 'orderingURL');
  }

  importCampaignMerchants(id, data): Observable<any> {
    return this.api.upload(`api/campaign/multiple-merchants`, data, { campaignId: id }, 'orderingURL');
  }

  getCampaignMerchants(params): Observable<any> {
    return this.api.get(`api/campaign/merchant-list`, params, 'orderingURL');
  }

  deleteCampaignMerchant(params): Observable<any> {
    return this.api.delete(
      `api/campaign/merchant`,
      { ...params, requestId: this.getGuidId(), correlationId: this.getGuidId() },
      'orderingURL'
    );
  }

  private getGuidId(): string {
    return Guid.create().toString();
  }
}

export interface OwnerEmployeeBody {
  orderIds: string[];
  ownerEmployee: string;
}

export interface TimeSlot {
  timeSlotId: number;
  slotType: number;
  shippingPrice: number;
  timeSlotsOptions: TimeSlotsOptions[];
}

export interface TimeSlotsOptions {
  timeSlotOptionsId: number;
  startHour: string;
  endHour: string;
  before: number;
  after: number;
  isChecked?: boolean;
}
export interface PickUpPoints {
  pageNumber: number;
  pageSize: number;
  shippingOptionId: string;
}

export interface PickUpPointsUpdate {
  id: number;
  addressDisplayName: string;
  phoneNumber: string;
  workingHours?: string;
  longitude?: number;
  latitude?: number;
  postalCode?: number;
}
export interface PickUpPointsCreate {
  shippingOptionId: number;
  locationId: number;
  addressDisplayName: string;
  longitude: number;
  latitude: number;
  workingHours: string;
  phoneNumber: string;
}

interface OrderDetails {
  locationId: number;
  merchants: {
    categoryId: number;
    merchantId: string;
  }[];
  productTimeSlotOptionIds: number[];
}
