import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { CanActivate, CanLoad, Router, UrlTree } from '@angular/router';
import { GlobalDataHttp } from 'lib-core';
import { Observable, catchError, map, of } from 'rxjs';
import { UserTypeService } from '../../services/user-type.service';

@Injectable()
export class ImpersonateUserGuard implements CanLoad, CanActivate {
  constructor(
    private globalDataHttp: GlobalDataHttp,
    private router: Router,
    private userTypeService: UserTypeService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  canLoad(): Observable<boolean | UrlTree> {
    if (this.userTypeService.isUserLegalEntity) {
      return of(true);
    }
    return this.globalDataHttp.isImpersonatedUser().pipe(
      map(res => this.onSuccess(res)),
      catchError(() => this.onError())
    );
  }

  canActivate(): Observable<boolean | UrlTree> {
    if (this.userTypeService.isUserLegalEntity) {
      return of(true);
    }
    return this.globalDataHttp.isImpersonatedUser().pipe(
      map(res => this.onSuccess(res)),
      catchError(() => this.onError())
    );
  }

  private onSuccess(res: boolean): boolean {
    if (res) {
      this.navigateToHomePage();
      return false;
    }
    return true;
  }

  private onError(): Observable<boolean> {
    this.navigateToHomePage();
    return of(false);
  }

  private navigateToHomePage(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.router.navigate(['/']);
    }
  }
}
