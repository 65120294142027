import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CustomersHttp } from 'lib-core';
import { userVerifiedStatus } from '../store/reducers/user.reducer';

@Injectable({
  providedIn: 'root'
})
export class VerificationService {
  public verificationState$ = this.store.select(userVerifiedStatus);

  constructor(
    private customersHttp: CustomersHttp,
    private store: Store
  ) {}

  postponeVerification(): void {
    this.customersHttp.postponeVerification().subscribe(_ => {});
  }
}
