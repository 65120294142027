import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { setCampaignObj } from '../actions/campaign.actions';

export const initialState: any = {
  loading: false
};

const authReducer = createReducer(
  initialState,
  on(setCampaignObj, (state, action) => ({ ...state, ...action }))
);

export function reducer(state: any, action: Action) {
  return authReducer(state, action);
}

export const selectAuthFeatureState = createFeatureSelector<any>('campaign');

export const getUserCampaign = createSelector(selectAuthFeatureState, state => state);
