import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MarketingHttp } from 'lib-core';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as bannerActions from '../actions/banner.actions';
import { BannerModel } from '../models/banner.model';

@Injectable()
export class BannerEffects {
  getBanners = createEffect(() =>
    this.actions$.pipe(
      ofType(bannerActions.getBanners),
      mergeMap(() => this.marketingService.getLandingPageSliders()),
      map((banners: BannerModel[]) => bannerActions.getBannersSuccess({ banners: banners })),
      catchError(error => of(bannerActions.getBannersFail({ error })))
    )
  );

  constructor(
    private actions$: Actions,
    private marketingService: MarketingHttp
  ) {}
}
