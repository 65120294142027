import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { OverflowHandlerService } from './overflow-handler.service';

@Injectable({
  providedIn: 'root'
})
export class ImageForSearchService implements OnDestroy {
  private imageUploadModalState = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private overflowHandlerService: OverflowHandlerService
  ) {}

  public setImageSearchModalState(value: boolean = false, overflow: boolean = value): void {
    if (isPlatformBrowser(this.platformId)) {
      if (overflow) {
        this.overflowHandlerService.hide();
      } else {
        this.overflowHandlerService.show();
      }
    }
    this.imageUploadModalState.next(value);
  }

  get getImageUploadModalState(): Observable<boolean> {
    return this.imageUploadModalState;
  }

  ngOnDestroy() {
    this.imageUploadModalState.complete();
  }
}
