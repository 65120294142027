import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Unsubscriber } from 'lib-core';
import { Observable, takeUntil } from 'rxjs';
import { CheckoutCommonService } from '../../../pages/protected-routes/checkout/services/checkout-common.service';
import { PickUpService } from '../../../pages/protected-routes/checkout/services/pick-up.service';
import { AddressService } from '../../../services/address.service';
import { CommonService } from '../../../services/common.service';
import { ConfirmationDialogService } from '../../../services/confirmation-dialog.service';
import { ExpressService } from '../../../services/express.service';
import {
  ChangeDefaultShippingAddressAttempt,
  RemoveShippginAddressAttempt
} from '../../../store/actions/addresses.actions';
import { AddressesStoreModel, ShippingAddressInteface } from '../../../store/models/addresses.model';
import { ShippingOptionType } from '../../../store/models/orders.model';
import { selectAddresses } from '../../../store/reducers/addresses.reducer';

@Component({
  selector: 'app-address-listing',
  templateUrl: './address-listing.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
  standalone: true
})
export class AddressListingComponent extends Unsubscriber implements OnInit, OnDestroy {
  public userAddresses$: Observable<ShippingAddressInteface[]> = this.store.select(selectAddresses);
  public addresses: ShippingAddressInteface[] = null;
  public localSelectedAddressId: number | null = null;
  public localSelectedAddress: ShippingAddressInteface = null;
  public isSelectedOptionTypeCourier: boolean = true;
  private userAddresses: ShippingAddressInteface[] = null;

  constructor(
    private addressService: AddressService,
    private expressService: ExpressService,
    private store: Store<AddressesStoreModel>,
    private cdr: ChangeDetectorRef,
    private confirmationDialogService: ConfirmationDialogService,
    private commonService: CommonService,
    private checkoutCommonService: CheckoutCommonService,
    private pickUpService: PickUpService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    super();
  }

  get isFromExpress(): boolean {
    return this.addressService.isFromExpress;
  }

  ngOnInit(): void {
    this.isSelectedOptionTypeCourier =
      this.checkoutCommonService.getSelectedShippingOptionType() == ShippingOptionType.Courier;
    this.isSelectedOptionTypeCourier ? this.setUserAddresses() : this.setPickUpLocationAddresses();
  }

  setUserAddresses() {
    this.userAddresses$.pipe(takeUntil(this.destroy$)).subscribe((addresses: ShippingAddressInteface[]) => {
      this.addresses = addresses;
      this.updateSelectedAddresBasedOnAddressesUpdate(addresses);
      if (this.addressService.selectedAddressId) {
        this.localSelectedAddressId = this.addressService.selectedAddressId;
      } else {
        this.localSelectedAddressId = addresses?.find(address => address.isDefault)?.id || null;
      }
      this.userAddresses = this.commonService.moveElementToStartOfTheArray(addresses, this.localSelectedAddressId);
      this.cdr.markForCheck();
    });
  }

  setPickUpLocationAddresses() {
    this.pickUpService
      .getpickUpLocationsById$(this.pickUpService.getChosenCity()?.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((addresses: ShippingAddressInteface[]) => {
        this.addresses = addresses;
        this.updateSelectedAddresBasedOnAddressesUpdate(addresses);
        if (this.addressService.selectedPickUpAddressId) {
          this.localSelectedAddressId = this.addressService.selectedPickUpAddressId;
        } else {
          this.localSelectedAddressId = addresses[0]?.id || null;
        }
        this.userAddresses = this.commonService.moveElementToStartOfTheArray(addresses, this.localSelectedAddressId);

        this.cdr.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.addressService.updateAddressListingWithNewValue = false;

    super.ngOnDestroy();
  }

  public setActiveAddressIdLocally(address: ShippingAddressInteface): void {
    const addressId = address?.id;
    this.localSelectedAddress = address;
    this.localSelectedAddressId = addressId;
  }

  public closeAddressListingModal(): void {
    this.addressService.closeAddressListingModal();
  }

  public editAddress(addressInfo: ShippingAddressInteface): void {
    this.addressService.updateAddressListingWithNewValue = addressInfo.id;
    this.addressService.editAddressData = addressInfo;
    this.addressService.openAddressFormModal();
  }

  public addNewAddress(): void {
    this.addressService.updateAddressListingWithNewValue = true;
    this.addressService.openAddressFormModal();
  }

  public saveListingChanges(): void {
    this.isSelectedOptionTypeCourier ? this.saveUserAddressChanges() : this.savePickUpAddressChanges();
    this.closeAddressListingModal();
  }

  saveUserAddressChanges(): void {
    this.addressService.setSelectedAddressId(this.localSelectedAddressId);
    this.store.dispatch(ChangeDefaultShippingAddressAttempt({ id: this.localSelectedAddressId, isDefault: true }));
    this.expressService.updateDarkStoreData(
      true,
      this.userAddresses?.find(address => address.id === this.localSelectedAddressId)
    );
  }

  savePickUpAddressChanges(): void {
    this.addressService.setSelectedPickUpAddress(this.localSelectedAddress);
    this.addressService.setSelectedPickUpAddressId(this.localSelectedAddressId);
  }

  openGoogleMaps(address: ShippingAddressInteface) {
    if (isPlatformBrowser(this.platformId)) {
      const url = `https://www.google.com/maps/search/?api=1&query=${address.latitude},${address.longitude}`;
      window.open(url, '_blank');
    }
  }

  public openAdressDeletionConfirmationModal(addressId: number): void {
    this.confirmationDialogService.openConfirmationModal({
      title: 'მისამართის წაშლა',
      text: 'ნამდვილად გსურთ მისამართის წაშლა?',
      confirmAction: () => this.removeAddress(addressId)
    });
  }

  private updateSelectedAddresBasedOnAddressesUpdate(addresses: ShippingAddressInteface[]): void {
    if (this.addressService.updateAddressListingWithNewValue) {
      if (typeof this.addressService.updateAddressListingWithNewValue === 'number') {
        this.addressService.setSelectedAddressId(this.addressService.updateAddressListingWithNewValue);
      } else {
        const newAddress = this.commonService.findAdditionalElementBetweenTwoArrayWithId(this.userAddresses, addresses);
        this.addressService.setSelectedAddressId(newAddress.id);
      }
      this.addressService.updateAddressListingWithNewValue = null;
    }
  }

  private removeAddress(addressId: number): void {
    this.store.dispatch(RemoveShippginAddressAttempt({ addressId }));
    if (this.addressService.selectedAddressId === addressId) {
      this.addressService.setSelectedAddressId(null);
    }
  }
}
