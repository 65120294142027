import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MarketingHttp } from 'lib-core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: []
})
export class FooterComponent implements OnInit {
  menuCollapse = [false, false, false];
  public staticPagesGroup$;

  meta = {
    year: new Date().getFullYear()
  };
  @ViewChild('footer', { static: true }) footer: ElementRef;
  constructor(
    private router: Router,
    private marketingHttp: MarketingHttp,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit() {
    this.staticPagesGroup$ = this.marketingHttp.getFooterStaticPages();
  }

  toggleMenu(i) {
    this.menuCollapse[i] = !this.menuCollapse[i];
  }

  navigate(route, url) {
    if (isPlatformBrowser(this.platformId)) {
      window.scroll(0, 0);
    }
    this.router.navigate([route, url]);
  }
}
