import { createAction, props } from '@ngrx/store';
import { BasketCheckOutModel, BasketModel, BasketUpdateModel } from '../models/basket.model';
import { HttpErrorResponse } from '@angular/common/http';

export enum BasketActionsTypes {
  GetBasket = '[BASKET] GetBasket',
  GetBasketSuccess = '[BASKET] GetBasket Success',
  ClearCheckoutBasket = '[BASKET] ClearCheckoutBasket',
  GetCheckOutBasket = '[BASKET] GetCheckOutBasket',
  GetCheckOutBasketSuccess = '[BASKET] GetCheckOutBasket Success',
  GetCheckOutBasketFail = '[BASKET] GetCheckOutBasket Fail',
  UpdateBasket = '[BASKET] UpdateBasket',
  UpdateBasketMultiple = '[BASKET] UpdateBasketMultiple',
  UpdateBasketMultipleV2 = '[BASKET] UpdateBasketMultiple V2',
  UpdateBasketSuccess = '[BASKET] UpdateBasket Success',
  DeleteBasket = '[BASKET] DeleteBasket',
  DeleteBasketSuccess = '[BASKET] DeleteBasketSuccess',
  DeleteBasketFail = '[BASKET] DeleteBasketFail',
  ClearBasket = '[BASKET] ClearBasket',
  ClearBasketSuccess = '[BASKET] ClearBasketSuccess',
  CheckLocalBasket = '[BASKET] Check Local Basket',
  MakeCheckoutSuccessFalse = '[BASKET] Make Checkout Success False'
}

export const makeCheckoutSuccessFalse = createAction(BasketActionsTypes.MakeCheckoutSuccessFalse);

export const clearCheckoutBasket = createAction(BasketActionsTypes.ClearCheckoutBasket);
export const getBasket = createAction(BasketActionsTypes.GetBasket);
export const getBasketSuccess = createAction(BasketActionsTypes.GetBasketSuccess, props<BasketModel>());

export const checkLocalBasket = createAction(BasketActionsTypes.CheckLocalBasket);

export const getCheckOutBasket = createAction(BasketActionsTypes.GetCheckOutBasket, props<BasketCheckOutModel>());
export const getCheckOutBasketSuccess = createAction(
  BasketActionsTypes.GetCheckOutBasketSuccess,
  props<BasketCheckOutModel>()
);
export const getCheckOutBasketFail = createAction(
  BasketActionsTypes.GetCheckOutBasketFail,
  props<{ error: HttpErrorResponse }>()
);

export const updateBasket = createAction(BasketActionsTypes.UpdateBasket, props<BasketUpdateModel>());
export const updateBasketMultiple = createAction(BasketActionsTypes.UpdateBasketMultiple, props<{ arr: any[] }>());
export const updateUserBasketMultiplev2 = createAction(
  BasketActionsTypes.UpdateBasketMultipleV2,
  props<{ arr: any[] }>()
);
export const updateBasketSuccess = createAction(BasketActionsTypes.UpdateBasketSuccess, props<BasketModel>());

export const deleteBasket = createAction(BasketActionsTypes.DeleteBasket, props<BasketUpdateModel>());
export const deleteBasketSuccess = createAction(BasketActionsTypes.DeleteBasketSuccess, props<BasketModel>());
export const deleteBasketFail = createAction(BasketActionsTypes.DeleteBasketFail, props<{ error: any }>());

export const clearBasket = createAction(BasketActionsTypes.ClearBasket);
export const clearBasketSuccess = createAction(BasketActionsTypes.ClearBasketSuccess, props<BasketModel>());
