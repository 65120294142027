import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { PAGE_ROUTES } from './pages/pages-routes.module';
import { PagesWraperComponent } from './pages-wraper/pages-wraper.component';
import { DevAuthGuard } from './core/guards/dev-auth.guard';
import { DevAppGuard } from './core/guards/dev-app.guard';

export const routes: Routes = [
  {
    path: 'dev-auth',
    pathMatch: 'full',
    canActivate: [DevAuthGuard],
    loadChildren: () => import('./pages/dev-auth/dev-auth.module').then(m => m.DevAuthModule)
  },

  {
    path: '',
    canActivate: [DevAppGuard],
    component: PagesWraperComponent,
    children: PAGE_ROUTES
  },

  {
    path: '**',
    redirectTo: '/'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
