import { Injectable } from '@angular/core';
import { ApiService } from '../services/api.service';

@Injectable({
  providedIn: 'root'
})
export class PhoneIsAddedService {
  constructor(private api: ApiService) {}

  check(phone: string) {
    return this.api.get(`api/customers/contact-phone/exists/${phone}`, {}, 'customersURL');
  }
}
