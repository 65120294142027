import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { clearIBuyConfig, updateIBuyConfig } from '../actions/instant-buy.actions';
import { IBuyModel, IBuyTabs } from '../models/instant-buy.model';

export const initialState: IBuyModel = {
  instalmentType: null,
  merchant: null,
  paymentType: null,
  activeTab: IBuyTabs.merchant,
  status: false
};

const iBuyReducer = createReducer(
  initialState,
  on(updateIBuyConfig, (state, action) => ({ ...state, ...action })),
  on(clearIBuyConfig, state => ({ ...state, ...initialState }))
);

export function reducer(state: IBuyModel, action: Action) {
  return iBuyReducer(state, action);
}

export const selectIBuyFeatureState = createFeatureSelector<IBuyModel>('iBuy');

export const getIBuyConfig = createSelector(selectIBuyFeatureState, (state: IBuyModel) => state);
