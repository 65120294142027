<div
  class="_x_fixed _x_left-0 _x_right-0 _x_top-0 _x_z-10 _x_flex _x_h-full _x_max-h-full _x_w-full _x_items-center _x_justify-center _x_overflow-x-hidden _x_overflow-y-hidden _x_p-4">
  <div
    (click)="orderDebtService.closeDebtPaymentModal()"
    class="_x_fixed _x_left-0 _x_right-0 _x_top-0 _x_z-10 _x_h-full _x_w-full _x_bg-dark-600"></div>
  <div
    class="_x_absolute _x_bottom-auto _x_z-10 _x_mx-8 _x_h-20 _x_h-auto _x_max-h-full _x_w-full _x_max-w-171 _x_translate-y-0 _x_transform _x_overflow-hidden _x_rounded-12 _x_bg-white sm:_x_max-w-262">
    <div class="_x_relative _x_rounded-t-12 _x_bg-white sm:_x_w-full">
      <div class="_x_flex _x_items-center _x_justify-between _x_border-b _x_border-gray _x_p-8">
        <div class="_x_flex _x_items-center">
          <p class="_x_font-bold _x_text-5">გადახდის მეთოდი</p>
        </div>
        <button
          (click)="orderDebtService.closeDebtPaymentModal()"
          class="_x_icon _x_icon-remove _x_flex _x_h-12 _x_max-h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_cursor-pointer _x_items-center _x_justify-center _x_text-7 _x_text-dark-700"></button>
      </div>
      <div class="_x_flex _x_flex-col _x_gap-6 _x_p-8">
        <div
          (click)="selectedPaymentMethod = paymentType.IPay"
          [ngClass]="{
            '_x_border-2 _x_border-purple': selectedPaymentMethod === paymentType.IPay,
            '_x_border-1 _x_border-dark-200': selectedPaymentMethod !== paymentType.IPay
          }"
          class="_x_flex _x_flex-col _x_gap-8 _x_rounded-8 _x_p-8">
          <div
            [ngClass]="{
              '_x_text-purple': selectedPaymentMethod === paymentType.IPay,
              '_x_text-dark': selectedPaymentMethod !== paymentType.IPay
            }"
            class="_x_flex _x_gap-3">
            <i
              class="_x_icon _x_icon-card _x_-ml-1 _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_justify-center _x_text-7"></i>
            <p
              class="_x_text-4"
              [ngClass]="{
                '_x_font-bold': selectedPaymentMethod === paymentType.IPay,
                '_x_font-medium': selectedPaymentMethod !== paymentType.IPay
              }">
              ბარათით
            </p>
          </div>
          <div
            *ngFor="let card of cards$ | async"
            (click)="selectedCard = card"
            class="_x_flex _x_items-center _x_gap-6">
            <div
              class="_x_group _x_flex _x_h-9 _x_max-h-9 _x_min-h-9 _x_w-9 _x_min-w-9 _x_max-w-9 _x_items-center _x_justify-center _x_rounded-full md:_x_mt-1"
              [ngClass]="{
                '_x_border-2 _x_border-purple':
                  card?.id === selectedCard?.id && selectedPaymentMethod !== paymentType.Balance,
                '_x_border _x_border-dark-300':
                  card?.id !== selectedCard?.id || selectedPaymentMethod === paymentType.Balance
              }">
              <i
                *ngIf="card?.id === selectedCard?.id && selectedPaymentMethod === paymentType.IPay"
                class="_x_h-4 _x_w-4 _x_rounded-8 _x_bg-purple _x_transition _x_duration-300 group-hover:_x_bg-purple"></i>
            </div>
            <p class="_x_font-medium _x_text-3 _x_text-dark">
              {{ card.name.slice(0, 4) }} **** **** {{ card.name.slice(-4) }}
            </p>
            <svg
              *ngIf="card.type === 2"
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="15"
              viewBox="0 0 23 15"
              fill="none">
              <path
                d="M22.7777 11.8382V11.5664H22.7058L22.6227 11.7529L22.54 11.5664H22.4679V11.8382H22.5189V11.6334L22.5967 11.8101H22.6496L22.7273 11.633V11.8382H22.7779H22.7777ZM22.3218 11.8382V11.6129H22.4137V11.5671H22.1787V11.6129H22.2707V11.8382H22.3213H22.3218Z"
                fill="#F79410" />
              <path d="M14.6096 13.0047H8.39062V1.99805H14.6097L14.6096 13.0047Z" fill="#FF5F00" />
              <path
                d="M8.78472 7.5C8.78472 5.2673 9.84624 3.2784 11.4992 1.99667C10.248 1.02503 8.70042 0.497689 7.107 0.500008C3.18182 0.500008 0 3.63396 0 7.5C0 11.366 3.18182 14.5 7.107 14.5C8.70046 14.5023 10.2481 13.975 11.4994 13.0033C9.84647 11.7218 8.78472 9.73281 8.78472 7.5Z"
                fill="#EB001B" />
              <path
                d="M22.9999 7.5C22.9999 11.366 19.8181 14.5 15.8929 14.5C14.2993 14.5023 12.7515 13.975 11.5 13.0033C13.1535 11.7216 14.215 9.73281 14.215 7.5C14.215 5.26719 13.1535 3.2784 11.5 1.99667C12.7515 1.02506 14.2992 0.49773 15.8928 0.500007C19.818 0.500007 22.9998 3.63396 22.9998 7.5"
                fill="#F79E1B" />
            </svg>
            <svg
              *ngIf="card.type === 1"
              xmlns="http://www.w3.org/2000/svg"
              width="34"
              height="11"
              viewBox="0 0 34 11"
              fill="none">
              <mask
                id="mask0_7662_94830"
                style="mask-type: luminance"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="34"
                height="11">
                <path
                  d="M17.5772 3.50806C17.5586 5.03817 18.9365 5.89652 19.9792 6.40034C21.0498 6.92282 21.4036 7.25869 21.4036 7.72519C21.3943 8.4436 20.5471 8.75148 19.7558 8.77014C18.3779 8.78881 17.5772 8.39695 16.9348 8.09839L16.4414 10.4309C17.0838 10.7294 18.2662 10.9813 19.4951 11C22.3812 11 24.2618 9.57252 24.2711 7.36132C24.2804 4.55301 20.3981 4.3944 20.4261 3.14419C20.4354 2.76166 20.7985 2.36047 21.5898 2.25785C21.9808 2.20187 23.0701 2.16455 24.299 2.73367L24.7831 0.475826C24.1221 0.233248 23.2749 0 22.2136 0C19.4951 0 17.5865 1.44614 17.5772 3.50806ZM29.4195 0.186599C28.8888 0.186599 28.4513 0.494486 28.2557 0.970313L24.1407 10.8227H27.0268L27.6041 9.23664H31.1232L31.4584 10.8227H34L31.7842 0.186599H29.4195ZM29.8198 3.06022L30.6484 7.05344H28.3675L29.8198 3.06022ZM14.0767 0.186599L11.805 10.8227H14.5515L16.8231 0.186599H14.0767ZM10.0175 0.186599L7.15936 7.42663L6.00493 1.26887C5.86528 0.578456 5.33461 0.186599 4.73877 0.186599H0.0651695L0 0.494486C0.958927 0.699744 2.04819 1.03562 2.7092 1.39949C3.10953 1.62341 3.23056 1.81001 3.3609 2.33249L5.54874 10.8227H8.45345L12.9036 0.186599H10.0175Z"
                  fill="white" />
              </mask>
              <g mask="url(#mask0_7662_94830)">
                <path
                  d="M-3.55762 1.30597L29.921 -11.0469L37.5366 9.68426L4.05794 22.0371"
                  fill="url(#paint0_linear_7662_94830)" />
              </g>
              <defs>
                <linearGradient
                  id="paint0_linear_7662_94830"
                  x1="3.06941"
                  y1="10.6328"
                  x2="31.7219"
                  y2="0.10335"
                  gradientUnits="userSpaceOnUse">
                  <stop stop-color="#222357" />
                  <stop offset="1" stop-color="#254AA5" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div (click)="selectedCard = undefined" class="_x_flex _x_items-center _x_gap-6">
            <div
              class="_x_group _x_flex _x_h-9 _x_max-h-9 _x_min-h-9 _x_w-9 _x_min-w-9 _x_max-w-9 _x_items-center _x_justify-center _x_rounded-full md:_x_mt-1"
              [ngClass]="{
                '_x_border-2 _x_border-purple': selectedPaymentMethod !== paymentType.Balance && !selectedCard,
                '_x_border _x_border-dark-300': selectedPaymentMethod === paymentType.Balance || selectedCard
              }">
              <i
                *ngIf="selectedPaymentMethod !== paymentType.Balance && !selectedCard"
                class="_x_h-4 _x_w-4 _x_rounded-8 _x_bg-purple _x_transition _x_duration-300 group-hover:_x_bg-purple"></i>
            </div>
            <p class="_x_font-medium _x_text-3 _x_text-dark">ახალი ბარათი</p>
          </div>
          <!-- <div [ngClass]="{'_x_opacity-40': selectedPaymentMethod === paymentType.Balance, '_x_opacity-100': selectedPaymentMethod === paymentType.IPay}" class="_x_flex _x_gap-6 _x_items-center">
              <div
                (click)="rememberCard = !rememberCard"
                class="_x_pl-6 _x_border-l _x_border-dark-200 _x_flex _x_gap-6 _x_items-center _x_ml-4 _x_cursor-pointer"
                >
                <span
                  class="_x_w-10 _x_h-10 _x_min-w-10 _x_min-max-10 _x_rounded-4 _x_flex _x_items-center _x_justify-center"
                  [ngClass]="
                            rememberCard
                            ? '_x_bg-purple'
                            : '_x_border-2 _x_border-dark-300'">
                  <i class="_x_icon-done-check _x_text-white _x_text-3"></i>
                </span>
                <p class="_x_text-3 _x_font-medium _x_text-dark _x_whitespace-nowrap">მსურს ბარათის დამახსოვრება</p>
              </div>
            </div> -->
        </div>
        <div
          (click)="onBalance()"
          [ngClass]="{
            '_x_border-2 _x_border-purple': selectedPaymentMethod === paymentType.Balance,
            '_x_border-1 _x_border-dark-200': selectedPaymentMethod !== paymentType.Balance,
            '_x_pointer-events-none _x_opacity-40': (user$ | async)?.balance < orderDebt
          }"
          class="_x_rounded-8 _x_p-8">
          <div
            [ngClass]="{
              '_x_text-purple': selectedPaymentMethod === paymentType.Balance,
              '_x_text-dark': selectedPaymentMethod !== paymentType.Balance
            }"
            class="_x_flex _x_gap-3">
            <i
              class="_x_icon _x_icon-wallet _x_-ml-1 _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_justify-center _x_text-7"></i>
            <p
              class="_x_text-4"
              [ngClass]="{
                '_x_font-bold': selectedPaymentMethod === paymentType.Balance,
                '_x_font-medium': selectedPaymentMethod !== paymentType.Balance
              }">
              ბალანსით
            </p>
            <span
              _ngcontent-serverapp-c120=""
              class="_x_rounded-4 _x_bg-dark-100 _x_px-3 _x_font-bold _x_text-4 _x_text-dark"
              >{{ (user$ | async)?.balance }} ₾</span
            >
          </div>
        </div>
      </div>
      <div class="_x_px-8 _x_pb-8 _x_pt-4">
        <button
          (click)="expressOrderDebtPayment()"
          class="_x_h-24 _x_w-full _x_rounded-8 _x_bg-purple _x_px-20 _x_font-bold _x_text-3 _x_text-white">
          გადახდის გაგრძელება
        </button>
      </div>
    </div>
  </div>
</div>
