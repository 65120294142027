<div
  class="left-0 _x_fixed _x_top-0 _x_z-11 _x_mb-auto _x_flex _x_h-screen _x_w-full _x_items-center _x_justify-center _x_bg-dark-900">
  <div
    #content
    class="_x_relative _x_m-auto _x_mx-8 _x_flex _x_w-full _x_max-w-235 _x_flex-col _x_items-center _x_overflow-hidden _x_rounded-14 _x_bg-white _x_pb-15 _x_pl-15 _x_pr-15 _x_pt-25 _x_text-center">
    <img src="../../../../assets/img/503/error-503.svg" alt="error-503" />
    <p
      class="_x_text-4_x _min-h-10 _x_z-1 _x_mb-15 _x_mt-15 _x_flex _x_items-center _x_justify-center _x_font-medium _x_text-dark">
      მიმდინარეობს ტექნიკური სამუშაოები <br />
      სცადეთ მოგვიანებით
    </p>
    <button
      _ngcontent-serverapp-c0=""
      class="_x_h-21 _x_w-full _x_rounded-6 _x_bg-purple-100"
      (click)="navigatoToHomepage()">
      <span _ngcontent-serverapp-c0="" class="_x_font-bold _x_text-3 _x_text-purple">მთავარი გვერდი</span>
    </button>
  </div>
</div>
