import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { EventType } from 'projects/web/src/app/services/toast.service';
import { clearComparison } from 'projects/web/src/app/store/actions/comparison.actions';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToastComponent implements OnInit {
  @Output() closeEvent = new EventEmitter();
  // @Output() showComparison = new EventEmitter();
  // @Output() clearComparison = new EventEmitter();

  @Input()
  type!: EventType;

  @Input()
  title!: string;

  @Input()
  message!: string;

  @Input()
  id!: number;

  @Input()
  isComparison: boolean;

  timer;

  constructor(
    private store: Store,
    private routerService: Router
  ) {}
  public eventType = {
    success: 'success',
    info: 'info',
    warning: 'warning',
    error: 'error',
    successLight: 'successLight'
  };

  ngOnInit(): void {
    this.timer = setTimeout(() => {
      this.closeEvent.emit(this.id);
    }, 3000);
  }

  hide(): void {
    this.closeEvent.emit(this.id);
    clearTimeout(this.timer);
  }
  redirectToComparison() {
    this.routerService.navigate(['/comparison']);
  }
  handleComparison() {
    this.store.dispatch(clearComparison());
  }
}
