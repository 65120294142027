import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '../api.service';
import { Guid } from 'guid-typescript';

@Injectable({
  providedIn: 'root'
})
export class GlobalDataHttp {
  constructor(private api: ApiService) {}

  getLocation(pageNumber = 1, pageSize = 10, searchValue?): Observable<any> {
    return this.api.get(
      'api/locations',
      {
        pageNumber: pageNumber,
        pageSize: pageSize,
        searchValue: searchValue && searchValue
      },
      'cmsURL'
    );
  }

  getLocationById(id: number): Observable<any> {
    return this.api.get(`api/locations/${id}`, {}, 'cmsURL');
  }

  getWebTokenByUser(userId: string): Observable<{ access_token: string; refresh_token: string }> {
    return this.api.get<{ access_token: string; refresh_token: string }>('api/Account/ImpersonateUser', { userId });
  }

  isImpersonatedUser(): Observable<boolean> {
    return this.api.get<boolean>('api/Account/IsImpersonatedUser?time=' + new Date().getTime());
  }

  private getGuidId(): string {
    return Guid.create().toString();
  }
}
