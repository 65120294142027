import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CategoriesHttp, CatalogHttp } from 'lib-core';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import {
  GetMerchantCategories,
  GetMerchantCategoriesFail,
  GetMerchantCategoriesSuccess,
  GetMerchantMammaMiaFail,
  GetMerchantMammaMiaSuccess,
  GetMerchantMammaMia
} from '../actions/merchant.actions';

@Injectable()
export class MerchantEffects {
  constructor(
    private actions$: Actions,
    private categoriesHttp: CategoriesHttp,
    private catalogHttp: CatalogHttp
  ) {}

  getMerchantCategories = createEffect(() =>
    this.actions$.pipe(
      ofType(GetMerchantCategories),
      mergeMap(data =>
        this.categoriesHttp.coolCat({ merchantId: data.categories }).pipe(
          map(result => GetMerchantCategoriesSuccess({ data: result })),
          catchError(error => of(GetMerchantCategoriesFail({ error })))
        )
      )
    )
  );

  getMerchantMammaMia = createEffect(() =>
    this.actions$.pipe(
      ofType(GetMerchantMammaMia),
      mergeMap(data =>
        this.catalogHttp.getMerchantsMammaMia({ merchantId: data.merchantId }).pipe(
          map(result => GetMerchantMammaMiaSuccess({ data: result })),
          catchError(error => of(GetMerchantMammaMiaFail({ error })))
        )
      )
    )
  );
}
