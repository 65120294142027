import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import {
  addProductInWishlistAttempt,
  addProductInWishlistFail,
  addProductInWishlistSuccess,
  getWishlistProductIdsAttempt,
  getWishlistProductIdsFail,
  getWishlistProductIdsSuccess,
  getWishlistProductsAttempt,
  getWishlistProductsFail,
  getWishlistProductsSuccess,
  removeProdutFromWishlistAttempt,
  removeProdutFromWishlistFail,
  removeProdutFromWishlistSuccess
} from '../actions/wishlist.actions';
import { WishlistModel } from '../models/wishlist.model';
import { WishlistStates } from '../states/wishlist.state';

export const WishlistReducer = createReducer(
  WishlistStates,
  on(addProductInWishlistAttempt, (state, action) => ({
    ...state,
    addProduct: {
      temporaryIdsArray: [...state.addProduct.temporaryIdsArray, action.productId],
      loading: true,
      error: []
    }
  })),
  on(addProductInWishlistSuccess, (state, action) => ({
    ...state,
    addProduct: {
      temporaryIdsArray: state.addProduct.temporaryIdsArray.filter(item => item !== action.productId),
      loading: false,
      error: []
    }
  })),
  on(addProductInWishlistFail, (state, action) => ({
    ...state,
    addProduct: {
      temporaryIdsArray: state.addProduct.temporaryIdsArray.filter(item => item !== action.productId),
      loading: false,
      error: action.error
    }
  })),

  on(getWishlistProductsAttempt, state => ({ ...state, products: { ...state.products, loading: true, error: [] } })),
  on(getWishlistProductsSuccess, (state, action) => ({
    ...state,
    products: { data: action.data, loading: false, error: [] }
  })),
  on(getWishlistProductsFail, (state, action) => ({ ...state, products: { loading: false, error: action.error } })),

  on(getWishlistProductIdsAttempt, state => ({
    ...state,
    productIds: { ...state.productIds, loading: true, error: [] }
  })),
  on(getWishlistProductIdsSuccess, (state, action) => ({
    ...state,
    productIds: { data: action.productIds, loading: false, error: [] }
  })),
  on(getWishlistProductIdsFail, (state, action) => ({ ...state, productIds: { loading: false, error: action.error } })),

  on(removeProdutFromWishlistAttempt, (state, action) => ({
    ...state,
    removeProduct: {
      temporaryIdsArray: [...state.removeProduct.temporaryIdsArray, action.productId],
      loading: true,
      error: []
    }
  })),
  on(removeProdutFromWishlistSuccess, (state, action) => ({
    ...state,
    removeProduct: {
      temporaryIdsArray: state.removeProduct.temporaryIdsArray.filter(item => item !== action.productId),
      loading: false,
      error: []
    }
  })),
  on(removeProdutFromWishlistFail, (state, action) => ({
    ...state,
    removeProduct: {
      temporaryIdsArray: state.removeProduct.temporaryIdsArray.filter(item => item !== action.productId),
      loading: false,
      error: action.error
    }
  }))
);

export function reducer(state: WishlistModel, action: Action) {
  return WishlistReducer(state, action);
}

export const selectWishlistState = createFeatureSelector<WishlistModel>('wishlist');

export const selectWishlist = createSelector(selectWishlistState, data => data?.products?.data);
export const addInWishlistTemporaryArr = createSelector(
  selectWishlistState,
  data => data?.addProduct?.temporaryIdsArray
);
export const removeFromWishlistTemporaryArr = createSelector(
  selectWishlistState,
  data => data?.removeProduct?.temporaryIdsArray
);
export const selectWishlistTotalCount = createSelector(
  selectWishlistState,
  data => data?.productIds?.data?.length || 0
);

// export const selectWishlistProductsCount = createSelector(selectWishlistState, data => data?.products?.data?.length || 0);
export const selectWishlistProductIds = createSelector(selectWishlistState, data => data?.productIds?.data || []);
