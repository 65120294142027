import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { CategoriesHttp } from 'lib-core';
import { Observable } from 'rxjs';

@Injectable()
export class CatalogPageGuard {
  constructor(
    private router: Router,
    private catalogService: CategoriesHttp
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const getCategoryId = route.paramMap.get('categoryId');
    const getCategorySlug = route.paramMap.get('categorySlug');
    this.catalogService
      .getMercuryCategoryById(getCategoryId)
      .subscribe(response => {
        if (response) {
          const { originalSlug, id } = response?.data;
          if (originalSlug && originalSlug !== getCategorySlug) {
            this.router.navigate(['/catalog', originalSlug, id]);
          }
        }
      })
      .unsubscribe();
    return true;
  }
}
