export enum Step {
  info,
  validate,
  password,
  success
}

export interface SingUpData {
  username?: null;
  type?: null;
  firstName?: null;
  lastName?: null;
  emailOrPhone?: null;
  otp?: number;
  password?: null;
  confirmPassword?: null;
  token?: null;
  contactStatus?: null | any;
  birthDate?: null;
  userName?: string;
  juridicalId?: string;
}
