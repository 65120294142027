import { createAction, props } from '@ngrx/store';

export enum EWishlistActionsTypes {
  AddProductInWishlistAttempt = '[WISHLIST] Add Product In Wishlist Attempt',
  AddProductInWishlistSuccess = '[WISHLIST] Add Product In Wishlist Success',
  AddProductInWishlistFail = '[WISHLIST] Add Product In Wishlist Fail',
  GetWishlistProductsAttempt = '[WISHLIST] Get Wishlist Products Attempt',
  GetWishlistProductsSuccess = '[WISHLIST] Get Wishlist Products Success',
  GetWishlistProductsFail = '[WISHLIST] Get Wishlist Products Fail',
  GetWishlistProductIdsAttempt = '[WISHLIST] Get Wishlist Product Ids Attempt',
  GetWishlistProductIdsSuccess = '[WISHLIST] Get Wishlist Product Ids Success',
  GetWishlistProductIdsFail = '[WISHLIST] Get Wishlist Product Ids Fail',
  RemoveProdutFromWishlistAttempt = '[WISHLIST] Remove Product From Wishlist Attempt',
  RemoveProdutFromWishlistSuccess = '[WISHLIST] Remove Product From Wishlist Success',
  RemoveProdutFromWishlistFail = '[WISHLIST] Remove Product From Wishlist Fail'
}

export const addProductInWishlistAttempt = createAction(
  EWishlistActionsTypes.AddProductInWishlistAttempt,
  props<any>()
);
export const addProductInWishlistSuccess = createAction(
  EWishlistActionsTypes.AddProductInWishlistSuccess,
  props<any>()
);
export const addProductInWishlistFail = createAction(EWishlistActionsTypes.AddProductInWishlistFail, props<any>());

export const getWishlistProductsAttempt = createAction(EWishlistActionsTypes.GetWishlistProductsAttempt, props<any>());
export const getWishlistProductsSuccess = createAction(EWishlistActionsTypes.GetWishlistProductsSuccess, props<any>());
export const getWishlistProductsFail = createAction(EWishlistActionsTypes.GetWishlistProductsFail, props<any>());

export const getWishlistProductIdsAttempt = createAction(
  EWishlistActionsTypes.GetWishlistProductIdsAttempt,
  props<any>()
);
export const getWishlistProductIdsSuccess = createAction(
  EWishlistActionsTypes.GetWishlistProductIdsSuccess,
  props<any>()
);
export const getWishlistProductIdsFail = createAction(EWishlistActionsTypes.GetWishlistProductsFail, props<any>());

export const removeProdutFromWishlistAttempt = createAction(
  EWishlistActionsTypes.RemoveProdutFromWishlistAttempt,
  props<any>()
);
export const removeProdutFromWishlistSuccess = createAction(
  EWishlistActionsTypes.RemoveProdutFromWishlistSuccess,
  props<any>()
);
export const removeProdutFromWishlistFail = createAction(
  EWishlistActionsTypes.RemoveProdutFromWishlistFail,
  props<any>()
);
