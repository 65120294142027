import { createAction, props } from '@ngrx/store';
import { IBuyModel } from '../models/instant-buy.model';

export enum IBuyActionsTypes {
  GetIBuyConfig = '[IBUY] GetIBuyConfig',
  UpdateIBuyConfig = '[IBUY] UpdateIBuyConfig',
  ClearIBuyConfig = '[IBUY] ClearIBuyConfig'
}

export const getIBuyConfig = createAction(IBuyActionsTypes.GetIBuyConfig);
export const updateIBuyConfig = createAction(IBuyActionsTypes.UpdateIBuyConfig, props<IBuyModel>());
export const clearIBuyConfig = createAction(IBuyActionsTypes.ClearIBuyConfig);
