import { AfterViewInit, Directive, ElementRef, HostListener, Input, NgModule, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { IsMobileService } from '../../services/is-mobile.service';
import { OverflowHandlerService } from '../../services/overflow-handler.service';

@Directive({
  selector: '[appDropDown]'
})
export class DropDownDirective implements AfterViewInit {
  private provocateur;
  private target;
  private arrow;

  @Input() documentClickClose = true;
  @Input() replaceHolder = true;

  @Input() hoverListener = false;
  @Input() closeOnSelect = false;

  constructor(
    private router: Router,
    private renderer: Renderer2,
    private el: ElementRef,
    private overflowHandlerService: OverflowHandlerService,
    private isMobileService: IsMobileService
  ) {}

  ngAfterViewInit() {
    this.provocateur = this.el.nativeElement.querySelector('.dropper');
    this.target = this.el.nativeElement.querySelector('.drop');
    this.arrow = this.el.nativeElement.querySelector('._x_js-rotate');

    if (!this.provocateur || !this.target) return;

    this.renderer.listen(this.provocateur, 'click', e => {
      this.target.classList.contains('active') ? this.close() : this.open();
    });

    if (this.closeOnSelect) {
      this.renderer.listen(this.target, 'click', e => {
        this.close();
      });
    }

    if (this.hoverListener) {
      this.renderer.listen(this.provocateur, 'mouseenter', e => {
        this.open();
      });

      this.renderer.listen(this.el.nativeElement, 'mouseleave', e => {
        this.close();
      });
    }

    this.router.events.subscribe(() => {
      this.target.classList.contains('active') && this.close();
    });
  }

  @HostListener('document:mouseup', ['$event.target'])
  public onClick(targetElement) {
    if (!this.documentClickClose) {
      return;
    }
    const clickedInside = this.el.nativeElement.contains(targetElement);
    if (!clickedInside) {
      if (!this.provocateur || !this.target) return;
      this.target.classList.contains('active') && this.close();
    } else {
      const optionClick = this.provocateur.contains(targetElement);
      if (targetElement.attributes['option']) return;
      if (optionClick) return;
      if (this.replaceHolder) {
        if (this.provocateur.getElementsByTagName('span')[0]) {
          this.provocateur.getElementsByTagName('span')[0].innerHTML = targetElement.getAttribute('data-holder');
        }
      }
      this.target.classList.contains('active') && this.close();
    }
  }

  open() {
    this.isMobileService.isMobileDevice
      .subscribe((isMobile: Boolean) => {
        if (isMobile) {
          this.overflowHandlerService.hide();
        }
      })
      .unsubscribe();
    this.target.classList.add('active');
    this.target.classList.add('_s_a-display');
    if (this.arrow) {
      this.arrow.classList.add('_x_rotate-180');
    }

    this.provocateur.classList.add('active');
  }

  close() {
    this.isMobileService.isMobileDevice
      .subscribe((isMobile: Boolean) => {
        if (isMobile) {
          this.overflowHandlerService.show();
        }
      })
      .unsubscribe();
    this.target.classList.remove('active');
    this.target.classList.remove('_s_a-display');
    if (this.arrow) this.arrow.classList.remove('_x_rotate-180');
    this.provocateur.classList.remove('active');
  }
}

export const DIRECTIVES = [DropDownDirective];

@NgModule({
  declarations: DIRECTIVES,
  exports: DIRECTIVES
})
export class DropDownDirectiveModule {}
