<ng-container *ngIf="!isComparison; else comparisonContent">
  <!-- this is updated version for new types of toasts -->
  <!-- I made it that way at the moment, not to damage existing cases -->
  <ng-container [ngSwitch]="type">
    <div
      *ngSwitchCase="eventType.successLight"
      class="_x_mb-4 _x_flex _x_items-center _x_rounded-8 _x_bg-white _x_py-6 _x_pl-6 _x_pr-9 _x_shadow-comparisonToastShadow">
      <div class="_x_mr-8 _x_flex _x_items-center md:_x_mr-11">
        <strong *ngIf="title" class="me-auto">{{ title }} </strong>
        <!-- use image instead of direct svg -->
        <svg
          class="_x_mr-6 _x_flex-shrink-0"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <rect width="24" height="24" rx="12" fill="#21BA45" />
          <path
            d="M9.81792 14.7172L6.95448 11.7921L6 12.7672L9.81792 16.6673L18 8.30902L17.0455 7.33398L9.81792 14.7172Z"
            fill="white" />
        </svg>
        <div class="_x_font-medium _x_text-3 _x_text-dark md:_x_whitespace-nowrap">{{ message }}</div>
      </div>
      <button
        type="button"
        (click)="hide()"
        class="_x_icon-remove _x_icon _x_relative _x_flex _x_h-10 _x_max-h-10 _x_min-h-10 _x_w-10 _x_min-w-10 _x_items-center _x_justify-center _x_text-2 _x_text-dark"></button>
    </div>

    <div
      *ngSwitchDefault
      class="_x_mb-4 _x_flex _x_h-20 _x_items-center _x_rounded-8 _x_bg-green _x_pl-8 _x_pr-10 md:_x_h-25 md:_x_min-w-150">
      <div class="_x_mr-10 _x_flex _x_items-center md:_x_mr-20">
        <strong *ngIf="title" class="me-auto">{{ title }} </strong>
        <i
          class="_x_icon-done-check _x_icon _x_relative _x_mr-3 _x_flex _x_h-10 _x_max-h-10 _x_min-h-10 _x_w-10 _x_min-w-10 _x_items-center _x_justify-center _x_text-6 _x_text-white md:_x_mr-4"></i>
        <div class="_x_whitespace-nowrap _x_font-medium _x_text-3 _x_text-white">{{ message }}</div>
      </div>
      <button
        type="button"
        (click)="hide()"
        class="_x_icon-remove _x_icon _x_relative _x_ml-auto _x_flex _x_h-10 _x_max-h-10 _x_min-h-10 _x_w-10 _x_min-w-10 _x_items-center _x_justify-center _x_text-2 _x_text-white"></button>
    </div>
  </ng-container>
</ng-container>

<ng-template #comparisonContent>
  <div
    class="_x_mb-4 _x_flex _x_items-center _x_rounded-8 _x_bg-white _x_py-6 _x_pl-6 _x_pr-9 _x_shadow-comparisonToastShadow">
    <div class="_x_mr-8 _x_flex _x_items-center md:_x_mr-11">
      <strong *ngIf="title" class="me-auto">{{ title }} </strong>
      <svg
        class="_x_mr-6 _x_flex-shrink-0"
        *ngIf="type === eventType.success"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect width="24" height="24" rx="12" fill="#21BA45" />
        <path
          d="M9.81792 14.7172L6.95448 11.7921L6 12.7672L9.81792 16.6673L18 8.30902L17.0455 7.33398L9.81792 14.7172Z"
          fill="white" />
      </svg>
      <svg
        class="_x_mr-6 _x_flex-shrink-0"
        *ngIf="type === eventType.error"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect width="24" height="24" rx="12" fill="#F12D2D" />
        <path
          d="M11.048 14.288L10.778 5.9H13.136L12.866 14.288H11.048ZM11.948 15.746C12.344 15.746 12.686 15.896 12.974 16.196C13.274 16.484 13.424 16.826 13.424 17.222C13.424 17.618 13.274 17.966 12.974 18.266C12.686 18.554 12.344 18.698 11.948 18.698C11.564 18.698 11.222 18.554 10.922 18.266C10.634 17.966 10.49 17.618 10.49 17.222C10.49 16.826 10.634 16.484 10.922 16.196C11.222 15.896 11.564 15.746 11.948 15.746Z"
          fill="white" />
      </svg>
      <div class="_x_font-medium _x_text-3 _x_text-dark md:_x_whitespace-nowrap">{{ message }}</div>
      <button
        (click)="redirectToComparison()"
        class="_x_ml-12 _x_hidden _x_rounded-8 _x_bg-comparisonToast _x_px-6 _x_py-3 _x_font-bold _x_text-3 _x_text-dark md:_x_block"
        *ngIf="type === eventType.success">
        სიის ნახვა
      </button>
      <button
        class="_x_ml-6 _x_hidden _x_rounded-8 _x_bg-comparisonToast _x_px-6 _x_py-3 _x_font-bold _x_text-3 _x_text-dark md:_x_block"
        (click)="handleComparison()"
        *ngIf="type === eventType.error">
        სიის გასუფთავება
      </button>
    </div>
    <button
      type="button"
      (click)="hide()"
      class="_x_icon-remove _x_icon _x_relative _x_flex _x_h-10 _x_max-h-10 _x_min-h-10 _x_w-10 _x_min-w-10 _x_items-center _x_justify-center _x_text-2 _x_text-dark"></button>
  </div>
</ng-template>
