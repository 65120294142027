import { ChangeDetectionStrategy, Component } from '@angular/core';
import { confirmationDialogData, ConfirmationDialogService } from '../../services/confirmation-dialog.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
  standalone: true
})
export class ConfirmationDialogComponent {
  constructor(private confirmationDialogService: ConfirmationDialogService) {}

  get confirmationModalData(): confirmationDialogData {
    return this.confirmationDialogService.confirmationDialogData;
  }

  public closeModal(): void {
    this.confirmationDialogService.closeConfirmationModal();
  }

  public save(): void {
    this.confirmationModalData?.confirmAction();
  }
}
