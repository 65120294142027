import { ChangeDetectionStrategy, Component } from '@angular/core';
import { GlobalModalService } from '../../../services/global-modal-service';
import { ExpressService } from '../../../services/express.service';

@Component({
  selector: 'non-express-address-warning-modal',
  templateUrl: './non-express-address-warning-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class nonExpressAddressWarningModalComponent {
  constructor(
    private globalModalService: GlobalModalService,
    private expressService: ExpressService
  ) {}

  public closeModal(): void {
    this.globalModalService.close();
  }

  public openAddressPopup() {
    this.closeModal();
    this.expressService.openAddressPopup();
  }
}
