import { createAction, props } from '@ngrx/store';
import { RibbonModel } from '../models/ribbon.model';

export enum ERibbonActions {
  GetRibbonAttempt = '[RIBBON] Get Ribbon Attempt',
  GetRibbonSuccess = '[RIBBON] Get Ribbon Success',
  GetRibbonFailed = '[RIBBON] Get Ribbon Failed'
}

export const GetRibbonAttempt = createAction(ERibbonActions.GetRibbonAttempt);
export const GetRibbonSuccess = createAction(ERibbonActions.GetRibbonSuccess, props<RibbonModel>());
export const GetRibbonFailed = createAction(ERibbonActions.GetRibbonFailed, props<any>());
